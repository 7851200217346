import React from 'react';
// import styles from './ProductPageTitleBar.module.scss';
import PlayIcon from '../../icons-v2/PlayIcon';
import AddIcon from '../../icons-v2/AddIcon';
import Bars from '../../icons-v2/Bars';
import InfoIconV2 from '../../icons-v2/InfoIconV2';
import WriteAReviewModal from '../WriteAReviewModal/WriteAReviewModal';
import ArrowRightIcon from '../../icons-v2/ArrowRightIcon';
import './styles.scss';
import StarIcon from '../../icons-v2/StarIcon';

const ProductPageTitleBar = ({ algo, title, onMinimize, goBack, onClose, onDemo, showTips, isResponsive }) => {
    return (
        <div className={`ToolPage-Header bg-surface-1 flex-wrap gap-2 text-accent-4 d-flex p-3 py-lg-0 align-items-center`}>
            <button className={`btn btn-icon no-padding ms-2 d-md-flex d-none`} onClick={goBack}><ArrowRightIcon className='rotate-180 text-primary' /></button>
            <h1 className='m-0 text-normal text-capitalize h6 fw-semibold ms-auto'>{title}</h1>
            {algo && (
                <div className={`me-0 d-flex gap-md-3 gap-2 text-nowrap ToolPage-btn-wrapper`}>
                    <WriteAReviewModal algo={algo}
                        component={({ showPopUp }) => (
                            <button className='btn small-sm btn-rounded' onClick={showPopUp}>
                                <StarIcon className='text-primary' fill='currentColor' />
                                Review
                            </button>
                        )}
                    />
                    <label className='btn small-sm btn-rounded' htmlFor={`${algo.url_link}-info`}><InfoIconV2 className='text-primary' /> Info</label>
                    {algo.video_url && (
                        <label htmlFor={`${algo.url_link}-video`} className='btn small-sm btn-rounded'><PlayIcon className='text-primary' />Explainer Video</label>
                    )}
                    {showTips && <button className='btn d-none d-lg-flex small-sm btn-rounded' onClick={onDemo}>
                        <PlayIcon className='text-primary' />
                        Play Tutorial
                    </button>}
                </div>
            )}
            <div className='d-md-flex d-none me-md-0 gap-3 ms-md-3 ps-1 pe-3 me-auto'>
                <button className='btn btn-icon no-padding' onClick={onMinimize}><Bars isSubsctract={true} /></button>
                <button className='btn btn-icon no-padding' onClick={onClose}><AddIcon className='rotate-45' /></button>
            </div>
        </div>
    )
}


export default React.memo(ProductPageTitleBar);