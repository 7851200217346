import React from 'react';
import CreditIcon from '../../../icons-v2/CreditIcon';
import NavDropDown from '../../Header/NavDropDown/NavDropDown';
import { useApp } from '../../../App';

const CreditDropDown = props => {
    const credits = useApp().user.credits
    return (
        <li className='position-relative' id='creditsystem'>
            <NavDropDown
                className='p-3'
                component={(_, ref) => (
                    <button
                        ref={ref}
                        className='btn btn-primary bg-accent-7 py-2 px-3 small'
                    ><CreditIcon />{credits}</button>
                )}
            >
                <div className='d-flex justify-content-between gap-4'>
                    <div className='d-flex align-items-center gap-2'>
                        <CreditIcon className='text-accent-7 svg-normal' />
                        <small className='text-accent-4'>Credits</small>
                    </div>
                    <b>{credits}</b>
                </div>
                <div className='mt-3 d-grid'>
                    {props.ViralC("Credits Popup")}
                </div>
            </NavDropDown>
        </li>
    )
}

export default CreditDropDown;