import React from 'react';
import axios from 'axios';
import URL from 'url-parse';
import { AppContext } from '../../App'
import moment from "moment";
import PatentFilter from './PatentsFilter';
import StockTable from './StockTable';
import Tool from '../../hoc-v2/withTool/Tool';
import './styles.scss';
import TitleBar, { Label } from '../../components-v2/ToolComponents/TitleBar';
import getStockSearch from '../../utility/getStockSearch';
import withQuery from '../../hoc-v2/withQuery';


class Patents_Container extends React.Component {

  static contextType = AppContext;
  render() {
    return (
      <Patents
        {...this.props}
        {...this.context} />
    )
  }
}

class Patents extends React.Component {
  controller = null;
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stocks: ['AAPL'],
      stock_search: getStockSearch(this.props) || 'AAPL',
      patent_data: [],
      keyword: "",
      start_date: "1930-01-01",
      end_date: moment(Date.now()).format('YYYY-MM-DD'),
      num_patents: 0,
      isLoading: false,
      error_msg: "",
    }
  }

  findPatentsStocks = () => {
    let symbols = this.state.stock_search.split(',');
    this.setState({ isLoading: true })
    if(this.controller) {
      this.controller.abort()
    }
    this.controller = new AbortController()
    axios.post('/find_stock_patents', {
      symbols: symbols,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      keyword: this.state.keyword,
    }, {
      signal: this.controller.signal
    }).then((res) => {
      const patent_data = res.data.data
      this.setState({
        patent_data,
        isLoading: false,
        total_items: res.data.number_of_patents,
      });
    }).catch((error) => {
      console.error("Error fetching most patented stocks:", error);
      // Optionally, you could set an error state here
      // this.setState({ error: 'Failed to fetch patented stocks' });
    });
  };
  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort(); // Cleanup on unmount
    }
  }
  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    var start_date = new Date();
    start_date.setMonth(start_date.getMonth() - 1);
    var compare_stock_data = [];

    this.findPatentsStocks()

    this.setState({
      end_date: moment(Date.now()).format('YYYY-MM-DD'),
      patent_data: compare_stock_data,

    })
  }
  typingTimeout = null;

  handleChange = (e, values = null) => {
    const { name, value } = e.target;

    // Clear any existing timeout so we don't call findPatentsStocks prematurely
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    // If the field is 'stock_search', gather the correct value
    if (name === 'stock_search') {
      const newValue = values?.join(',') || value;
      this.setState(
        {
          [name]: newValue,
        },
        // Do NOT immediately call findPatentsStocks here
        // We'll call it after the debounce timer ends
      );
    } else {
      this.setState(
        {
          [name]: value,
        },
        // Same logic here - call after debounce
      );
    }

    // Set up a new timer. Only if the user doesn't type again within 500ms,
    // we'll call findPatentsStocks.
    this.typingTimeout = setTimeout(() => {
      this.findPatentsStocks();
    }, 1000);
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.setState(this.state.patent_data)
    }
    if (prevProps.symbol_list !== this.props.symbol_list) {
      this.setState({
        stock_search: this.props.symbol_list,
      }, () => {
        this.findPatentsStocks()
      })

    }
  }
  handleStartChange = (date) => {
    this.setState({
      start_date: moment(date).format('YYYY-MM-DD')
    })
  }
  handleEndChange = (date) => {
    this.setState({
      end_date: moment(date).format('YYYY-MM-DD')
    })
  }

  addStock = () => {
    this.setState(prevState => ({
      stocks: [...prevState.stocks, ''] // you may change '' to a default stock symbol if you prefer
    }));
  }

  handleStockChange = (index, value) => {
    this.setState(prevState => {
      const stocks = [...prevState.stocks];
      stocks[index] = value;
      return { stocks };
    });
  }



  onStockRemove = (index) => {
    this.setState(prevState => {
      const stocks = [...prevState.stocks];
      stocks.splice(index, 1);
      return { stocks };
    });
  }


  render() {
    this.props.getState && this.props.getState(this.state);
    // this.props.setResponsive(this.state.stock_data.length > 0);
    const { isPage, isMobile } = this.props;
    return (
      <Tool
        id="patents"
        algo={this.props.algo}
      >
        <div className='d-flex flex-column'>
          <TitleBar id="patents" label="Correlate More" />
          <PatentFilter
            handleChange={this.handleChange}
            handleStartChange={this.handleStartChange}
            handleEndChange={this.handleEndChange}

            addStock={this.addStock}
            handleStockChange={this.handleStockChange}
            {...this.state}
            algo={this.props.algo}
            onStockRemove={this.onStockRemove}
          />
        </div>
        <div className='ToolPage-Main d-flex flex-column gap-3 pb-5'>
          {isPage && isMobile && <Label id="patents" className='me-auto' />}
          {this.state.isLoading && <div className='bar-loader' />}
          <StockTable {...this.state} />
        </div>

      </Tool>
    )
  }
}

export default withQuery(Patents_Container);