import React, { useState } from 'react';
import Eye from '../../icons-v2/Eye';
import { OutlinedInput } from '@material-ui/core';
import withFormField from '../withFormField/withFormField';

const InputField = ({ type, pattern, className, min, max, accept, inputProps = {}, minLength, maxLength, endAdornment, ref, ...props }) => {
    const [inputType, setInputType] = useState(type);
    return (
        <OutlinedInput
            ref={ref}
            fullWidth={true}
            {...props}
            type={inputType}
            pattern={pattern}
            inputProps={{ min, max, minLength, maxLength, accept, ...inputProps }}
            endAdornment={
                type === 'password' ?
                    <button className='btn btn-icon' onClick={() => setInputType(oldType => oldType === 'password' ? 'text' : 'password')} type='button'>
                        <Eye className={inputType !== "password" ? "bg-accent-6" : ""} />
                    </button> : endAdornment}
        />
    )
}

export default withFormField(InputField);