import React from 'react';
import { NavLink } from "react-router-dom";
import NavDropDown from "../../Header/NavDropDown/NavDropDown";
import LogoutModal from '../../LogoutModal';
import UserAvatar from "../../UserAvatar/UserAvatar";
import styles from './UserDropDown.module.scss';
import HeaderLinkMain from '../../HeaderV2/HeaderLink/Main';

const UserDropDown = ({ user, ...props }) => {
    return (
        <NavDropDown className={styles.root}
            component={(status, ref) => (
                <li ref={ref}
                    className={status}
                >
                    <HeaderLinkMain
                        title={<><UserAvatar {...user} />{user.name}</>}
                    />
                </li>
            )}>
            <li>
                <NavLink to='/my-account'>My Account</NavLink>
            </li>
            <li>
                <NavLink to='/help-and-support'>Help & Support</NavLink>
            </li>
            <li>
                <NavLink to='/privacy'>Privacy policy</NavLink>
            </li>
            <li>
                <LogoutModal {...props} />
            </li>

        </NavDropDown>
    )
}

export default UserDropDown;
