import React, { useState } from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import styles from '../congress/StockItem.module.scss';
import ChevronBottom from '../../icons-v2/ChevronBottom';
import moment from 'moment';
import MainTable from './MainTable';


const StockItem = props => {
    const [isExpanded, setIsExpanded] = useState(false);
    const stock = props.stock;
    const index_num = props.index_num;
    const number_of_patents = props.stock_arr.number_of_patents;
    
    function convert_num_to_string(num) {
        if (num > 1000000000) {
            return (num / 1000000000).toFixed(2) + "B";
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(2) + "M";
        } else if (num > 1000) {
            return (num / 1000).toFixed(2) + "K";
        } else {
            return num;
        }
    }

    return (
        <>
            <tr
                onClick={() => { setIsExpanded(!isExpanded); }}
                className={`${styles.root} ${isExpanded ? styles.active : ''}`}
            >
                <td>
                    <ChevronBottom
                        className={`text-accent-4 ${isExpanded ? 'rotate-180' : ''}`}
                    />
                </td>
                <td>{index_num + 1}</td>
                <td>
                    <button className='badge' id='ticker' about={stock}>{stock}</button>
                </td>
                <td>{number_of_patents}</td>
            </tr>

            {isExpanded && (
                <>
                    <tr className={`border-bottom ${styles.expandedRow}`}>
                        <td colSpan={8} className='p-0'>
                            <table className='primary-table'>
                                <MainTable symbol={props.stock} {...props.stock_arr} filters={props.filters} />
                            </table>
                        </td>
                    </tr>
                </>
            )}
        </>
    )
}

const ExpandableTableWrapper = ({ page, graph, no_result, stock_data, graphStock, stepNo, totalLength, patent_data, filters }) => {
    return (
        <>
            <thead>
                <tr>
                    <th></th>
                    {/* Updated the helper text for Row Number */}
                    <th>
                        <div>
                            <ToolTip title="Indicates the row number in the table.">
                                No
                            </ToolTip>
                        </div>
                    </th>
                    {/* Updated the helper text for Symbol */}
                    <th>
                        <div>
                            <ToolTip title="The stock's ticker symbol.">
                                Symbol
                            </ToolTip>
                        </div>
                    </th>
                    {/* Updated the helper text for # of patents */}
                    <ToolTip title="Displays the total number of patents found for the company.">
                        <th># of patents</th>
                    </ToolTip>
                </tr>
            </thead>
            <tbody>
                {
                    Object.keys(patent_data).length > 0 ?
                        Object.entries(patent_data).map(([key, value], index) => (
                            <StockItem
                                key={index}
                                stock={key}
                                stock_arr={value}
                                index_num={index}
                                filters={filters}
                            />
                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20}>
                                    <div className='text-center m-auto'>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const StockTable = props => {
    const number_of_stocks = Object.keys(props.patent_data).length;
    const firstStockKey = Object.keys(props.patent_data)[0];
    const filters = {
        start_date: props.start_date,
        end_date: props.end_date
    }
    return (
        <>
            <div className='table-wrapper product_tool_table'>
                <div className={props.stepNo === 1 ? 'overflow-hidden' : ''}>
                    <table>
                        {number_of_stocks === 1
                            ? <MainTable filters={filters} symbol={firstStockKey} {...props.patent_data[firstStockKey]} />
                            : <ExpandableTableWrapper filters={filters} {...props} />
                        }
                    </table>
                </div>
            </div>
        </>
    )
}

export default StockTable;
