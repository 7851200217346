import React from 'react';
import SearchField from "../../ui-v2/SearchField/SearchField";

const MobileSearchBox = ({ className = '', ...props }) => {
    return (
        <div className={className}>
            <SearchField
                {...props}
            >
                <span className="position-absolute start-0 ms-2 ps-1">

                {props.watchlist}
                </span>
            </SearchField>
        </div>
    )
}

export default MobileSearchBox;