import React from 'react';
import SearchField from "../../ui-v2/SearchField/SearchField";
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import styles from './stocksearch.module.scss'
import SearchResults from '../../components-v2/SearchResults';
import useStockSearchV2 from '../../hooks/useStockSearchV2';

export default function StockSearch(props) {
    const { results, onAdd, onValueChange, ...sProps } = useStockSearchV2({
        value: props.value,
        onChange: props.onChange
    });
    return (
        <div className={`flex-1 position-relative ${results.length > 0 ? 'z-index-8' : ''} ${styles.root}`}>
            <SearchField autoComplete="off" name="Search" {...sProps} onChange={sProps.onChange}>
                {props.isLoggedIn && (
                    <span className="position-absolute start-0 ms-3">
                        <WatchListImport handleStockChange={props.onChange} algo={{ watchlist: true }} forDashboard={true} />
                    </span>
                )}
            </SearchField>
            <SearchResults results={results} value={sProps.value} onValueChange={onValueChange} />
        </div>
    )
}