import React, { useEffect, useState } from "react"
import './styles.scss';
import Left from ".";

export default function LeftSection(props) {
    const [isMounted, setMounted] = useState(typeof props.loading === 'boolean' ? props.loading : true)
    useEffect(() => {
        if(!isMounted && props.loading) setMounted(true);
    }, [props.loading, isMounted])
    return isMounted && <Left {...props} />
}