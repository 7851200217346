import React, { Component } from 'react';
import axios from 'axios';
import Filters from './Filters';
import StockTableInvestor from './StockTableInvestor';
import StockTableStock from './StockTableStock';
import Pagination from '../../ui-v2/Pagination/Pagination';
import PortfolioUpdates from './PortfolioUpdates';
import Tool from '../../hoc-v2/withTool/Tool';
import TitleBar, { Label } from '../../components-v2/ToolComponents/TitleBar';
import NotificationIcon from '../../icons-v2/NotificationIcon';
import getStockSearch from '../../utility/getStockSearch';

class Super_Investor extends Component {
    state = {
        page: 0,
        superInvestors: [],
        stock_search: getStockSearch(this.props),
        super_investor_search: '',
        tableView: 'super_investor',
        sort: 'portfolio_value_string',
        desc: true
    }
    conver_num_to_string = (num) => {
        if (num > 1000000000) {
            return (num / 1000000000).toFixed(2) + "B";
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(2) + "M";
        } else if (num > 1000) {
            return (num / 1000).toFixed(2) + "K";
        } else {
            return num;
        }
    }
    changeTableView(e) {
        this.setState({
            tableView: e.target.value,
            loadingTable: true
        }, () => {
            this.getData()
        })
    }
    handleStockChange = (e, values) => {
        const { value, name } = e.target
        this.setState({
            [name]: values?.join(',') || value,
            page: 0,
        }, () => {
            this.getData()
        })
    }
    handleInvestorChange = (e, values) => {
        const { value, name } = e.target
        this.setState({
            [name]: values?.join(',') || value,
            page: 0,
        }, () => {
            this.getData()
        })
    }
    fetchData(json) {
        axios.post('/fetch_super_investors', json).then((response) => {
            const res = response.data
            // console.log(res);
            if (res.status === "success") {
                this.setState({
                    superInvestors: res.superInvestors,
                    totalLength: res.totalLength,
                })
                if (res.totalLength === 0) {
                    this.setState({
                        no_result: true,
                        getting_data: false
                    })
                }
            }
        }).finally(() => {
            this.setState({
                process: false,
                loadingTable: false,
            })
        })
    }
    async getData(page = 0) {
        const {
            stock_search,
            super_investor_search,
            tableView,
            sort, desc } = this.state
        const json = {
            sort,
            desc,
            super_investor_search: super_investor_search,
            stock_search: stock_search.split(','),
            page,
            tableView: tableView,
            // ...data

        }
        this.setState({
            process: true,
            loadingTable: true,
            no_result: false,
            page,
            desc
            // ...data
        })
        this.fetchData(json)
    }
    componentDidMount = () => {
        this.getData()
    }
    onSort = sort => {
        const update = {
            sort,
            desc: (this.state.sort === sort && this.state.desc) ? 0 : 1,
            page: 0
        }
        const json = {
            super_investor_search: this.state.super_investor_search,
            stock_search: this.state.stock_search.split(','),
            tableView: this.state.tableView,
            ...update
        }
        this.setState({ ...update, loadingTable: true })
        this.fetchData(json);
    }
    setUpdate = update => this.setState(update)
    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.setState(this.state.superInvestors)
        }
        if (prevProps.symbol_list !== this.props.symbol_list) {
            this.setState({
                stock_search: this.props.symbol_list
            }, () => {
                this.getData()
            }
            )
        }
    }

    render() {
        const newProps = { ...this.state, ...this.props }, updates = <Label
            id="portfolio-updates-btn"
            label={<><NotificationIcon color="currentColor" /> Recent Updates</>}
        />
        return (
            <Tool id="super_investor" algo={this.props.algo}>
                <div className='d-flex flex-column'>
                    <TitleBar title='Hedge Fund Analysis' id="super_investor" />
                    <Filters 
                        {...newProps}
                        handleStockChange={this.handleStockChange}
                        handleInvestorChange={this.handleInvestorChange}
                        changeTableView={this.changeTableView.bind(this)}
                    />
                </div>
                <div className='d-flex overflow-hidden flex-column flex-1 ToolPage-Main gap-3'>
                    <div className='flex-center gap-2'>
                        <small className='text-accent-4 ms-auto'>{this.state.totalLength} Results</small>
                        {this.props.isPage && (
                            <>
                                {updates}
                                <Label id="super_investor" />
                            </>
                        )}
                    </div>
                    {this.state.loadingTable ? <div className='bar-loader' /> : (
                        this.state.tableView === 'stock' ?
                            <StockTableStock onSort={this.onSort} stocks={newProps.superInvestors} /> :
                            <StockTableInvestor onSort={this.onSort} {...newProps} />
                    )}
                    <Pagination
                        pageCount={Math.ceil(this.state.totalLength / 10)}
                        initialPage={this.state}
                        forcePage={this.state.page}
                        onPageChange={data => this.getData(data.selected)}
                        className='mt-auto'
                    />
                </div>
                <PortfolioUpdates />
            </Tool>
        )
    }
}

export default Super_Investor