import React from 'react';
import Range from './Range';
import SearchBox from '../../components-v2/SearchBox/SearchBox';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';
import BoxField from '../../ui-v2/BoxField/BoxField';
import SearchField from '../../components-v2/AlgoHoldingCommonTools/SearchField';


const FundamentalsFilter = ({ ranges, handleToggle, fields, getPureData, filter, clearFiltersHandler, watchlist, ...props }) => {
    // footer={(
    //     <div className='d-flex align-items-center text-accent-3 gap-3'>
    //         {props.loading && <><p className='m-0'>Loading...</p> <Spinner fontSize='0.35rem' /></>}
    //         <button
    //             disabled={props.loading}
    //             onClick={() => props.getData()}
    //             className={`btn smaller-sm btn-primary font-size-normal flex-1 px-3`}
    //         >Apply Filters</button>
    //     </div>
    // )}
    return (
        <LeftSection
            algo={props.algo}
            // component={(
            //     <>
            //         <div className='d-flex gap-3 align-items-center w-100'>
            //             <MobileSearchBox
            //                 name='ticker'
            //                 className='flex-1'
            //                 label="Search for stocks"
            //                 onChange={props.handleStockChange}
            //                 defaultValue={filter.ticker}
            //                 placeholder='Search for a specific stock'
            //                 onSubmit={() => props.getData()}
            //             />
            //             <FilterButton />
            //         </div>
            //         {watchlist}
            //     </>
            // )}
            id="fundamentals"
        >
            <small>Data Filters </small>
            <div className='d-flex flex-column gap-3'>
                <BoxField checked={filter.specific_search} onChange={handleToggle} name="specific_search" label="Specific Search" />
                {!props.isMobile && (
                    <div className='d-lg-block d-none'>
                        <SearchField
                            name='ticker'
                            label="Search for a specific stock"
                            onChange={props.handleStockChange}
                            value={Array.isArray(filter.ticker) ? filter.ticker.join(',') : typeof filter.ticker === 'string' ? filter.ticker : ""}
                            endAdornment={watchlist}
                        />
                        <button className='btn-text text-accent-2 mt-1 small btn me-auto' onClick={clearFiltersHandler}>Clear Filters</button>
                    </div>
                )}
                {ranges && fields.map((item, index) => ranges[item.name] && (
                    <Range getPureData={getPureData}
                        onChange={props.handleChange}
                        {...item}
                        key={index}
                        range={ranges[item.name]}
                        checked={!filter[`pure_${item.name}`]}
                        filter={filter}
                    />
                ))}
            </div>
        </LeftSection>
    )
}

export default FundamentalsFilter;
