import React from "react"
import SearchField from "../../ui-v2/SearchField/SearchField"
import styles from './DashboardMain.module.scss'
import Switch from "../../ui-v2/Switch";
import { Link } from "react-router-dom";
import SortModal from "./SortModal";
import AlgoItems from "./AlgoItems";
import useSetViewPort from "../../hooks/useSetViewPort";
import StockSearch from "./StockSearch";

let timer;

export default function TopBar({ setState, isLoggedIn, search, dashboard_view, algos }) {
    const onChange = e => setState({ search: e.target.value })
    const isDashboard = dashboard_view === "all";
    const ref = useSetViewPort(isDashboard);
    return (
        <div ref={isDashboard ? ref : undefined} className={isDashboard ? "position-sticky bg-inherit top-header z-index-7" : ""}>
            <div className={`overflow- gap-3 ${isDashboard ? "pb-2 bg-surface-1" : "border-bottom"} align-items-md-center p-3 d-md-flex flex-wrap d-grid`}>
                {isDashboard ? <StockSearch value={search} isLoggedIn={isLoggedIn} onChange={onChange} /> : (
                    <SearchField placeholder="Search" defaultValue={search} onChange={e => {
                        clearTimeout(timer);
                        timer = setTimeout(() => onChange(e), 500);
                    }} name='search' className={`flex-1 ${styles.searchField}`}>
                    </SearchField>
                )}
                <div className="text-accent-4 me-md-auto small align-items-center d-flex">
                    <button className="p-2 btn small" onClick={() => isDashboard && setState({ dashboard_view: 'list', search: "" })}>Separate Tools</button>
                    <Switch
                        onChange={e => {
                            const dashboard_view = e.target.checked ? 'all' : 'list';
                            localStorage.setItem('dashboard_view', dashboard_view)
                            setState({ dashboard_view, search: '' })
                        }}
                        checked={isDashboard}
                    />
                    <button className="p-2 btn small ms-1" onClick={() => !isDashboard && setState({ dashboard_view: "all", search: "" })}>Dashboard</button>
                    {isDashboard && (
                        isLoggedIn ? (
                            <button
                                className="btn btn-text small me-auto"
                                onClick={() => setState({ isMenu: true })}
                            >
                                Watchlist <i className="fas fa-chevron-right" />
                            </button>
                        ) : (
                            <Link to="#register" className="btn btn-text small me-auto">
                                Watchlist <i className="fas fa-chevron-right" />
                            </Link>
                        )
                    )}
                    {isLoggedIn && <SortModal setState={setState} algos={algos} />}
                </div>
            </div>
            {isDashboard && <AlgoItems algos={algos} />}
        </div>
    )
}