import React from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import StockItem from './StockItem';
import moment from 'moment';
import Circle from '../../ui-v2/Circle/Circle';
import useQuery from '../../hooks/useQuery';
// import StockGraph from '../../components-v2/StockGraph';


const TipWrapper = ({ stepNo }) => {
    const isMobile = useQuery();
    return (
        <th id='tip-1'>
            <ToolTip title="Number of different participants that have traded the stock." open={!isMobile && stepNo === 1}>
                <span className='position-relative'>
                    {stepNo === 1 && <Circle />}
                    Participants
                </span>
            </ToolTip>
        </th>
    )
}

const NormalTable = ({ stock_data = [], graph, page, no_result, graphStock, stepNo }) => {
    return (
        <>
            <thead>
                <tr>
                    <ToolTip title="The row number.">
                        <th>
                            <div>No</div>
                        </th>
                    </ToolTip>
                    <ToolTip title="The stock symbol.">
                        <th>Symbol</th>
                    </ToolTip>
                    <ToolTip title="The name of the insider.">
                        <th>Owner</th>
                    </ToolTip>
                    <ToolTip title="Number of transactions that took place on the stock.">
                        <th>Transactions</th>
                    </ToolTip>
                    <TipWrapper stepNo={stepNo} />
                    <ToolTip title="The insiders role in relation to the stock.">
                        <th>Relationship</th>
                    </ToolTip>
                    <ToolTip title="The date that the order occurred.">
                        <th>Date</th>
                    </ToolTip>
                    <ToolTip title="The type of transaction that occurred.">
                        <th>Transaction</th>
                    </ToolTip>
                    <ToolTip title="The stock price that the trade occurred at.">
                        <th>Cost($)</th>
                    </ToolTip>
                </tr>
            </thead>
            <tbody>

                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <tr>
                                <td title='No'>{page * 10 + index + 1}</td>
                                <td title='Symbol'>
                                    <button
                                        id='ticker'
                                        className="badge"
                                        about={stock.ticker}
                                        aria-details={`Congress Reaction for ${stock.ticker}`}
                                    >{stock.ticker}</button>
                                </td>
                                <td title='Owner'><div>{stock.representative}</div></td>
                                <td title='Transactions'>{stock.individual}</td>
                                <td title='Participants'>{stock.include}</td>
                                <td title='Relationship'>{stock.congress_house}</td>
                                <td title='Date'>{moment(stock.transaction_date).format('YYYY-MM-DD')}</td>
                                <td title='Transaction'>{stock.transaction_type}</td>
                                <td title='Cost'>{parseFloat(stock.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                            </tr>


                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20}>
                                    <div className='m-auto'>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const ExpandableTableWrapper = ({ stock_data = [], graph, page, no_result, graphStock, selectionRange, stepNo, period }) => {
    return (
        <>
            <thead>
                <tr>
                    <th></th>
                    <th><ToolTip title="The row number.">No</ToolTip></th>
                    <th><div><ToolTip title="The stock symbol.">Symbol</ToolTip></div></th>
                    <ToolTip title="Number of transactions that took place on the stock.">
                        <th>Transactions</th>
                    </ToolTip>
                    <TipWrapper stepNo={stepNo} />
                    <th>Date Range</th>
                    {/* <th>Transaction Type</th> */}
                    <th>Cost Range ($)</th>
                </tr>
            </thead>
            <tbody>
                {
                    stock_data.length > 0 ?
                        stock_data.map((stock, index) => (
                            <StockItem stock={stock} selectionRange={selectionRange} page={page * 10 + index + 1} period={period} />
                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20}>
                                    <div className='text-center m-auto'>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const StockTable = props => {
    return (
        <>
            <div className='table-wrapper product_tool_table'>
                <div className={props.stepNo === 1 ? 'overflow-hidden' : ''}>
                    <table>
                        {props.tableView === "individual" ? <NormalTable {...props} /> : <ExpandableTableWrapper {...props} />}
                    </table>
                </div>
            </div>
        </>
    )
}

export default StockTable;