import React from 'react';
import App from "../App";
import { MinimizedContextProvider } from "./MinimizedPagesContext";
import RefreshContextProvider from "./refresh/provider";
import { SymbolContextProvider } from './SymbolContext';
import { AlgoProvider } from './AlgoContext';  // Import AlgoContextProvider
import AlgosProvider from './algos/provider';

export default function GlobalContext(props) {
    return (
        <RefreshContextProvider>
            <App>
                <AlgosProvider>
                    <SymbolContextProvider>
                        <MinimizedContextProvider>
                            <AlgoProvider>
                                {props.children}
                            </AlgoProvider>
                        </MinimizedContextProvider>
                    </SymbolContextProvider>
                </AlgosProvider>
            </App>
        </RefreshContextProvider>
    );
}