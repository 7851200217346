import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import DividendsStockFilter from './DividendsStockFilter';
// import ManageSignalPopUp from './ManageSignalPopUp';
import DividendsStockTable from './DividendsStockTable';
import Pagination from '../../ui-v2/Pagination/Pagination';
import { Link } from 'react-router-dom';
import TipCard from '../../ui-v2/TipCard/TipCard';
import SignalsHandler from '../SignalsHandler/SignalsHandler';
import SignalButtons from '../SignalsHandler/SignalButtons';
import Tool from '../../hoc-v2/withTool/Tool';
import TitleBar, { Label } from '../../components-v2/ToolComponents/TitleBar';
import withQuery from '../../hoc-v2/withQuery';
import getStockSearch from '../../utility/getStockSearch';


class Dividends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: new URL(window.location.href, true),
            stock_data: [],
            sort_type: ["Date", "Biggest Payout", "Biggest Payout Percent"],
            period: "All",
            period_type: ["All", "Quarterly", "Monthly", "Semi-Annual", "Annual"],
            selected_type: "Date",
            orig_stock_data: [],
            scraping: false,
            page: 0,
            totalLength: 10,
            filterDate: moment().format('YYYY-MM-DD'),
            filterDate_options: ["All", moment().format('YYYY-MM-DD')],
            created: false,
            loadingTable: false,
            stock_search:  getStockSearch(this.props),
            no_result: false,
            loading: true,
            started_changing:false,

            selected_stock: ""
        }
        this.handlePageClick = this.handlePageClick.bind(this)
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        // this.fetchData()
        if (this.props.state) return this.setState(this.props.state);
        
        axios.get('/get_dividends_data_dates').then((response) => {
            const dates = [...response.data.dates];
    
            this.setState({
                filterDate_options: dates
            }, () => {
                const { filterDate } = this.state;
                let closestDate = null;
    
                if (dates.length === 0) {
                    // If no dates exist, set filterDate to 'All'
                    this.setState({
                        filterDate: 'All',
                        loading: false
                    }, () => {
                        this.getData();
                    });
                } else {
                    // Find the closest date to the current date
                    const currentDate = moment(); 
                    let minDiff = Infinity;
    
                    dates.forEach((date) => {
                        const dateMoment = moment(date, 'YYYY-MM-DD');
                        const diff = Math.abs(currentDate.diff(dateMoment, 'days'));
    
                        if (diff < minDiff) {
                            minDiff = diff;
                            closestDate = date;
                        }
                    });
    
                    // Set the closest date or 'All' if none found
                    const finalDate = closestDate || 'All';
    
                    this.setState({
                        filterDate: finalDate,
                        loading: false
                    }, () => {
                        this.getData();
                    });
                }
            });
        });
    }


    getData() {
        if (!this.state.loading) {
            const json = {
                page: this.state.page,
                sort: this.state.selected_type,
                period: this.state.period,
                date: this.state.filterDate,
                stock_search: this.state.stock_search ? this.state.stock_search.split(',') : ""
            }
            this.setState({
                loadingTable: true,
                stock_data: [],
                no_result: false
            })

            axios.post('/get_dividend_data', {
                ...json
            }).then((response) => {

                this.setState({
                    totalLength: response.data.total,
                    stock_data: response.data.result,

                })
                if (response.data.result.length === 0) {
                    this.setState({
                        no_result: true
                    })
                }

            }).finally(() => {
                this.setState({
                    loadingTable: false,
                })
            })
        }

    }

    handlePageClick(data) {
        this.setState({
            page: data.selected
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSortChange = (e) => {
        let val = e.target.value
        this.setState({
            selected_type: val,
            started_changing:true,
            page: 0,
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }

    handleFilterDateChange = (e) => {
        console.log(e)
        this.setState({
            filterDate: e.target.value,
            started_changing:true,
            page: 0,
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }

    handleFilterChange = (e) => {
        let val = e.target.value
        this.setState({
            period: val,
            started_changing:true,
            page: 0,
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }

    fetchData = (e) => {
        this.setState({
            scraping: true,
        })
        axios.get('/fetch_dividend_stocks').then((response) => {
            this.setState({
                orig_stock_data: response.data.result,
                stock_data: response.data.result
            })
        }).finally(() => {
            this.setState({
                scraping: false,
            })
        })
    }

    get(n) {
        if (n === undefined) return '-'
        if (n.length === 0) return '-'
        return n
    }

    handleStockChange = (e, values) => {
        const { value, name } = e.target
        this.setState({
            [name]: values?.join(',') || value,
            page: 0,
        }, () => {
            setTimeout(() => {
                if (value === this.state.stock_search) {
                    this.getData()
                }
            }, 300)
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.symbol_list !== this.props.symbol_list) {
            if (!this.state.started_changing) {
            this.setState({
                stock_search: this.props.symbol_list,
                period: "All",
                selected_type: "Date",
                filterDate:"All",
                page: 0,
            }, () => {
                this.getData()
            }
            )
        }else{
            this.setState({
                stock_search: this.props.symbol_list,
                page: 0,
            }, () => {
                this.getData()
            }  )
        }
        }
    }


    render() {
        const { loadingTable, scraping, page, totalLength } = this.state, tipProps = this.props.tipProps, stepNo = tipProps?.stepNo;
        this.props.getState && this.props.getState(this.state);
        const signals = <SignalsHandler name="dividends" algo_name="Dividends" component={options => <SignalButtons name="dividends" {...options} stepNo={stepNo} />} />
        const isPage = this.props.isPage, isMobile = this.props.isMobile;
        return (
            <>
                {isPage && isMobile && (
                    <>
                        <TipCard id='tip-1'
                            totalSteps={3}
                            activeStep={1}
                            {...tipProps}
                            className='mt-3 me-0'
                        >Hover over an item for an explanation of its function.</TipCard>
                        <TipCard id='tip-2'
                            {...tipProps}
                            totalSteps={3}
                            activeStep={2}
                        >Filter the data based on your desired criteria (ie. by date, by stock, by transaction type, etc.). View the data as grouped by company, or by individual transactions.</TipCard>
                        <TipCard id='tip-3'
                            {...tipProps}
                            totalSteps={3}
                            activeStep={3}
                        >Opt in for email signals so you can stay informed and never miss a trade. <Link to='/' className='btn btn-text text-surface-1'>Learn how to use</Link></TipCard>

                    </>
                )}
                <Tool id="dividends" algo={this.props.algo}>
                    <div className='d-flex flex-column'>
                        <TitleBar id="dividends" title="Ex-Dividend Analysis">
                            {isMobile && !isPage && signals}
                        </TitleBar>
                        <DividendsStockFilter
                            handleFilterChange={this.handleFilterChange}
                            handleSortChange={this.handleSortChange}
                            handleStockChange={this.handleStockChange}
                            handleFilterDateChange={this.handleFilterDateChange}
                            {...this.state} {...this.props}
                            stepNo={stepNo}
                        />
                    </div>
                    <div className='ToolPage-Main d-flex flex-column gap-3'>
                  
                        {(scraping || loadingTable) && <div className='bar-loader' />}

                        <DividendsStockTable
                            handleFilterDateChange={this.handleFilterDateChange}
                            stepNo={stepNo}
                            {...this.state}
                            child={(
                                <>
                                    {(isPage || !isMobile) && signals}
                                    {isPage && isMobile && <Label id="dividends" />}
                                </>
                            )}
                        />
                        <Pagination
                            pageCount={Math.ceil(totalLength / 10)}
                            initialPage={page}
                            forcePage={page}
                            onPageChange={this.handlePageClick}
                            className='m-auto mb-0'
                        />
                    </div>
                </Tool>
            </>
        )
    }
}

export default withQuery(Dividends);