import React from "react";
import { NavLink } from 'react-router-dom';

const HeaderLinkMain = ({ Icon, title, ...props }) => {
    const Root = props.href ? 'a' : props.to ? NavLink : 'button';
    return (
        <Root {...props} className={`Header-Link ${props.id || title}`}>
            {Icon && <Icon />} {title}
        </Root>
    )
}

export default HeaderLinkMain;