import React, { useContext, useEffect, useState } from "react";
import Momentum_Trader from "../momentum_trader";
import Momentum_Trader_Multi from "../momentum_trader_multi";
import Basic_Sentiment from "../basic_sentiment";
import OpenAI from "../OpenAI";
import Correlation from "../correlation";
import Patents from "../patents";
import Shorts from "../short_analysis";
import ML_Model from "../ml_model";
import Convertible_Debt from "../convertible_debt";
import Rsi from "../rsi";
import Options_Best_List from '../options_best'
import Real_Estate from '../real_estate'
import Options_Orders from '../options_orders'
import Options_Strike_Price_Comparer from '../options_strike_price_comparer'
import Dividends from '../dividends'
// import Article_Analyzer from '../article_analyzer'
import Earnings from '../earnings'
import Ipos from '../ipos'
import Insiders from '../insiders'
import Congress from '../congress'
import Senator from '../senator'
import House from '../house'
import Government from '../government'
import AutoSwipe from '../auto_swiper'
import Level2 from '../level2'
import Fundamentals from '../fundamentals'
import SiteTitle from '../../components/site_title'
import { getAlgo } from "../../apis/Algos";
import Spinner from "../../ui-v2/Spinner/Spinner";
import MinimizedContext from "../../contexts/MinimizedPagesContext";
import FDA from "../FDA";
import { checkAlgoAccess } from "../../apis/Algos";
import Super_Investor from "../Super_Investor/Super_Investor";
import axiosErrorHandler from "../../utility/axiosErrorHandler";
import OptionComparison from "../OptionComparison";
import News from "../news";

const ProductPage2 = props => {
  const pages = useContext(MinimizedContext).pages;
  const { algo, err, setState, id } = props;
  const fetchAlgo = async (pageInfo) => {
    const algo = pages.find(page => page?.algo?.id === id);
    if (algo) {
      algo.query = pageInfo[0];
      algo.id = id;
      setState({ algo });
    }
    else {
      checkPurchase(id)
        .then(algo => {
          if (algo) {
            algo.query = pageInfo[0];
            algo.id = pageInfo[1];
            setState({ algo })
          }
        })
        .catch(err => setState({ err: axiosErrorHandler(err) }))

    }
  }
  const checkPurchase = async (algoId) => {
    const data = { algoId: algoId }, result = await checkAlgoAccess(data), algo = (await getAlgo(algoId)).data.data;
    if (!result.data.access_granted) return props.history.push(`/marketplace/${algo.url_link}`);
    else return algo;
  }

  useEffect(() => {
    const pageInfo = props.location.search.replace('?algo=', '').split('&algo_id=');
    if (!algo) return fetchAlgo(pageInfo);
    if (algo.query !== pageInfo[0]) {
      if (algo.id === pageInfo[1]) setState({ algo: { ...algo, query: pageInfo[0] } })
      else fetchAlgo(pageInfo);
    }
  }, [props.location.search]);
  const renderTool = () => {
    switch (algo.query) {
      case 'momentum_trader':
        return <Momentum_Trader {...props} />;
      case 'level2':
        return <Level2 {...props} />;
      case 'auto_swiper':
        return <AutoSwipe {...props} />;
      case 'real_estate':
        return <Real_Estate {...props} />;

      case 'fundamentals':
        return <Fundamentals {...props} />;
      case 'correlation':
        return <Correlation {...props} />;
      case 'patents':
        return <Patents {...props} />
      case 'news':
        return <News {...props} />;
      case 'shorts':
        return <Shorts {...props} />;
      case 'momentum_trader_multi':
        return <Momentum_Trader_Multi {...props} />;
      case 'ml_model':
        return <ML_Model {...props} />;
      case 'basic_sentiment':
        return <Basic_Sentiment {...props} />;
      case 'convertible_debt':
        return <Convertible_Debt {...props} />;
      case 'options_best':
        return <Options_Best_List {...props} />;
      case 'option_orderer':
        return <Options_Orders {...props} />;
      case 'options_strike':
        return <Options_Strike_Price_Comparer {...props} />
      case 'rsi':
        return <Rsi {...props} />;
      case 'insiders':
        return <Insiders {...props} />;
      case 'congress':
        return <Congress {...props} />;
      case 'senator':
        return <Senator {...props} />;
      case 'open_ai':
        return <OpenAI {...props} />;
      case 'house':
        return <House {...props} />;
      case 'government':
        return <Government {...props} />
      case 'earnings':
        return <Earnings {...props} />
      case 'dividends':
        return <Dividends {...props} />
      case 'option_comparison':
        return <OptionComparison {...props} />
      // case 'article_analyzer':
      //   return <Article_Analyzer {...props} />
      case 'fda':
        return <FDA {...props} />
      case 'super_investor':
        return <Super_Investor {...props} />
        case 'ipos':
        return <Ipos {...props} />
      default:
        return <> </>
    }
  }

  return (
    <>
      {algo ?
        <SiteTitle
          title={`StockAlgos - ${algo.stockAlgoName}`}
          meta_description={`${algo.description}`}
          meta_keywords={`StockAlgos, ${algo.stockAlgoName}, Algorithm, Algo `}
        />
        :
        <SiteTitle title='StockAlgos - Algo/Tool' />
      }

      {!algo && !err ? <Spinner toMiddle /> : err ? <h1 className='position-fixed translate-middle'>{err}</h1> : renderTool()}
    </>
  )
}


export default ProductPage2;
