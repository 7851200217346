import React, { useState, useEffect } from 'react';
import useModel from '../../../hooks/useModel';
import AddIcon from '../../../icons-v2/AddIcon';
import Bars from '../../../icons-v2/Bars';
import HeaderLink from '../HeaderLink';

const LinksWrapper = ({ children, className = '', location }) => {
    const [isOpen, setIsOpen] = useState(false);
    useModel({ shouldWork: isOpen, changed: [isOpen] })
    useEffect(() => {
        if(isOpen) setIsOpen(false)
    }, [location]);
    return (
        <ul className={`${isOpen ? 'active' : ''} Header-Links ${className}`}>
            {children}
            <HeaderLink
                onClick={() => setIsOpen(!isOpen)}
                Icon={isOpen ? () => <AddIcon className='rotate-45' /> : Bars}
                title={isOpen ? 'Close' : 'Menu'}
                className="d-lg-none Header-Menu-Button"
            />
        </ul>
    )
}

export default LinksWrapper;