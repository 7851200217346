import React from 'react'
import styles from './styles.module.scss'
import ToggleText from '../../ui-v2/ToggleText/ToggleText'

export default function SearchResults({ results, className, isOverLay = true, value, onValueChange }) {
    const onSelect = e => {
        const items = value.split(',');
        items[items.length - 1] = e.target.value;
        onValueChange(items.join(', '));
    }
    return React.useMemo(() => results && results.length > 0 ? (
        <div className={`${className} ${isOverLay ? 'position-absolute top-100 left-0 w-100 card' : ''} ${styles.root}`}>
            {results.map(item => (
                <ToggleText
                    key={item._id}
                    name={item.company_name}
                    value={item.ticker}
                    title={<span ticker={item.ticker}>{item.company_name}</span>}
                    onChange={onSelect}
                />
            ))}
        </div>
    ): null, [results])
}