import React from "react";
import SiteTitle from '../../components/site_title'
import { Link } from 'react-router-dom'
import DashboardMain from "./DashboardMainV2";
import useAlgos from "../../contexts/algos/useAlgos";

const Main = props => {
  const { data, err, Loader } = useAlgos();
  if (Loader) return <Loader toMiddle />
  if (err) return <h5 className="text-center text-accent-2 my-5 py-5">{err}</h5>
  return data && data.result.length > 0 ? <DashboardMain sort={data.sort} algos={data.result} {...props} /> : (
    <div className='vh-page container flex-column gap-4 p-3 d-flex align-items-center justify-content-center'>
      <img alt='' className='mw-100' src='/NoPurchases.svg' />
      <h5 className='m-0 h6-lg fw-normal'>You haven't added any algos yet, visit the <Link className='btn-text' to="/">Marketplace</Link> to get started. </h5>
    </div>
  )
}

const DashboardPage = props => (
  <>
    <SiteTitle
      title={`StockAlgos - My Algos`}
      meta_description={`Your personal dashboard for stock algorithms and tools. `}
      meta_keywords={`StockAlgos, Dashboard, Algorithm, Tools `}
    />
    <Main {...props} />
  </>
)

export default DashboardPage;
