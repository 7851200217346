import React from "react"
import dependentTagPositionHandler from "../utility/dependentTagPositionHandler";

const usePosition = (btnRef, isActive = true, deps = []) => {
    const childRef = React.useRef();
    React.useEffect(() => {
        if (!isActive || !childRef.current || !btnRef || !btnRef.current) return;
        const unsubscribe = dependentTagPositionHandler(btnRef.current, childRef.current)
        return unsubscribe
    }, [...deps, isActive])

    return childRef;
}

export default usePosition;