import React, { Suspense, useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useApp } from "./App";
// import MarketPlacePage from "./live/MarketPlacePage/MarketPlacePage";
import MarketPlacePageInstitutional from "./live/MarketPlaceInstitutional/MarketPlacePage";
import Dashboard from "./live/Dashboard/DashboardPage";
import CookiesBar from "./components/CookiesBar/CookiesBar";
import MinimizedPagesBar from './components-v2/MinimizedPagesBar/MinimizedPagesBar';
import Footer from "./components-v2/Footer/Footer";
import lazyRoutes from "./LazyRoutes";
import Spinner from "./ui-v2/Spinner/Spinner";
// import LeaderboardPage from "./live/LeaderboardPage/LeaderboardPage";
// import Top5LeaderBoardsPage from "./live/Top5LeaderBoardsPage/Top5LeaderBoardsPage";
import AuthPopUp from "./live/AuthPopUp/AuthPopUp";
import Header from "./components-v2/HeaderV2";
import HeaderInstitutional from "./components-v2/HeaderV2_insta";
import useInitial from "./hooks/useInitial";
import ToolsSubscription from "./live/MarketPlacePage/ToolsSubscription/ToolsSubscription";
import GlobalContext from "./contexts";
import AlgoRoute from "./AlgoRoute";
import CopyBanner from "./components-v2/CopyBanner";
import StockGraph from "./components-v2/StockGraph";
import LogoutModal from "./components-v2/LogoutModal";
// import Homepage from "./live/SubscriptionPage/SubscriptionPage";

const RouterCom = () => (
  <GlobalContext>
    <Routings />
  </GlobalContext>
)


const Main = () => useMemo(() => (
  <Suspense fallback={<Spinner toMiddle={true} />}>
    <AlgoRoute />
    <Switch>
      {lazyRoutes.map((route, index) => <Route {...route} key={index} />)}
      {/* <Route path='/go-unlimited' component={Homepage} /> */}
      {/* <Route path='/leaderboard/top-10' component={Top5LeaderBoardsPage} />
      <Route path='/leaderboard' component={LeaderboardPage} /> */}
      <Route path='/institutional' component={MarketPlacePageInstitutional} />
      <Route path={['/algo_page', '/marketplace/:id']} component={() => <></>} />
      <Route exact path={["/", '/dashboard', '/home', '/marketplace', "/go-unlimited", '/marketplace_item']} component={Dashboard} />
      <Route path="*" component={props => {
        const pathname = window.location.href;
        if (pathname.includes('/leaderboard')) {
          window.location.href = window.location.href.replace(`${window.location.origin}/leaderboard`, 'https://predictr.ca')
        }
        else if (process.env.NODE_ENV !== "development") {
          window.location.href = '/404';
        }
        return ""
      }} />
    </Switch>
  </Suspense>
), [])

const Routings = () => {
  useInitial();
  const auth = useApp(), { user, isLoggedIn } = auth, history = useHistory(), location = history.location;
  return (
    <>
      {useMemo(() => (
        location.pathname === '/institutional' ? <HeaderInstitutional {...auth} /> : <Header {...auth} />
      ), [auth])}
      {useMemo(() => isLoggedIn && (
        <LogoutModal logOutUser={auth.logOutUser} />
      ), [isLoggedIn])}
      {useMemo(() => !(user && user.loading) ? (
        <>
          <ToolsSubscription {...auth} />
          <Main />
          <AuthPopUp history={history} isLoggedIn={isLoggedIn} />
        </>
      ) : <img src="/logo.svg" style={{ height: '3rem' }} className="animate-bounce absolute-middle" alt="" />, [user, isLoggedIn, location])}
      <MinimizedPagesBar />
      {useMemo(() => (
        <>
          <StockGraph />
          <CopyBanner />
          <CookiesBar />
          <Footer />
        </>
      ), [])}
    </>
  )
}

export default RouterCom;