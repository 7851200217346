import React from 'react';
import axios from 'axios';
import URL from 'url-parse';
import { AppContext } from '../../App'
import moment from "moment";
import NewsFilter from './NewsFilter';
import NewsTable from './NewsTable';
import Tool from '../../hoc-v2/withTool/Tool';
import './styles.scss';
import TitleBar, { Label } from '../../components-v2/ToolComponents/TitleBar';
import getStockSearch from '../../utility/getStockSearch';
import withQuery from '../../hoc-v2/withQuery';
import MobileSearchBox from '../../components-v2/AlgoHoldingCommonTools/MobileSearchBox';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import NewsData from './NewsData';

class News_Container extends React.Component {
  static contextType = AppContext;
  render() {
    return (
      <News
        {...this.props}
        {...this.context} />
    )
  }
}

class News extends React.Component {
  controller = null;
  constructor(props) {
    super(props);

    // Format date for API (YYYYMMDDTHHMM)
    const formatDateForApi = (date) => {
      return moment(date).format('YYYYMMDD') + 'T0000';
    };

    this.state = {
      url: new URL(window.location.href, true),
      stock_search: getStockSearch(this.props) || '',
      news_data: { feed: [] },
      topics: 'ALL',
      start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      time_from: formatDateForApi(moment().subtract(7, 'days')),
      time_to: formatDateForApi(moment()),
      sort: 'NEWEST',
      limit: 50,
      viewMode: 'individual',
      fetchPageContent: false,
      isLoading: false,
      error_msg: "",
    }
  }

  fetchNewsSentiment = () => {
    let symbols = this.state.stock_search.trim();

    // Format dates for API
    const formatDateForApi = (date) => {
      return moment(date).format('YYYYMMDD') + 'T0000';
    };

    this.setState({
      isLoading: true,
      time_from: formatDateForApi(this.state.start_date),
      time_to: formatDateForApi(this.state.end_date)
    });

    if (this.controller) {
      this.controller.abort();
    }

    this.controller = new AbortController();

    // Map UI sort options to API parameters
    let apiSort = this.state.sort;
    if (this.state.sort === 'NEWEST') {
      apiSort = 'LATEST';
    } else if (this.state.sort === 'OLDEST') {
      apiSort = 'EARLIEST';
    }

    axios.post('/api/sentiment/news', {
      tickers: symbols || undefined,
      topics: this.state.topics === 'ALL' ? undefined : this.state.topics,
      time_from: formatDateForApi(this.state.start_date),
      time_to: formatDateForApi(this.state.end_date),
      sort: apiSort,
      limit: this.state.limit,
      viewMode: this.state.viewMode,
      fetchPageContent: this.state.fetchPageContent
    }, {
      signal: this.controller.signal
    }).then((res) => {
      const news_data = res.data.data;
      this.setState({
        news_data,
        isLoading: false
      });
    }).catch((error) => {
      if (error.name !== 'AbortError') {
        console.error("Error fetching news:", error);
        this.setState({
          isLoading: false,
          error_msg: "Failed to fetch news data"
        });
      }
    });
  };

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort(); // Cleanup on unmount
    }
  }

  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    this.fetchNewsSentiment();
  }

  typingTimeout = null;

  handleChange = (e, values = null) => {
    const { name, value, type, checked } = e.target;

    // Clear any existing timeout
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    // Handle special case for stock_search
    if (name === 'stock_search') {
      const newValue = values?.join(',') || value;
      this.setState({ [name]: newValue });
    } else if (type === 'checkbox') {
      // Handle checkbox inputs by using the 'checked' property
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }

    // Debounce API call
    this.typingTimeout = setTimeout(() => {
      this.fetchNewsSentiment();
    }, 1000);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.setState && this.props.setState(this.state.news_data);
    }

    if (prevProps.symbol_list !== this.props.symbol_list) {
      this.setState({
        stock_search: this.props.symbol_list,
      }, () => {
        this.fetchNewsSentiment();
      });
    }
  }

  handleStartChange = (date) => {
    this.setState({
      start_date: moment(date).format('YYYY-MM-DD')
    }, () => {
      this.fetchNewsSentiment();
    });
  }

  handleEndChange = (date) => {
    this.setState({
      end_date: moment(date).format('YYYY-MM-DD')
    }, () => {
      this.fetchNewsSentiment();
    });
  }

  render() {
    this.props.getState && this.props.getState(this.state);
    const { isPage, isMobile } = this.props;

    return (
      <Tool
        id="news"
        algo={this.props.algo}
      >
        <div className='d-flex flex-column'>
          <TitleBar id="news" title="Market News & Sentiment" />
          <NewsFilter
            handleChange={this.handleChange}
            handleStartChange={this.handleStartChange}
            handleEndChange={this.handleEndChange}
            {...this.state}
            algo={this.props.algo}
            isMobile={isMobile}
          />
        </div>
        <div className='ToolPage-Main d-flex flex-column gap-3 pb-5'>
          <div className='d-flex d-lg-none gap-3 align-items-center w-100'>
            {isMobile && (
              <MobileSearchBox
                className='flex-1'
                name="stock_search"
                label="Stock Symbols"
                placeholder="Stock Search"
                value={this.state.stock_search}
                onChange={this.handleChange}
                watchlist={<WatchListImport forDashboard={true} handleStockChange={this.handleChange} />}
              />
            )}
            {isPage && isMobile && <Label id="news" className='me-auto' />}
          </div>

          {this.state.isLoading && <div className='bar-loader' />}
          {this.state.error_msg && <div className="alert alert-danger">{this.state.error_msg}</div>}
          {/* <NewsTable {...this.state} /> */}
          <NewsData {...this.state} />
        </div>
      </Tool>
    )
  }
}

export default withQuery(News_Container); 