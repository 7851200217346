import React from 'react';
import HeaderLink from '../HeaderLink';
import ExternalLink from '../../../icons-v2/ExternalLink';
import Buildings from '../../../icons-v2/Building';
import styles from './styles.module.scss';
import useQuery from '../../../hooks/useQuery';
import ToolTip from '../../../ui-v2/ToolTip/ToolTip';

export default function PredictrLink() {
    const isMobile = useQuery();
    return (
        <HeaderLink
            href="https://predictr.ca/"
            target="_blank"
            className={`no-fill-icon ${styles.root}`}
            id='leaderboard-link'
            title={isMobile ? <ToolTip title="Now Leaderboard is">Predictr</ToolTip> : (
                <>
                    <ExternalLink className={`position-absolute start-0 top-0 custom ${styles.externalLink}`} />
                    <span className='text-center text-lg-end position-relative d-flex flex-column'>
                        <small className='text-xs text-uppercase fw-mediam'>Now Leaderboard is</small>
                        Predictr
                    </span>
                </>
            )}
            Icon={Buildings}
        />
    )
}