import React from 'react';
import Select, { Option } from '../../../ui-v2/Select/Select';
import { useHistory } from 'react-router-dom';

const OpenIn = ({ algos, stocks }) => {
    const history = useHistory();
    const skipTools = [
        'fundamentals',
        'dividends',
        'insiders',
        'congress',
        'senator',
        'house',
        'government',
        'earnings',
        'convertible_debt',
        'options_best',
        'article_analyzer'
    ]
    return (
        <Select label='Open in' className='small badge' selectClass='small'>
            {algos.map((vals) => {
                const val = vals.algoId;
                return skipTools.includes(val.url_link) && (
                    <Option key={val._id}
                        value={val._id}
                        onClick={() => {
                            const { url_link, _id } = vals.algoId
                            history.push(
                                `/algo_page?algo=${url_link}&algo_id=${_id}&watchlist=${stocks.map(item => item.ticker).join(',')}`
                            )
                        }}
                    >
                        {val.stockAlgoName}
                    </Option>
                )
            })}
        </Select>
    )
}

export default OpenIn;