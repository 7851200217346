import React, { useEffect, useState } from 'react';
import axios from 'axios'
import Select, { Option } from '../../ui-v2/Select/Select';
import ImportIcon from '../../icons-v2/ImportIcon';
import { useApp } from '../../App';
import { Link } from 'react-router-dom';

const Main = ({
  handleStockChange, className = '', ...props
}) => {
  const [watchList, setWatchList] = useState([])
  const [show_watchlist, set_show_watchlist] = useState(false)

  const handle_watch_list_import = (e) => {
    let list = watchList
  }
  useEffect(() => {
    axios.get('/watchlist')
      .then(({ data }) => data && setWatchList(data))
      .catch(err => console.log(err))
  }, [show_watchlist])

  const onChange = (e, values) => handleStockChange(e, values.join().split(',').filter(v => v !== ''))




  return (
    <Select name="stock_search" onChange={onChange} icon='' label={props.label} {...props}>
      {watchList.map((value, index) => value.stocks.length > 0 && (
        <Option key={index} value={value.stocks.map((x) => x.ticker)}>{value.name}</Option>
      ))}
    </Select>
  )
}


const WatchListImport = ({ algo, forDashboard, ...props }) => {
  const isLoggedIn = useApp().isLoggedIn;
  const label = <><ImportIcon /> {forDashboard ? '' : 'Import Watchlist'}</>
  return isLoggedIn ? <Main label={label} {...props} /> : <Link className='btn btn-text gap-1' to="#register">{label}</Link> ;
};

export default WatchListImport;