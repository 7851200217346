import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";

const hProps = {
    value: "",
    api: "stock_item",
    initialsRequired: false
}

let initials;

const useStockSearch = (props = hProps) => {
    const { value: v, api, initialsRequired, ...params } = { ...hProps, ...props };
    const [value, setValue] = useState(v);
    const [results, setResults] = useState([]);
    const [initialResults, setInitialResults] = useState(initials || []);
    let blurTimer = useMemo(() => setTimeout(() => { }, 500), []);

    const onClear = useCallback(() => {
        setValue('');
        setResults([])
    }, [])

    const onChange = useCallback(e => {
        const value = e.target.value, v = value.trim()
        if (!v) return onClear();
        const item = v.split(',').map(item => item.trim()).pop();
        setValue(value);
        if(e.resultItem) return setResults([]) 
        if(!item) return
        axios.get(`/${api}?search=${item}`, { params })
            .then(res => setResults(res.data))
            .catch(err => console.log(err))
    }, [])

    const onAdd = item => {
        setInitialResults([item, ...initialResults]);
        setResults([item, ...results]);
        setValue(item.ticker);
    }

    useEffect(() => {
        if(initials) return
        initials = true;
        axios.get('/fetch_initial_list_leaderboard', { params })
                .then(res => {
                    initials = res.data.result;
                    if(initialsRequired) setResults(initials)
                    setInitialResults(initials)
                })
    }, []);
    

    return {
        value,
        results: results.length === 0 && value && value.trim() === '' ? initialResults : results,
        onChange,
        onClear,
        onAdd,
        onFocus: () => setResults(initialResults),
        onBlur: () => {
            clearTimeout(blurTimer)
            blurTimer = setTimeout(() => {
                setInitialResults(results)
                setResults([]);
            }, 500);
        }
    }

}

export default useStockSearch;
