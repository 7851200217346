import React from 'react';
import moment from "moment";
import PickersUtilsProvider from '../../ui-v2/PickersUtilsProvider/PickersUtilsProvider';
import DatePicker from '../../ui-v2/DatePicker/DatePicker';
import BoxField from '../../ui-v2/BoxField/BoxField';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';
import SearchField from '../../components-v2/AlgoHoldingCommonTools/SearchField';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';

const CoorelationFilter = ({ handleChange, isLoading, stocks, start_date, end_date, error_msg, handleStartChange, handleEndChange, submitStocks, addStock, handleStockChange, layout, stock_search, algo, onStockRemove }) => {
    return (
        <LeftSection id="correlation" algo={algo}>
            <div className='d-grid gap-2 overflow-initial'>
                <BoxField name="layout"
                    type='radio'
                    onChange={handleChange}
                    value="table"
                    label='Find Comparable Stocks'
                    checked={layout === "table"}
                />
                <BoxField
                    name="layout"
                    type='radio'
                    value="graph"
                    onChange={handleChange}
                    label='Compare Stocks'
                    checked={layout === "graph"}
                />
            </div>
            {layout !== 'table' ? (
                <div className='d-flex flex-column gap-3'>
                    <p className='mb-0'>Comparison Dates</p>
                    <PickersUtilsProvider>
                        <DatePicker
                            inputVariant='outlined'
                            label="Start Date (historical data)"
                            value={moment(start_date)}
                            name='start_date'
                            onChange={handleStartChange}
                        />
                        <DatePicker
                            className="__custom-input-field"
                            label="End Date (historical data)"
                            value={moment(end_date)}
                            name='end_date'
                            onChange={handleEndChange}
                        />
                    </PickersUtilsProvider>
                    <p className='mb-0 mt-2'>Stocks to Compare</p>
                    {stocks.map((stock, index) => (
                        <div className='flex-between gap-2'>
                            <SearchField
                                key={index}
                                name={`stock_${index}`}
                                label={`Stock ${index + 1}`}
                                value={stock}
                                onChange={e => handleStockChange(index, e.target.value)}
                                placeholder='Enter Stock Name'
                            />
                            {index >1 && (
                            <button onClick={() => onStockRemove(index)} type='button' className='btn btn-icon no-padding text-accent-2'>
                                <i className="fas fa-trash-alt"></i>
                            </button>
                            )}
                        </div>
                    ))}
                    <button className='btn btn-primary bg-accent-3' onClick={addStock}>Add Stock +</button>



                    <button
                        className={`btn btn-primary ${isLoading ? 'progress-btn' : ''}`}
                        onClick={submitStocks}
                        disabled={isLoading || stocks.length < 2}
                    >
                        Compare
                    </button>
                    {error_msg.length > 0 &&
                        <p style={{ color: "red" }}>{error_msg}</p>
                    }
                </div>
            ) : (
                <>
                    <p>Correlated Stocks</p>
                    <SearchField
                        name={`stock_search`}
                        label={`Find Correlated Stocks For:`}
                        value={stock_search}
                        onChange={handleChange}
                        placeholder='Enter Stock Symbol'
                        endAdornment={<WatchListImport forDashboard={true} handleStockChange={handleChange} />}
                     />
                </>
            )}
        </LeftSection>
    )
}

export default CoorelationFilter;
