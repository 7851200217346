import React from "react";
import useGet from "../../hooks/useGet";
import AlgosContext from "./context";

export default function Provider(props) {
    const tools = useGet('/my_active_algos', {}, false);
    return (
        <AlgosContext.Provider value={tools}>
            {props.children}
        </AlgosContext.Provider>
    )
}