import React from 'react';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import SelectField from '../../ui-v2/SelectField/SelectField';
import { MenuItem } from '@material-ui/core';
import RangePicker from '../../ui/RangePicker';
import InputField from '../../ui-v2/InputField/InputField';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';
import SearchField from '../../components-v2/AlgoHoldingCommonTools/SearchField';


const Filters = ({ onStateChange, filters, algo, ...props }) => {
    const onChange = e => onStateChange({ filters: { ...filters, [e.target.name]: e.target.value } })
    const onWatchChange = (e) => onStateChange({ filters: { ...filters, search: e.target.value } })
    return (
        <LeftSection id="fda" className="gap-3" algo={algo}>
            <small>Data Filters</small>
            <SearchField
                name='search'
                label="Search for a specific stock"
                value={filters.search}
                onChange={onChange}
                endAdornment={<WatchListImport handleStockChange={onWatchChange} forDashboard={true} isMultiple algo={algo} />}
            />
            <SelectField label='Filter by Outcome' onChange={onChange} name='filtered_outcome' value={filters.filtered_outcome}>
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='approved'>Approved</MenuItem>
                <MenuItem value='approval'>Approval</MenuItem>
                <MenuItem value='declined'>Declined</MenuItem>
                <MenuItem value='deferred'>Deferred</MenuItem>
                <MenuItem value='extended'>Extended</MenuItem>
                <MenuItem value='supported'>Supported</MenuItem>
            </SelectField>
            <SelectField label='Filter by Event Type' onChange={onChange} name='filtered_event_type' value={filters.filtered_event_type}>
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='decision'>Decision</MenuItem>
                <MenuItem value='reviewal'>Reviewal</MenuItem>
            </SelectField>
            <RangePicker
                ranges={[filters.event_date]}
                onChange={e => onStateChange({ filters: { ...filters, event_date: e.selection } })}
                component={({ show, value }) => (
                    <InputField
                        fullWidth
                        name='event_date'
                        label="Filter by Event Date"
                        type="text"
                        onFocus={show}
                        value={value}
                        title="Want to see the insider trading that occurred on a specific date?"
                    />
                )}
            />
        </LeftSection>
    )
}

export default Filters;