import React from 'react';
import Table from "../../ui-v2/Table/Table"
import TR from './TR';
import HistoryModal from './HistoryModal';
import SectorAnalysisModal from './SectorAnalysisModal';
import styles from './StockTable.module.scss';

const Main = props => {
    const [stockHistory, setHistory] = React.useState(null);
    const [stockSector, setSector] = React.useState(null);

    return (
        <>
            {stockSector && <SectorAnalysisModal {...stockSector} closeHandler={() => setSector(null)} />}
            {stockHistory && <HistoryModal {...stockHistory} closeHandler={() => setHistory(null)} />}
            {props.superInvestors.length > 0 && props.superInvestors.map((superInvestor, index) => (
                <TR
                    key={index}
                    superInvestor={superInvestor}
                    showHistory={(stock, company_name, holdings) => setHistory({ stock: stock, company_name: company_name, name: superInvestor.name, history: holdings })}
                    showSector={() => setSector({ name: superInvestor.name, sector_breakdown: superInvestor.sector_breakdown })}
                    stock_search={props.stock_search.split(',').map(stock => stock.trim())}
                />
            ))}
        </>
    )
} 

const headings = [
    { title: 'Fund Name', key: 'name' },
    { title: 'Portfolio Value', key: 'portfolio_value_string' },
    { title: 'No.Of Stock', key: 'number_stocks_held' }
]

const StockTable = props => (
    <>
        <Table className='product_tool_table'>
            <thead className='text-uppercase position-relative z-index-1'>
                <tr>
                    <th></th>
                    {headings.map(item => (
                        <th key={item.key}
                            onClick={() => props.onSort(item.key)}
                            className={`${styles.heading} ${props.sort === item.key ? `${styles.active} ${props.desc ? '' : styles.desc}` : ''}`}
                        >{item.title}</th>
                    ))}
                    <th>Sector%</th>
                    <th>Updated on</th>
                </tr>
            </thead>
            <tbody>
                <Main {...props} />
            </tbody>
        </Table>
    </>
)

export default StockTable;