import React, { useState, useEffect } from 'react';
import Chat from "../../icons-v2/Chat";
import styles from './styles.module.scss';
import ChatRoom from './chatroom';
import axios from 'axios';
import useModel from '../../hooks/useModel';
import defaultStates from './defaultStates';
import toastr from 'toastr'
import AiModelsForChat from './ai_models_for_chat';
import { useApp } from '../../App';



export default function ChatBot(props) {
    const [className, setClass] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useModel({ shouldWork: className === 'active', closeHandler: onClose, isActive: className === 'active' })
    const [chat, setChat] = useState(defaultStates.chat);
    const [alertText, setAlertText] = useState('');
    const [currTokens, setCurrTokens] = useState(0);
    const [selectedModel, setSelectedModel] = useState("gpt-4o-mini");
    const { isLoggedIn } = useApp();

    // Fetch user's preferred model on component mount if logged in
    useEffect(() => {
        
        if (!isLoggedIn) return;
        console.log(isLoggedIn,'is logged in')
        
        axios.get('/fetch_chat_model')
            .then(response => {
                if (response.data && response.data.status === 'success') {
                    setSelectedModel(response.data.data);
                }
            })
            .catch(err => {
                console.error('Error fetching chat model preference:', err);
            });
    }, [isLoggedIn]);

    // Save user's selected model whenever it changes
    const handleModelChange = (model) => {
        setSelectedModel(model);
        
        if (isLoggedIn) {
            axios.post('/save_chat_model', { selectedModel: model })
                .then(response => {
                    console.log('Model preference saved successfully');
                })
                .catch(err => {
                    console.error('Error saving model preference:', err);
                });
        }
    };

    const toggle_gpt = (index) => {
        console.log(index,'here')
        const newChat = chat.map((item, i) => {
            if (i === index) {
                return { ...item, send_to_gpt: !item.send_to_gpt }
            }
            return item;
        }
        )
        setChat(newChat)
        console.log(newChat)
    }
    useEffect(() => {
        if(props.newMessage && props.newMessage.algo) {
            // Check for duplicate algo_name
            if (
                chat.some(c => c.algo_name === props.newMessage.algo?.stockAlgoName && c.message == `${JSON.stringify(props.newMessage)}`)
            ){
                
             
            } else if (
                chat.some(c => c.algo_name === props.newMessage.algo?.stockAlgoName && c.message !== `${JSON.stringify(props.newMessage)}`)
            ){
                setAlertText(`Data for ${props.newMessage.algo?.stockAlgoName} Updated in Chat.`)
            }
             else if (props.newMessage.algo?.stockAlgoName){
                setAlertText(`Data for ${props.newMessage.algo?.stockAlgoName} Added to Chat.`)
            } 
            
            const chatWithoutDuplicate = chat.filter(c => !c.algo_name || c.algo_name !== props.newMessage.algo?.stockAlgoName);
            const messageContent = "Stockalgos.com is a website that offers a variety of tools and algorithms for stock market analysis and trading.";
            const filteredChat = chatWithoutDuplicate.filter(c => !c.message.includes(messageContent));

            setChat(prevChat => [...filteredChat, {message: `${JSON.stringify(props.newMessage)}`, hidden: true, send_to_gpt:true, role: 'user', algo_name: props.newMessage.algo?.stockAlgoName, date: new Date().toLocaleString()}]);
            
            let suggest = [
                { suggestion: "What do you think about this data?" },
                { suggestion: "Help me make some conclusions about this data" },
                { suggestion: "What is the most promising based off this dataset" },
                { suggestion: "How do I use this tool?" },
            ];
        
            setSuggestions(suggest);
        }
    }, [props.newMessage]);

    const [suggestions, setSuggestions] = useState(defaultStates.suggetions);
    const onChat = message => {
        if (isLoading) return; // Prevent multiple submissions while loading
        
        setIsLoading(true);
        const newChat = [...chat, { message, role: 'user', send_to_gpt: true, date: new Date().toLocaleString() }];
        
        setChat([...newChat, 'loading']);
        let filteredChat = newChat.filter(c => c.send_to_gpt);
        setSuggestions([]);

        axios.post('/open_ai_chat', 
            { chat: filteredChat, model: selectedModel },
            { timeout: 60000 } // 30 second timeout
        )
            .then(res => {
                console.log(res);
                const machineResponse = { message: res.data.result, isRobot: true, send_to_gpt: true, role: 'machine', date: new Date().toLocaleString() };
                setChat([...newChat, machineResponse]);
                return axios.post('/generate_suggestions', { chat: [...newChat, machineResponse] });
            })
            .then(res => setSuggestions(res.data.suggestions))
            .catch(err => {
                console.log(err);
                let errorMessage;
                if (err.code === 'ECONNABORTED') {
                    errorMessage = "Request timed out. The server is taking too long to respond. Please try again.";
                } else if (err.response?.status === 503) {
                    errorMessage = "The server is currently busy processing your request. Please wait a moment and try again.";
                } else {
                    errorMessage = "Too much data to generate a response. Try resetting, then filtering the data and trying again.";
                }
                
                const errorResponse = { message: errorMessage, role: 'error' };
                setChat(prevChat => {
                    const chatWithoutLoading = prevChat.filter(c => c !== 'loading');
                    return [...chatWithoutLoading, errorResponse];
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    const onSubmit = e => {
        e.preventDefault();
        const form = e.target;
        const message = form.message.value;
        form.reset();
        onChat(message)
    }
    function onClose() {
        setClass(styles.opening);
        setTimeout(() => {
            setClass(null);
        }, 200)
    }
    return (
        <>
            {className && (
                <ChatRoom 
                    chat={chat}
                    suggestions={suggestions}
                    toggle_gpt={toggle_gpt}
                    alertText={alertText}
                    onClose={onClose}
                    className={className}
                    onSubmit={onSubmit}
                    onSuggestion={e => onChat(e.target.textContent)}
                    reset={() => {
                        setChat(defaultStates.chat)
                        setSuggestions(defaultStates.suggetions)
                        setAlertText('Chat Reset No Data for Any Tools')
                    }}
                    selectedModel={selectedModel}
                    onModelChange={handleModelChange}
                    AiModelsForChat={
                        <AiModelsForChat 
                            selectedModel={selectedModel} 
                            onModelChange={handleModelChange}
                        />
                    }
                />
            )}
            {(!className || className === styles.opening) && (
                <button
                    onClick={() => {
                        setClass(styles.opening);
                        setTimeout(() => {
                            setClass('active');
                        }, 200)
                    }}
                    className={`bg-gradient-5 h6 ${className} text-surface-1 px-4 btn ${styles.chatbtn}`}
                > StockGPT
                    <Chat />
                </button>
            )}
        </>
    )
}