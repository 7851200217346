import React from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import TableData from './MainTableData';

export default function MainTable(props) {
    return (
        <>
            <thead>
                <tr>
                    <th>
                        <div>No</div>
                    </th>
                    <th>
                        <ToolTip title="The official title of the patent.">
                            Patent Title
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="A short summary or abstract describing the patent.">
                            Patent Abstract
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="The date on which this patent was issued.">
                            Patent Date
                        </ToolTip>
                    </th>
                </tr>
            </thead>
            <tbody>
                <TableData {...props} />
            </tbody>
        </>
    )
}