import React, { useState } from 'react';
import Graph from '../../live/graph_module';

const ToolSymbol = ({ unique_date=null, selected_stock }) => {
    const [showGraph, setShowGraph] = useState(false);

    return (
        <>
            <button 
                className="badge cursor-pointer" 
                onClick={() => setShowGraph(true)}>
                {selected_stock}
            </button>
            {showGraph && (
                <Graph
                    date={unique_date}
                    symbol={selected_stock}
                    text={`Chart for ${selected_stock}`}
                    close={() => setShowGraph(false)}
                />
            )}
        </>
    );
}

export default ToolSymbol;
