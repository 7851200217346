import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import RangePicker from '../../ui/RangePicker';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';
import BoxField from '../../ui-v2/BoxField/BoxField';
import SearchField from '../../components-v2/AlgoHoldingCommonTools/SearchField';

// import { useHistory } from 'react-router-dom';

const EarningStockFilter = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    handleStockChange,
    stock_search,
    handleTimeChange,
    time,
    times,
    stepNo,
    algo,
    handleToggle,
    specific_search,
    handleRangeSelect,
    selectionRange,
    date_range_enabled,
    should_calc_options
}) => {
    // const history = useHistory();
    // const { pathname, replace, location } = history;
    // const onRepresentationChanged = e => replace({ pathname, hash: e.target.value, search: location.search })
    return (
        <LeftSection id="earnings" className="gap-3" algo={algo}>
            <small>Data Filters</small>
            <BoxField label="Specific Search" checked={specific_search} onChange={handleToggle} name="specific_search" />

            <SearchField
                fullWidth
                name='stock_search'
                label="Search stock symbol"
                onChange={handleStockChange}
                value={stock_search}
                endAdornment={<WatchListImport forDashboard={true} handleStockChange={handleStockChange} algo={algo} />}
            />

            <SelectField
                value={time}
                name="time"
                onChange={handleTimeChange}
                label="Filter By Time"
            >
                {times.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
            </SelectField>
            <BoxField
                checked={date_range_enabled}
                onChange={handleToggle}
                name="date_range_enabled"
                label="Use Date Range?"
            />
                {date_range_enabled && <> <small className="text-muted">Note: Date range is inclusive</small>
                    <RangePicker
                        ranges={[selectionRange]}
                        onChange={handleRangeSelect}
                        component={({ show, value }) => (
                            <InputField
                                fullWidth
                                name='period_range'
                                label="Filter By Date Range"
                                type="text"
                                onFocus={show}
                                value={value}
                                title={`Want to earnings ranges?`}
                            />
                        )}
                    />
                </>
                }
                {!date_range_enabled &&
                    <SelectField
                        title="Want to see the earnings for a specific day?"
                        fullWidth
                        value={period}
                        name="period"
                        onChange={handleFilterChange}
                        label="Filter By Earnings Date"
                    >

                        {period_type.map((type) => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                    </SelectField>
                }
                <SelectField
                    title="You can sort by stocks that have the most participants, most trades, or the cost of the stock purchased."
                    fullWidth
                    value={selected_type}
                    name="selected_type"
                    onChange={handleSortChange}
                    label="Sort By"
                >
                    {sort_type.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
                <BoxField
                    checked={should_calc_options}
                    onChange={handleToggle}
                    name="should_calc_options"

                    label="Run Options"
                />
            
        </LeftSection>
    )
}

export default EarningStockFilter;
