import React from 'react';
import HeaderLink from './HeaderLink';
import BagIcon from '../../icons-v2/BagIcon';
import LinksWrapper from './LinksWrapper';
import PredictrLink from './predictr-link';

const Main = props => {
    return (
        <LinksWrapper {...props}>
            {/* <HeaderLink title='Marketplace' exact id="marketplace-link" to='/' Icon={HomeIcon} />
            {props.isLoggedIn && <HeaderLink title='My Algos' to='/dashboard' Icon={BagIcon} />} */}
            <HeaderLink title='My Dashboard' to='/dashboard' Icon={BagIcon} />
            <PredictrLink />
        </LinksWrapper>
    )
}

export default Main;