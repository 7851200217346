import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import InputField from '../../ui-v2/InputField/InputField';
import Model from "../../ui-v2/Modal/Model";
import SelectField from '../../ui-v2/SelectField/SelectField';
import Form from '../../utility/Form';
import getSignalsFields from '../../utility/getSignalsFields';
import SignalField from './SignalField';
import axios from 'axios';
import toastr from 'toastr'
import axiosErrorHandler from '../../utility/axiosErrorHandler';
import DeleteModal from './DeleteModal';
import { fields } from "../../live/fundamentals/fields"
import ImportIcon from '../../icons-v2/ImportIcon';

const DyanamicWatchlistModal = ({ component, algos, onDynamicCreate, watchlistItem, toolName, onDelete, ...props }) => {
    const [selectedTool, setSelectedTool] = useState(watchlistItem?.type_of_algo_data_fetch || toolName || (algos && algos[0]?.algoId.url_link) || '');
    const signals = getSignalsFields()
    const formFields = signals[selectedTool];
    const onSubmit = async ({ values, hidePopUp }) => {
        const { name, ...filters } = values;
        if (selectedTool === "fundamentals") {
            filters.sortby = fields.filter((x) => x.title === filters.sortby)[0].name
            filters.asc = filters.asc === "Highest" ? 'false' : 'true'
        }
        const data = { filters: [filters], name, type_of_algo_data_fetch: selectedTool }
        axios[watchlistItem ? 'put' : 'post'](`/dynamic_watchlist/${watchlistItem?._id || ''}`, data)
            .then(res => {
                toastr.success(`Watchlist ${watchlistItem ? 'updated' : 'created'} successfully!`, 'Success')
                onDynamicCreate && onDynamicCreate({ _id: watchlistItem?._id, stocks: [], ...data, ...res.data, watchlist_type: 'dynamic' });
                hidePopUp()
            })
            .catch(axiosErrorHandler)

    }


    return (
        <Model Root={Form}
            onSubmit={onSubmit}
            title='Create a dynamic watchlist'
            component={component}
            footer={isLoading => (
                <div className='model-footer'>
                    {watchlistItem && <DeleteModal _id={watchlistItem?._id} onDelete={onDelete} />}
                    <button disabled={isLoading}
                        className={`btn btn-primary ${isLoading ? 'progress-btn' : ''}`}
                    >
                        {watchlistItem ? 'Update' : 'Create'}
                    </button>
                </div>
            )
            }
            {...props}
        >
            <h6 className="m-0">Create a watchlist that auto-updates based on <br className='d-md-block d-none' /> parameters set by you.</h6>
            {algos && (
                <>
                    <SelectField
                        label='Pick a tool'
                        value={selectedTool}
                        errorText='This field is required!'
                        onChange={e => setSelectedTool(e.target.value)}
                    >
                        {algos.map(tool => {
                            const { url_link, stockAlgoName } = tool.algoId;
                            return signals[url_link] && (
                                <MenuItem
                                    key={tool._id}
                                    value={url_link}
                                >
                                    {stockAlgoName}
                                </MenuItem>
                            )
                        })}
                    </SelectField>
                </>
            )}
            <InputField label='Watchlist name' defaultValue={watchlistItem?.name} name='name' required errorText="Watchlist can't be empty!" />
            <div className='text-accent-4'>
                <p className='m-0 pb-2'>Set filters</p>
                <div className='d-grid overflow-initial col-2 gap-3'>
                    {formFields && formFields.map((item, index) => {
                        if (watchlistItem || (item && item.array)) {
                            const value = watchlistItem?.filters[0]?.[item.state_name] ?? item.array?.[0] ?? '';
                            return (
                                <SignalField
                                    key={index}
                                    {...item}
                                    value={value}
                                />
                            )
                        } else {
                            return null;
                        }
                    })}
                    
                    <InputField name='number_of_results' type='number' defaultValue={watchlistItem?.filters[0].number_of_results || 5} required label='Number of Results' />
                </div>
                <p className='mt-2 mb-0'>Your watchlist will auto-update everyday.</p>
            </div>
            <div className='bg-primary-lighten-5 p-2'>
                <small className='m-1 d-flex gap-2 align-items-center'>
                    <b className='text-primary'>Tip: </b>
                    You can use <div style={{width:"20px"}}><ImportIcon/></div> to import this watchlist into many of the tools on the site.
                </small>
            </div>
        </Model>
    )
}

export default DyanamicWatchlistModal;
