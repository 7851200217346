import React, { useState } from "react";
import WatchlistV2 from "../../components-v2/WatchlistV2";
import useModel from "../../hooks/useModel";
import Algos from "./Algos";
import TotalView from "./total_view";
import useQuery from "../../hooks/useQuery";
import { useApp } from "../../App";
import TopBar from "./TopBar";


const DashboardMain = props => {
    const [state, setS] = useState({
        algos: [...props.algos],
        // algos: props.algos.filter(algo => algo.algoId.url_link === "super_investor"),
        search: "",
        isMenu: false,
        dashboard_view: localStorage.getItem('dashboard_view') || 'all',
        sort: props.sort || []
    }), { algos, search, isMenu, dashboard_view, sort } = state, isMobile = useQuery(), isLoggedIn = useApp().isLoggedIn;
    useModel({ shouldWork: isMenu && isMobile, changed: [isMenu], scrollToTop: true });
    const setState = update => setS(state => ({ ...state, ...update }));
    if (state.sort) algos.sort((a, b) => sort.indexOf(a.algoId._id) - sort.indexOf(b.algoId._id))
    return (
        <div className={`d-flex position-relative ${state.dashboard_view === 'all' ? 'Make-Footer-Absolute' : ''}`}>
            <div className="flex-1 d-grid overflow-">
                <TopBar isLoggedIn={isLoggedIn} {...state} setState={setState} />
                {algos.length > 0 ? dashboard_view === "list" ?
                    <Algos
                        sort={props.sort}
                        algos={algos.filter(algo => algo.algoId.stockAlgoName.toLowerCase().includes(search.toLowerCase()))}
                    /> : (
                        <TotalView
                            isLoggedIn={isLoggedIn}
                            setState={setState}
                            {...state}
                            onChange={e => setState({ search: e.target.value })}
                        />
                    ) : <h3 className='my-5 h4-sm container py-5 text-accent-2 fw-mediam text-center'>404, algos with keyword "{search}" doesn't exist!</h3>}
            </div>
            <WatchlistV2 dashboard_view={dashboard_view === 'all'} isMenu={isMenu} {...props} onClose={() => setState({ isMenu: false })} />
        </div>
    )

}

export default DashboardMain;
