import React from 'react';
import moment from "moment";
import PickersUtilsProvider from '../../ui-v2/PickersUtilsProvider/PickersUtilsProvider';
import DatePicker from '../../ui-v2/DatePicker/DatePicker';
import InputField from '../../ui-v2/InputField/InputField';
import BoxField from '../../ui-v2/BoxField/BoxField';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';
import SearchField from '../../components-v2/AlgoHoldingCommonTools/SearchField';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';

const PatentFilter = ({ handleChange, isLoading, stocks, start_date, end_date, error_msg, handleStartChange, handleEndChange, addStock, handleStockChange, layout, stock_search, algo, onStockRemove, keyword }) => {
    return (
        <LeftSection id="patents" algo={algo}>
            <>
                <p className='mt-0 mb-1'>Patented Stocks</p>
                <SearchField
                    name={`stock_search`}
                    label={`Find Patents for These Stocks:`}
                    value={stock_search}
                    onChange={handleChange}
                    placeholder='Enter Stock Symbol'
                    endAdornment={<WatchListImport forDashboard={true} handleStockChange={handleChange} />}
                />
                <InputField
                    name='keyword'
                    label='Keyword'
                    value={keyword}
                    onChange={handleChange}
                    placeholder='Enter Keyword'
                />
                <p className='mb-0 mt-1'>Patent Dates</p>
                <PickersUtilsProvider>
                    <DatePicker
                        inputVariant='outlined'
                        label="Start Date (historical data)"
                        value={moment(start_date)}
                        name='start_date'
                        onChange={handleStartChange}
                    />
                    <DatePicker
                        className="__custom-input-field"
                        label="End Date (historical data)"
                        value={moment(end_date)}
                        name='end_date'
                        onChange={handleEndChange}
                    />
                </PickersUtilsProvider>
            </>
        </LeftSection>
    )
}

export default PatentFilter;
