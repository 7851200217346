import React, { useState } from 'react';
import moment from 'moment'
import ChevronBottom from '../../icons-v2/ChevronBottom';
import styles from './StockItem.module.scss';

const StockItem = props => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { stock, selectionRange, page } = props
    return (
        <>
            <tr onClick={() => setIsExpanded(!isExpanded)} className={`${styles.root} ${isExpanded ? styles.active : ''}`}>
                <td><ChevronBottom className={`text-accent-4 ${isExpanded ? 'rotate-180' : ''}`} /></td>
                <td>{page}</td>
                <td>
                    <button
                        aria-details={`Government Reaction for ${stock.ticker}`}
                        className='badge'
                        id='ticker'
                        about={stock.ticker}
                    >{stock.ticker}</button>
                </td>
                <td>{stock.individual}</td>
                <td>{stock.include}</td>
                <td>{moment(selectionRange.startDate).format('MM/DD/YYYY')} | {moment(selectionRange.endDate).format('MM/DD/YYYY')}</td>
                <td>{stock.amounts}</td>
            </tr>
            {isExpanded && (
                <tr className={`border-bottom ${styles.expandedRow}`}>
                    <td colSpan={8} className='p-0'>
                        <table className='primary-table'>
                            <thead className="no-opacity">
                                <tr>
                                    <th></th>
                                    <th>Number</th>
                                    <th>Department Name</th>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Cost($)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stock.items.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><ChevronBottom className='opacity-0' /></td>
                                                <td>{index + 1}</td>
                                                <td>{item.representative}</td>
                                                <td>{moment(item.transaction_date).format('MM/DD/YYYY')}</td>
                                                <td>
                                                    <div className='description'>
                                                        <span>
                                                            {item.description}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{parseFloat(item.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            )}
        </>
    )
}

export default StockItem;
