import React, { useEffect } from 'react';
import Model from '../../ui-v2/Modal/Model';
import SignUpPage from '../SignUpPage';
import LoginPage from '../login';
import { useLocation } from 'react-router-dom';
import PasswordReset from '../PasswordReset';
import ForgotPasswordPage from '../ForgotPasswordPage';


const AuthPopUp = ({ isLoggedIn, history }) => {
    const { search, hash } = useLocation(), active = /register|login|password_reset|forgot_password/i.test(hash), close = () => history.replace({ search, hash: '' });

    useEffect(() => {
        if (active && isLoggedIn) {
            close()
            history.replace({ hash: '', search })
        };
    }, [isLoggedIn])
    
    return !isLoggedIn && active && (
        <Model show={true} isNormal={true} className="Auth-Modal" closeHandler={close}>
            {hash === '#login' && <LoginPage isPopup={true} />}
            {hash === "#register" && <SignUpPage isPopup={true} />}
            {hash === "#password_reset" && <PasswordReset />}
            {hash === '#forgot_password' && <ForgotPasswordPage history={history} />}
        </Model>
    )
}

export default AuthPopUp;
