import React from "react";
import Graph_module from "../live/graph_module";

export default function StockGraph() {
    const [state, setState] = React.useState(null)
    React.useEffect(() => {
        const cb = e => {
            const elm = e.target;
            if (elm.id === 'ticker') {
                const symbol = elm.getAttribute('about');
                if(symbol) {
                    setState({
                        symbol: elm.getAttribute('about').toUpperCase(),
                        text: elm.getAttribute('aria-details') || "Stock chart for " + symbol,
                    })
                }
            }
        }
        window.addEventListener('click', cb)
        return () => window.removeEventListener('click', cb);
    }, [])
    return state && <Graph_module {...state} close={() => setState(null)} />
}