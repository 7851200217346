import React, { useMemo, useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import ProductPage2 from './live/AlgoHoldingPage/ProductPage';
import ChatBot from './components-v2/ChatBot';
import Portal from './hoc-v2/Portal';
import { useAlgo } from './contexts/AlgoContext';
import { getAlgoByName } from './apis/Algos';

export default function AlgoRoute() {
    const location = useLocation();
    const [id, setId] = useState(null);

    // Utility to extract algo_id from location
    const getAlgoIdFromLocation = (location) => {
        let searchParams = location.search;
        if (!searchParams && location.hash) {
            const hashParamsIndex = location.hash.indexOf('?');
            if (hashParamsIndex !== -1) {
                searchParams = location.hash.substring(hashParamsIndex);
            }
        }
        return new URLSearchParams(searchParams).get('algo_id');
    };

    // Effect to update id when location changes
    useEffect(() => {
        const cb = async () => {
            const algoId = getAlgoIdFromLocation(location);
            if(algoId) return setId(algoId);
            const pathname = location.pathname;
            if(pathname.includes('/marketplace/')) {
                const algoName = location.pathname.replace('/marketplace/', '');
                if (algoName) {
                    const res = (await getAlgoByName(algoName)).data;
                    if(res.status) setId(res.data._id)
                    else window.location.href = '/404';
                }
            }
        }
        cb()
    }, [location]);

    // Effect to update id when the hash changes due to scrolling or user navigation
    useEffect(() => {
        const handleScrollOrHashChange = () => {
            const algoId = getAlgoIdFromLocation(window.location);
            if (algoId !== id) {
                setId(algoId);

            }


        };

        window.addEventListener('scroll', handleScrollOrHashChange);

        return () => {
            window.removeEventListener('scroll', handleScrollOrHashChange);
        };
    }, [window.location]);

    const isDashboardView = !location.pathname.includes('algo_page');

    const { getAlgoState, setAlgoState, getAlgoStateForChat } = useAlgo();
    const algoState = isDashboardView ? null : getAlgoState(id);
    const algoStateForChat = getAlgoStateForChat(id);
    const memoizedRoute = useMemo(() => {
        if (!isDashboardView && id) {
            let loadable = algoState.algo !== null ? algoState : {}
            return (
                <Route
                    path='/algo_page'
                    component={props => (
                        <ProductPage2
                            id={id}
                            setState={data => setAlgoState(id, data)}
                            {...loadable}
                            {...props}
                        />
                    )}
                />
            );
        }
        return null;
    }, [id, isDashboardView, algoState?.algo]);


    return (
        <>
            {memoizedRoute}

            <Portal id="chat-wrapper">
                <ChatBot newMessage={algoStateForChat} />
            </Portal>
        </>
    );
}