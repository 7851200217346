import React, { useMemo } from 'react';
import usePost from '../../hooks/usePost';
import WriteReivew from './WriteReview';


const Wrapper = props => {
    const data = { userId: localStorage.getItem("userId"), algoId: props.algo._id }
    const tools = usePost('/algos/review/get', data);
    return tools.res ? <WriteReivew setReview={tools.setStatus} {...props} {...tools.res} {...data} /> : ""
}

const WriteAReviewModalWrapper = props => useMemo(() => props.algo._id ? <Wrapper {...props} /> : null, [props.algo])

export default WriteAReviewModalWrapper;
