import React, { useState } from 'react';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import ClockLoader from "react-spinners/ClockLoader";
import styles from '../congress/StockItem.module.scss';
import ChevronBottom from '../../icons-v2/ChevronBottom';
import moment from 'moment';
import Circle from '../../ui-v2/Circle/Circle';
import useQuery from '../../hooks/useQuery';
const StockItem = props => {
    const [isExpanded, setIsExpanded] = useState(false);
    const stock = props.stock;
    const stock_arr = props.stock_arr;
    const index_num = props.index_num;
    function convert_num_to_string(num) {
        if (num > 1000000000) {
            return (num / 1000000000).toFixed(2) + "B";
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(2) + "M";
        } else if (num > 1000) {
            return (num / 1000).toFixed(2) + "K";
        } else {
            return num;
        }
    }


    return (

        <>
            <tr onClick={() => { setIsExpanded(!isExpanded); }} className={`${styles.root} ${isExpanded ? styles.active : ''}`}>
                <td><ChevronBottom className={`text-accent-4 ${isExpanded ? 'rotate-180' : ''}`} /></td>
                <td> {index_num + 1}</td>
                <td><button className='badge' id='ticker' about={stock}>{stock}</button></td>
                <td>{stock_arr.length}</td>

            </tr>
            {isExpanded && (
                <tr className={`border-bottom ${styles.expandedRow}`}>
                    <td colSpan={8} className='p-0'>

                        <table className='primary-table'>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>
                                        <ToolTip title="Name of company that is similar">
                                            COMPANY NAME
                                        </ToolTip>
                                    </th>
                                    <th>
                                        <ToolTip title="Stock Symbol of similar">
                                            SYMBOL
                                        </ToolTip>
                                    </th>
                                    <th>
                                        <ToolTip title="Marketcap">
                                            MARKET CAP
                                        </ToolTip>
                                    </th>

                                    <th>
                                        <ToolTip title="How correlated the stock is to the stock you are comparing">
                                            Correlation
                                        </ToolTip>
                                    </th>

                                    <th>
                                        <ToolTip title="Upcoming earnings date of similar similar stocks.">
                                            Upcoming Earnings Date?
                                        </ToolTip>
                                        </th>


                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stock_arr.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <div className='description'>
                                                        <span>{item.company_name}</span>
                                                    </div>
                                                </td>
                                                <td><button className='badge' id='ticker' about={item.ticker}>{item.ticker}</button></td>
                                                <td>{convert_num_to_string(item.marketcap)}</td>
                                                <td>{item.correlation}%</td>
                                                <td>{item.earnings_date ? item.earnings_date : '-'}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            )}
        </>
    )
}




const NormalTable = ({ page, graph, no_result, stock_data, graphStock, stepNo, totalLength, comparable_stock_data }) => {
    function convert_num_to_string(num) {
        if (num > 1000000000) {
            return (num / 1000000000).toFixed(2) + "B";
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(2) + "M";
        } else if (num > 1000) {
            return (num / 1000).toFixed(2) + "K";
        } else {
            return num;
        }
    }
    return (
        <>
            <thead>
                <tr>
                    <th>No</th>
                    <th>
                        <ToolTip title="Name of company that is similar">
                            COMPANY NAME
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="Stock Symbol of similar">
                            SYMBOL
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="Marketcap">
                            MARKET CAP
                        </ToolTip>
                    </th>

                    <th>
                        <ToolTip title="How correlated the stock is to the stock you are comparing">
                            Correlation
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip title="Upcoming earnings date of similar similar stocks.">
                            Upcoming Earnings Date?
                        </ToolTip>
                    </th>


                </tr>
            </thead>
            <tbody>
                {comparable_stock_data.map((stock, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ width: '350px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {stock.company_name}
                        </td>
                        <td><button className='badge' id='ticker' about={stock.ticker}>{stock.ticker}</button></td>
                        <td>{convert_num_to_string(stock.marketcap)}</td>
                        <td>{stock.correlation}%</td>
                        <td>{stock.earnings_date ? stock.earnings_date : '-'}</td>
                    </tr>
                ))}

            </tbody>
        </>
    )
}

const ExpandableTableWrapper = ({ page, graph, no_result, stock_data, graphStock, stepNo, totalLength, comparable_stock_data }) => {
    console.log(comparable_stock_data)
    return (
        <>
            <thead>
                <tr>
                    <th></th>
                    <th><ToolTip title="The row number.">No</ToolTip></th>
                    <th><div><ToolTip title="The stock symbol.">Symbol</ToolTip></div></th>
                    <ToolTip title="Number of similar stocks.">
                        <th>Similar Stocks</th>
                    </ToolTip>
                </tr>
            </thead>
            <tbody>
                {
                    Object.keys(comparable_stock_data).length > 0 ?
                        Object.entries(comparable_stock_data).map(([key, value], index) => (
                            <StockItem stock={key} stock_arr={value} index_num={index} />
                        ))
                        :
                        <tr>
                            {
                                no_result &&
                                <td colSpan={20}>
                                    <div className='text-center m-auto'>No Results...</div>
                                </td>
                            }
                        </tr>
                }
            </tbody>
        </>
    )
}

const StockTable = props => {
    const number_of_stocks = Object.keys(props.comparable_stock_data).length;
    const firstStockKey = Object.keys(props.comparable_stock_data)[0];
    return (
        <>
            <div className='table-wrapper product_tool_table'>
                <div className={props.stepNo === 1 ? 'overflow-hidden' : ''}>
                    <table>
                        {number_of_stocks === 1 ? <NormalTable comparable_stock_data={props.comparable_stock_data[firstStockKey]} /> : <ExpandableTableWrapper {...props} />}
                    </table>
                </div>
            </div>
        </>
    )
}

export default StockTable;