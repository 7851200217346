import React from 'react';
import getNumber from '../../../../utility/getNumber';
import Card from "./Card";
import LI from './LI';


const Stats = props => (
    <Card title='Stats'>
        <ul>
            <LI title="Current price" tooltip={`The current price of the stock in the market. This is the amount you will pay for one share at this moment.`} value={props.current_price} start='$' />
        <LI title="52W High" tooltip={`The highest price this stock has reached in the past 52 weeks (1 year). It shows the peak value the stock achieved during this period.`} value={props.week52high} start='$' />
        <LI title="52W High difference" tooltip={`The percentage drop from the stock's highest price in the past year to its current price. A large percentage may indicate the stock is currently undervalued or facing challenges. Calculation: ((Current Price - 52 Week High) / 52 Week High) * 100.`} value={getNumber(props.diff_from_high, 3)} end='%' />
        <LI title="52W Low" tooltip={`The lowest price this stock has reached in the past 52 weeks (1 year). It shows the minimum value the stock achieved during this period.`} value={props.week52low} start='$' />
        <LI title="52W Low difference" tooltip={`The percentage increase from the stock's lowest price in the past year to its current price. A large percentage may indicate a recovery or growth in the stock. Calculation: ((Current Price - 52 Week Low) / 52 Week Low) * 100.`} value={getNumber(props.diff_from_low, 3)} end='%' />
        <LI title="52W High Low Difference" tooltip={`The percentage range between the stock's highest and lowest prices over the past year. A large range can indicate high volatility. Calculation: ((52 Week High - 52 Week Low) / 52 Week High) * 100.`} value={getNumber(props.high_low_52_week_diff_percent, 3)} end='%' />
        <LI title="52 Week Performance" tooltip={`The percentage change in the stock price over the last year. A positive percentage indicates growth, while a negative percentage shows a decline. Calculation: ((Current Price - Price 52 Weeks Ago) / Price 52 Weeks Ago) * 100.`} value={getNumber(props.week52performance*100, 3)} end='%' />
        <LI title="Avg 10 Day Volume" tooltip={`The average number of shares traded daily over the past 10 trading days. Higher volume can indicate strong investor interest or movement.`} value={getNumber(props.avg10dayvolume)} />
        <LI title="Avg 30 Day Volume" tooltip={`The average number of shares traded daily over the past 30 trading days. It provides a broader perspective on trading activity compared to the 10-day average.`} value={getNumber(props.avg30dayvolume)} />
        <LI title="Avg 90 Day Volume" tooltip={`The average number of shares traded daily over the past 90 trading days. It offers a long-term view of trading activity and liquidity.`} value={getNumber(props.avg90dayvolume)} />
        </ul>
        <ul>
        <LI title="Alpha" tooltip={`Alpha measures a stock's performance relative to a market index (like the S&P 500). A positive alpha indicates the stock has performed better than the index, while a negative alpha indicates underperformance. Alpha of 1.7% means the stock beat the market by 1.7%.`} value={props.alpha} />
        <LI title="Beta" tooltip={`Beta measures the stock's volatility compared to the market. A beta of 1 means the stock moves with the market. Less than 1 means less volatile, and greater than 1 means more volatile. For example, a beta of 1.2 means the stock is 20% more volatile than the market.`} value={getNumber(props.beta)} />
        <LI title="R2" tooltip={`R-squared indicates how much of the stock's movements can be explained by the market index. A value closer to 100% means the stock closely follows the index. Lower values suggest more independent movement.`} value={getNumber(props.r2)} />
        <LI title="Standard Deviation" tooltip={`Standard deviation measures how much the stock's price deviates from its average price. A higher standard deviation means higher volatility, indicating a wider range of price changes.`} value={getNumber(props.stddev)} />
        <LI title="Periods" tooltip={`The number of days used to calculate metrics like alpha, beta, R-squared, and standard deviation. Longer periods may provide more stability, while shorter periods offer more current data.`} value={props.periods} />
        <LI title="21 Day Moving Avg" tooltip={`The average stock price over the past 21 days. Used to identify short-term trends. A rising SMA suggests upward momentum, while a falling SMA indicates downward momentum.`} value={props.day21movingavg} start='$' />
        <LI title="50 Day Moving Avg" tooltip={`The average stock price over the past 50 days. A key technical indicator used to assess medium-term trends. Many investors see a price crossing above this line as a bullish sign.`} value={props.day50movingavg} start='$' />
        <LI title="200 Day Moving Avg" tooltip={`The average stock price over the past 200 days. A significant indicator of long-term trends. If the price is above the 200-day SMA, it is generally seen as a positive long-term trend.`} value={props.day200movingavg} start='$' />
        <LI title="21 Day EMA" tooltip={`The 21-day EMA places more weight on recent prices, making it more responsive to new information. Used to identify short-term trends and potential price reversals.`} value={props.day21ema} />
        <LI title="50 Day EMA" tooltip={`The 50-day EMA is similar to the SMA but reacts more quickly to price changes. It helps in identifying medium-term trends and momentum shifts.`} value={props.day50ema} />
        <LI title="200 Day EMA" tooltip={`The 200-day EMA smooths out long-term price trends and provides a clearer view of a stock's overall direction. Prices above this line often suggest strength and stability.`} value={props.day200ema} />
        </ul>
    </Card>
);

export default Stats;


const Old_Stats = props => (
    <Card title='Stats'>
        <ul>
            <LI title="Current price" tooltip='Current price' value={props.current_price} start='$' />
            <LI title="52W High" tooltip={`The stocks highest price over the last year.`} value={props.week52high} start='$' />
            <LI title="52W High difference" tooltip={`The percentage difference between the 52 week high price and the current price. ((quote_price - week_52_high) / week_52_high) * 100`} value={getNumber(props.diff_from_high, 3)} end='%' />
            <LI title="52W Low" tooltip={`The stocks lowest price over the last year.`} value={props.week52low} start='$' />
            <LI title="52W Low difference" tooltip={`The percentage difference between the 52 week low price and the current price. ((quote_price - week_52_low) / week_52_low) * 100`} value={getNumber(props.diff_from_low, 3)} end='%' />
            <LI title="52W High Low Difference" tooltip={`The difference between the 52W High & Low as a percentage compared to the 52W High. ((high_low_52_week_diff * 100) / week_52_high) * 100`} value={getNumber(props.high_low_52_week_diff_percent, 3)} end='%' />
            <LI title="52 Week Performance" tooltip={`The stock price change over the last year as %.`} value={getNumber(props.week52performance*100, 3)} end='%' />
            <LI title="Avg 10 Day Volume" tooltip={`The average number of shares that are traded on a daily basis over the last 2 weeks of trading.`} value={props.avg10dayvolume} />
            <LI title="Avg 30 Day Volume" tooltip={`The average number of shares that are traded on a daily basis over the last 30 days of trading.`} value={props.avg30dayvolume} />
            <LI title="Avg 90 Day Volume" tooltip={`The average number of shares that are traded on a daily basis over the last 90 days of trading.`} value={props.avg90dayvolume} />
        </ul>
        <ul>
            <LI title="Alpha" tooltip={`a term used in investing to describe an investment strategy's ability to beat the market, or its "edge." Alpha is thus also often referred to as "excess return" or "abnormal rate of return," which refers to the idea that markets are efficient, and so there is no way to systematically earn returns that exceed the broad market as a whole. Ex As of Feb. 28, 2022, DGRW annualized return was 18.1%, which was also higher than the S&P 500 at 16.4%, so it had an alpha of 1.7% `} value={props.alpha} />
            <LI title="Beta" tooltip={`is a measure of the volatility—or systematic risk—of a security or portfolio compared to the market as a whole. If beta =1, strongly correlated to market. If beta<1, then less volatile than market. If beta > 1, more volatile than market.`} value={getNumber(props.beta)} />
            <LI title="R2" tooltip={`In investing, R-squared is generally interpreted as the percentage of a fund or security's movements that can be explained by movements in a benchmark index. R-Squared is a statistical measure of fit that indicates how much variation of a dependent variable is explained by the independent variable(s) in a regression model.`} value={getNumber(props.r2)} />
            <LI title="Standard Deviation" tooltip={`is the statistical measure of market volatility, measuring how widely prices are dispersed from the average price. If prices trade in a narrow trading range, the standard deviation will return a low value that indicates low volatility`} value={getNumber(props.stddev)} />
            <LI title="Periods" tooltip={`Number of days used to calculate alpha, beta, r2, stddev`} value={props.periods} />
            <LI title="21 Day Moving Avg" tooltip={`21 Day Simple Moving Average is a technical indicator (price taken from end of day values)`} value={props.day21movingavg} start='$' />
            <LI title="50 Day Moving Avg" tooltip={`50 Day Simple Moving Average is a technical indicator (price taken from end of day values)`} value={props.day50movingavg} start='$' />
            <LI title="200 Day Moving Avg" tooltip={`200 Day Simple Moving Average is a technical indicator (price taken from end of day values)`} value={props.day200movingavg} start='$' />
            <LI title="21 Day EMA" tooltip={`21 Day Exponential  Moving Average is a technical indicator (price taken from end of day values)`} value={props.day21ema} />
            <LI title="50 Day EMA" tooltip={`50 Day Exponential  Moving Average is a technical indicator (price taken from end of day values)`} value={props.day50ema} />
            <LI title="200 Day EMA" tooltip={`200 Day Exponential  Moving Average is a technical indicator (price taken from end of day values)`} value={props.day200ema} />
        </ul>
    </Card>
)

