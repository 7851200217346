import React, { useContext, useMemo } from "react";
import Model from "../../ui-v2/Modal/Model";
import BoxField from '../../ui-v2/BoxField/BoxField';

const handleComputeTokens = (data) => {
    // Convert object to JSON string
    const jsonString = JSON.stringify(data);
    
    // Rough estimation: 1 token ≈ 4 characters
    // This is a common approximation for GPT models
    const estimatedTokens = Math.ceil(jsonString.length / 4);
    
    return estimatedTokens;
};

const Main = ({ chat, toggle_gpt }) => {
    return (
        <>
            {chat.map((item, index) => (
                item.algo_name && (
                    <div className="border-top flex-between py-2" key={index}>
                        {item.algo_name} ({handleComputeTokens(item.message)} tokens)
                        <BoxField
                            name="send_to_gpt"
                            type="checkbox"
                            checked={item.send_to_gpt}
                            onChange={() => toggle_gpt(index)}
                        />
                    </div>
                )
            ))}
        </>
    );
};

export default function DataSets(props) {
    const { chat, toggle_gpt, component, modelContextLength = 4000 } = props;
    
    // Calculate total tokens for enabled datasets
    const totalTokenCount = useMemo(() => {
        return chat
            .filter(item => item.send_to_gpt && item.algo_name)
            .reduce((sum, item) => sum + handleComputeTokens(item.message), 0);
    }, [chat]);
    
    const isExceeded = totalTokenCount > modelContextLength;
    const tokenStyle = {
        color: isExceeded ? 'red' : 'green',
        fontWeight: 'bold'
    };
    
    const titleComponent = (
        <div className="d-flex align-items-center">
            Datasets Used - <span style={tokenStyle} className="ml-1">{totalTokenCount}/{modelContextLength} tokens</span>
        </div>
    );
    
    return (
        <Model title={titleComponent} component={component}>
            <div>
                {isExceeded && (
                    <div className="mb-2 mt-0 text-danger" style={{ 
                        fontSize: '0.65rem', 
                        fontWeight: 'bold', 
                        fontStyle: 'italic' 
                    }}>
                        Warning: Token count > maximum context length. Some data will be auto filtered.
                    </div>
                )}
                <Main chat={chat} toggle_gpt={toggle_gpt} />
            </div>
        </Model>
    );
}