import React from "react";
import Input from "./input";

function valid(e) {
    const elm = e.target, value = elm.value, valid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
    valid ? elm.classList.remove("invalid") : elm.classList.add("invalid");  
}

export default function Email({ onChange, ...props }) {
    return (
        <Input
            required={true}
            onChange={e => {
                valid(e)
                onChange && onChange(e);
            }}
            onBlur={valid}
            errorText="Please provide a valid email address!"
            {...props}
            label="Email"
        />
    )
}