import React, { useEffect } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import Item from "./Item";
import axios from 'axios';

export default function Algos(props) {
    const [items, setItems] = React.useState(null);
    const onSortEnd = (oldIndex, newIndex) => {
        const newOrder = arrayMoveImmutable(items, oldIndex, newIndex),
        order = newOrder.map(item => item.algoId._id)
        axios.put('/algo-sort', { order })
        setItems(newOrder);
    };

    useEffect(() => {
        setItems(props.algos)
    }, [props.algos])
    return (
        <div className="d-grid gap-3 m-3 col-xl-4 overflow- col-md-3 col-sm-2 no-select">
            {props.algos.map(algo => <Item {...algo} key={algo._id} />)}
        </div>
    )
}