import React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import axios from "axios";

export default function AlgosSort({ className, algos, Item, actions }) {
    const [items, setItems] = React.useState([...algos]);
    const onSortEnd = (oldIndex, newIndex) => {
        const newOrder = arrayMoveImmutable(items, oldIndex, newIndex);
        axios.put('/algo-sort', { order: newOrder.map(item => item.algoId._id) })
        setItems(newOrder);
    };

    return (
        <SortableList
            onSortEnd={onSortEnd}
            className={`no-select ${className}`}
        >
            {items.map((item) => (
                <SortableItem key={item._id}>
                    <div style={{ zIndex: 10002 }} className="d-flex no-select flex-column">
                        <Item {...item} />
                    </div>
                </SortableItem>
            ))}
            {actions && actions(items)}
        </SortableList>
    );
}