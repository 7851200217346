import React, { useEffect, useRef, memo, useState, useContext } from 'react';
import Model from '../ui-v2/Modal/Model';
import moment from 'moment';
import axios from 'axios';
import BoxField from '../ui-v2/Tag/Tag';
import ToolTip from '../ui-v2/ToolTip/ToolTip'
import { AppContext } from '../App';

const studiesInfo = [
  { name: "24-hour Volume", description: "The 24-hour Volume indicator displays the total volume traded in the last 24 hours. Traders should look for spikes in volume to gauge market activity and potential price movements. It's valuable for assessing the strength of a price move and determining the liquidity of a security, aiding in making informed trading decisions.", value: "STD;24h%Volume" },
  { name: "Accumulation/Distribution", description: "The Accumulation/Distribution indicator combines price and volume data to show the accumulation or distribution of a security. Rising values indicate buying pressure (accumulation), while falling values indicate selling pressure (distribution). Traders can use this indicator to confirm trends and spot divergences between price movements and volume flow, offering insights into market sentiment.", value: "STD;Accumulation_Distribution" },
  { name: "Advance Decline Line", description: "The Advance Decline Line measures the difference between the number of advancing and declining stocks. A rising line indicates a strong market, while a falling line suggests market weakness. This indicator is useful for gauging overall market breadth, providing a clearer picture of market health beyond major indices.", value: "STD;Advance%1Decline%1Line" },
  { name: "Advance Decline Ratio", description: "The Advance Decline Ratio shows the ratio of advancing stocks to declining stocks. Ratios above 1 indicate more advancing stocks, whereas ratios below 1 indicate more declining stocks. Traders use this to confirm market trends and gain insights into market strength, helping to predict potential trend reversals.", value: "STD;Advance%1Decline%1Ratio" },
  { name: "Advance/Decline Ratio (Bars)", description: "The Advance/Decline Ratio (Bars) indicator displays the advance/decline ratio using bars, making it easier to visualize market breadth. Traders should look for patterns and spikes in the bars to identify shifts in market sentiment. This visualization enhances the interpretation of market trends.", value: "STD;Advance_Decline_Ratio_Bars" },
  { name: "Arnaud Legoux Moving Average", description: "The Arnaud Legoux Moving Average is a more responsive moving average designed to reduce lag. Traders should look for crossovers and divergences with price action. This indicator helps in identifying trend changes more quickly than traditional moving averages, providing timely trading signals.", value: "STD;Arnaud%1Legoux%1Moving%1Average" },
  { name: "Aroon", description: "The Aroon indicator identifies the beginning of a new trend. High values in the Aroon Up indicate a strong uptrend, while high values in the Aroon Down indicate a strong downtrend. Traders use this to spot emerging trends and potential reversals, providing early signals of trend changes.", value: "STD;Aroon" },
  { name: "Average Day Range", description: "The Average Day Range indicator shows the average price range of a security over a specific period. Higher values indicate more volatility. Traders use this to set realistic stop-loss levels and profit targets, aiding in risk management and trade planning.", value: "STD;Average%Day%Range" },
  { name: "Average Directional Index", description: "The Average Directional Index (ADX) measures the strength of a trend. Values above 25 typically indicate a strong trend. Traders use this indicator to confirm the strength of existing trends, helping to avoid range-bound markets and focus on strong trends.", value: "STD;Average%1Directional%1Index" },
  { name: "Average True Range", description: "The Average True Range (ATR) shows the volatility of a security. Higher values indicate higher volatility. Traders use ATR to adjust position sizes and set stop-loss levels, enhancing their risk management strategies.", value: "STD;Average_True_Range" },
  { name: "Awesome Oscillator", description: "The Awesome Oscillator determines market momentum by showing the difference between a 34-period and a 5-period simple moving average. Traders look for crosses above or below the zero line to identify bullish or bearish momentum, providing momentum signals to support trading decisions.", value: "STD;Awesome_Oscillator" },
  { name: "Balance of Power", description: "The Balance of Power (BOP) indicator measures the strength of buyers versus sellers. Positive values indicate buyer strength, while negative values indicate seller strength. Traders use this to gauge market sentiment and confirm trends, helping in understanding the underlying power dynamics in the market.", value: "STD;Balance%1of%1Power" },
  { name: "BBTrend", description: "The BBTrend indicator combines Bollinger Bands and trend analysis. Traders should look for interactions with Bollinger Bands to identify trends and potential reversals. This indicator enhances the interpretation of Bollinger Bands in trend analysis, providing more comprehensive trading signals.", value: "STD;BBTrend" },
  { name: "Bollinger Bands", description: "Bollinger Bands show volatility and relative price levels by plotting a moving average with upper and lower bands. Traders look for price touching or crossing the bands to identify overbought or oversold conditions. This indicator provides signals for potential entry or exit points based on volatility.", value: "STD;Bollinger_Bands" },
  { name: "Bollinger Bands %b", description: "The Bollinger Bands %b indicator shows where the price is relative to the Bollinger Bands. Values near 1 indicate the price is near the upper band, while values near 0 indicate the price is near the lower band. Traders use this to gauge the strength of price moves within the bands, offering a clearer view of price position.", value: "STD;Bollinger_Bands_B" },
  { name: "Bollinger BandWidth", description: "Bollinger BandWidth measures the width of the Bollinger Bands. Increasing width indicates higher volatility. Traders use this to anticipate changes in volatility, helping to adjust trading strategies based on volatility expectations.", value: "STD;Bollinger_Bands_Width" },
  { name: "Bull Bear Power", description: "The Bull Bear Power indicator measures the power of bulls versus bears in the market. Positive values indicate bullish power, while negative values indicate bearish power. Traders use this to confirm price trends and strength, providing insight into market sentiment.", value: "STD;Bull%Bear%Power" },
  { name: "Chaikin Money Flow", description: "Chaikin Money Flow combines price and volume to measure buying and selling pressure. Positive values indicate buying pressure, while negative values indicate selling pressure. Traders use this indicator to confirm trend direction and strength, understanding the flow of money in and out of a security.", value: "STD;Chaikin_Money_Flow" },
  { name: "Chaikin Oscillator", description: "The Chaikin Oscillator measures the momentum of the accumulation/distribution line. Traders look for crosses above or below the zero line to spot changes in momentum. This indicator provides signals for potential trend reversals, enhancing momentum analysis.", value: "STD;Chaikin_Oscillator" },
  { name: "Chande Kroll Stop", description: "The Chande Kroll Stop is a trend-following indicator that sets trailing stop-losses. Traders use the levels provided to set stop-loss orders, protecting profits and limiting losses. This indicator enhances risk management and trade protection strategies.", value: "STD;Chande%1Kroll%1Stop" },
  { name: "Chande Momentum Oscillator", description: "The Chande Momentum Oscillator measures momentum in a security. Traders look for crosses above or below the zero line to identify overbought or oversold conditions. This momentum-based indicator provides signals to refine entry and exit points.", value: "STD;Chande_Momentum_Oscillator" },
  { name: "Chop Zone", description: "The Chop Zone indicator indicates whether the market is trending or choppy. High values suggest choppiness, while low values suggest trending markets. Traders use this to adjust their strategies accordingly, helping to avoid false signals in choppy markets.", value: "STD;Chop%1Zone" },
  { name: "Choppiness Index", description: "The Choppiness Index measures market choppiness. High values indicate a choppy market, while low values indicate a trending market. Traders use this to gauge market conditions, planning trades more effectively based on the market behavior.", value: "STD;Choppiness_Index" },
  { name: "Commodity Channel Index", description: "The Commodity Channel Index (CCI) identifies cyclical trends in commodities. Values above +100 indicate overbought conditions, while values below -100 indicate oversold conditions. Traders use this to spot overbought or oversold conditions, identifying potential reversal points in commodity prices.", value: "STD;CCI" },
  { name: "Connors RSI", description: "Connors RSI is a composite momentum oscillator combining several indicators. Traders look for overbought and oversold levels to refine entry and exit points. This indicator enhances decision-making with a more refined momentum analysis.", value: "STD;Connors_RSI" },
  { name: "Coppock Curve", description: "The Coppock Curve identifies long-term buying opportunities. Traders look for positive reversals as buy signals. This indicator is valuable for long-term investment decisions, providing signals for market entries over extended periods.", value: "STD;Coppock%1Curve" },
  { name: "Correlation Coefficient", description: "The Correlation Coefficient measures the correlation between two securities. Values close to +1 or -1 indicate strong correlation. Traders use this to assess diversification and risk, helping in portfolio management and strategic decision-making.", value: "CorrelationCoefficient@tv-basicstudies" },
  { name: "Cumulative Volume Delta", description: "Cumulative Volume Delta shows the difference between buying and selling volume. Positive values indicate more buying, while negative values indicate more selling. Traders use this to understand the volume dynamics and market sentiment, aiding in more informed trading decisions.", value: "STD;Cumulative%1Volume%1Delta" },
  { name: "Cumulative Volume Index", description: "The Cumulative Volume Index (CVI) tracks the cumulative volume of a security. Rising values indicate increasing buying pressure, while falling values indicate selling pressure. Traders use this to gauge long-term volume trends and underlying market strength.", value: "STD;Cumulative%1Volume%1Index" },
  { name: "Detrended Price Oscillator", description: "The Detrended Price Oscillator (DPO) removes the trend from price to show cycles more clearly. Traders look for cyclical patterns to identify potential turning points. This indicator is useful for short-term trading strategies focused on market cycles.", value: "STD;DPO" },
  { name: "Directional Movement Index", description: "The Directional Movement Index (DMI) measures the strength and direction of a trend. Traders look for crossovers between the +DI and -DI lines to confirm trends. This indicator helps in identifying and confirming trend directions, enhancing trend-following strategies.", value: "STD;DMI" },
  { name: "Donchian Channels", description: "Donchian Channels show the highest high and lowest low over a specific period. Traders look for price breaks above or below the channels to identify trend breakouts. This indicator is valuable for breakout trading strategies, providing clear entry and exit signals.", value: "STD;Donchian_Channels" },
  { name: "Double EMA", description: "The Double Exponential Moving Average (DEMA) applies an EMA to another EMA, making it more responsive to price changes. Traders look for crossovers with price to identify trend changes. This indicator helps in making timely trading decisions by reducing lag.", value: "STD;DEMA" },
  { name: "Ease of Movement", description: "The Ease of Movement (EOM) indicator shows the relationship between price and volume. Traders look for positive values to indicate easy upward movement and negative values for downward movement. This indicator helps in assessing the ease with which price moves in a particular direction.", value: "STD;EOM" },
  { name: "Elder Force Index", description: "The Elder Force Index measures the power behind a price movement by combining price change and volume. Traders look for significant changes in the index to gauge the strength of price movements. This indicator is useful for identifying potential reversals and confirming trends.", value: "STD;EFI" },
  { name: "Envelope", description: "Price Envelopes show price envelopes over a moving average, indicating overbought and oversold levels. Traders look for price touching the upper or lower envelope to identify potential reversal points. This indicator helps in setting target levels for trades.", value: "STD;ENV" },
  { name: "Fisher Transform", description: "The Fisher Transform indicator identifies price reversals by transforming price data into a Gaussian normal distribution. Traders look for extreme values to identify potential reversal points. This indicator provides clear signals for entry and exit points.", value: "STD;Fisher_Transform" },
  { name: "Gaps", description: "The Gaps indicator identifies gaps in price, which occur when there is a significant difference between the closing price of one period and the opening price of the next. Traders use this to spot potential trading opportunities, as gaps often indicate strong momentum.", value: "STD;Gaps" },
  { name: "Historical Volatility", description: "Historical Volatility shows the past volatility of a security. Traders look for changes in volatility to anticipate potential price movements. This indicator helps in adjusting trading strategies based on expected market volatility.", value: "STD;Historical_Volatility" },
  { name: "Hull Moving Average", description: "The Hull Moving Average (HMA) is a moving average that reduces lag while improving smoothness. Traders look for crossovers with price to identify trend changes. This indicator helps in making more responsive trading decisions.", value: "STD;Hull%1MA" },
  { name: "Ichimoku Cloud", description: "The Ichimoku Cloud shows support and resistance levels, trend direction, and momentum all in one indicator. Traders look for the position of price relative to the cloud and the crossing of the Tenkan-sen and Kijun-sen lines. This comprehensive indicator aids in identifying trends, potential reversals, and support/resistance levels.", value: "STD;Ichimoku%1Cloud" },
  { name: "Keltner Channels", description: "Keltner Channels show volatility with bands set around an exponential moving average (EMA). Traders look for price breaking the upper or lower bands to identify potential breakout or reversal points. This indicator helps in setting entry and exit levels based on volatility.", value: "STD;Keltner_Channels" },
  { name: "Klinger Oscillator", description: "The Klinger Oscillator measures long-term money flow trends by combining price and volume data. Traders look for crossovers with a signal line to identify trend changes. This indicator provides insights into long-term market sentiment and potential reversals.", value: "STD;Klinger%1Oscillator" },
  { name: "Know Sure Thing", description: "The Know Sure Thing (KST) is a momentum oscillator that combines several rate-of-change calculations. Traders look for crossovers with a signal line to identify bullish or bearish momentum. This indicator helps in spotting momentum shifts and potential entry/exit points.", value: "STD;Know_Sure_Thing" },
  { name: "Least Squares Moving Average", description: "The Least Squares Moving Average (LSMA) is a linear regression indicator that plots the line of best fit. Traders look for crossovers with price to identify trend changes. This indicator provides a smooth trend line, helping in identifying the overall direction of the market.", value: "STD;Least%1Squares%1Moving%1Average" },
  { name: "Linear Regression Channel", description: "The Linear Regression Channel shows a regression line with parallel lines above and below, representing standard deviations. Traders look for price touching or breaking the channel boundaries to identify potential reversals or continuations. This indicator helps in assessing the trend and potential price targets.", value: "STD;Linear_Regression" },
  { name: "MA Cross", description: "The MA Cross indicator shows crossovers of moving averages, indicating potential trend changes. Traders look for the faster moving average crossing above or below the slower moving average to identify buy or sell signals. This indicator is valuable for confirming trend changes and timing trades.", value: "STD;MA%1Cross" },
  { name: "Mass Index", description: "The Mass Index identifies potential reversals by measuring the range between high and low prices. Traders look for values above 27 to signal a potential reversal. This indicator helps in anticipating trend changes based on price range expansions.", value: "STD;Mass%1Index" },
  { name: "McGinley Dynamic", description: "The McGinley Dynamic is a smoothing mechanism for moving averages that adapts to market speed. Traders look for crossovers with price to identify trend changes. This indicator helps in reducing lag and providing a more accurate trend representation.", value: "STD;McGinley%1Dynamic" },
  { name: "Median", description: "The Median indicator shows the median price of a security. Traders use this to identify central tendency and potential support/resistance levels. This indicator provides a straightforward way to assess price behavior around a central value.", value: "STD;Median" },
  { name: "Momentum", description: "The Momentum indicator measures the velocity of price movements. Traders look for divergences between the indicator and price to identify potential reversals. This indicator helps in assessing the strength of price trends and timing trades based on momentum shifts.", value: "STD;Momentum" },
  { name: "Money Flow Index", description: "The Money Flow Index (MFI) combines price and volume data to identify overbought or oversold conditions. Values above 80 indicate overbought conditions, while values below 20 indicate oversold conditions. Traders use this to spot potential reversal points based on volume-weighted momentum.", value: "STD;Money_Flow" },
  { name: "Moon Phases", description: "The Moon Phases indicator shows the phases of the moon, which some traders believe can influence market behavior. Traders use this to identify potential market cycles and turning points. This indicator is more esoteric and used primarily by those interested in market astrology.", value: "MoonPhases@tv-basicstudies" },
  { name: "Moving Average Convergence Divergence", description: "The Moving Average Convergence Divergence (MACD) shows the relationship between two moving averages of different lengths. Traders look for crossovers of the MACD line and signal line to identify buy or sell signals, as well as divergences between the MACD and price. This indicator is widely used for momentum and trend-following strategies.", value: "STD;MACD" },
  { name: "Moving Average Exponential", description: "The Exponential Moving Average (EMA) is a type of moving average that places greater weight on recent prices. Traders look for crossovers with price to identify trend changes. This indicator helps in making more responsive trading decisions by reducing lag compared to simple moving averages.", value: "STD;EMA" },
  { name: "Moving Average Ribbon", description: "The Moving Average Ribbon consists of multiple moving averages of different lengths plotted on a chart. Traders look for the spread and convergence of the ribbons to identify trend strength and potential reversals. This indicator provides a visual representation of trend strength and potential changes.", value: "STD;MA%Ribbon" },
  { name: "Moving Average Simple", description: "The Simple Moving Average (SMA) is a basic moving average that calculates the average price over a specified period. Traders look for crossovers with price to identify trend changes. This indicator helps in smoothing price data and identifying the overall direction of the market.", value: "STD;SMA" },
  { name: "Moving Average Weighted", description: "The Weighted Moving Average (WMA) gives more weight to recent prices in its calculation. Traders look for crossovers with price to identify trend changes. This indicator helps in making more responsive trading decisions by giving greater importance to recent price movements.", value: "STD;WMA" },
  { name: "Multi-Time Period Charts", description: "Multi-Time Period Charts display multiple time periods on a single chart. Traders use this to analyze trends across different timeframes simultaneously. This indicator helps in gaining a comprehensive view of market trends and making more informed trading decisions.", value: "STD;Multi-Time%Period%Charts" },
  { name: "Net Volume", description: "Net Volume shows the difference between up volume (buying) and down volume (selling). Positive values indicate buying pressure, while negative values indicate selling pressure. Traders use this to gauge market sentiment and the balance of power between buyers and sellers.", value: "STD;Net%1Volume" },
  { name: "On Balance Volume", description: "The On Balance Volume (OBV) indicator uses volume flow to predict changes in price. Rising OBV indicates buying pressure, while falling OBV indicates selling pressure. Traders use this to confirm price trends and spot divergences between price and volume.", value: "STD;On_Balance_Volume" },
  { name: "Open Interest", description: "Open Interest shows the number of open contracts in the market. Rising open interest indicates new positions are being opened, while falling open interest indicates positions are being closed. Traders use this to gauge market participation and potential future price movements.", value: "STD;Open%Interest" },
  { name: "Parabolic SAR", description: "The Parabolic SAR (Stop and Reverse) indicator shows potential reversals in price by plotting dots above or below the price. Traders look for price crossing the dots to signal a trend reversal. This indicator helps in setting trailing stop-losses and identifying trend changes.", value: "STD;PSAR" },
  { name: "Pivot Points High Low", description: "Pivot Points High Low shows support and resistance levels based on previous price data. Traders use these levels to identify potential reversal points and set target prices. This indicator helps in planning trades and setting stop-loss levels.", value: "STD;Pivot%1Points%1High%1Low" },
  { name: "Pivot Points Standard", description: "Pivot Points Standard shows standard pivot points based on previous price data. Traders use these levels to identify potential support and resistance levels and plan trades accordingly. This indicator helps in setting entry and exit points based on historical price behavior.", value: "STD;Pivot%1Points%1Standard" },
  { name: "Price Oscillator", description: "The Price Oscillator shows the difference between two moving averages of different lengths. Traders look for crossovers with the zero line to identify bullish or bearish momentum. This indicator helps in confirming trend direction and spotting potential reversals.", value: "STD;Price_Oscillator" },
  { name: "Price Target", description: "The Price Target indicator shows projected price targets based on various technical analysis techniques. Traders use this to set target prices for trades and plan exit strategies. This indicator helps in setting realistic price expectations and managing trades.", value: "STD;Price%1Target" },
  { name: "Price Volume Trend", description: "The Price Volume Trend (PVT) combines price and volume data to show the strength of price trends. Traders look for divergences between PVT and price to identify potential reversals. This indicator helps in confirming price trends and understanding the relationship between price and volume.", value: "PriceVolumeTrend@tv-basicstudies" },
  { name: "Rate Of Change", description: "The Rate of Change (ROC) measures the percentage change in price over a specified period. Traders look for high positive or negative values to identify strong price movements. This indicator helps in assessing the momentum of price movements and spotting potential reversals.", value: "STD;ROC" },
  { name: "Relative Strength Index", description: "The Relative Strength Index (RSI) measures the speed and change of price movements. Values above 70 indicate overbought conditions, while values below 30 indicate oversold conditions. Traders use this to identify potential reversal points based on momentum.", value: "STD;RSI" },
  { name: "Relative Vigor Index", description: "The Relative Vigor Index (RVI) measures the strength of a trend by comparing the closing price to the trading range. Traders look for crossovers with a signal line to identify trend changes. This indicator helps in confirming trend direction and spotting potential reversals.", value: "STD;Relative_Vigor_Index" },
  { name: "Relative Volatility Index", description: "The Relative Volatility Index (RVI) measures the direction of volatility. Values above 50 indicate increasing volatility, while values below 50 indicate decreasing volatility. Traders use this to gauge market conditions and adjust trading strategies based on volatility expectations.", value: "STD;Relative_Volatility_Index" },
  { name: "Relative Volume at Time", description: "Relative Volume at Time shows the volume of trades relative to the typical volume for a specific time period. Traders use this to identify unusual trading activity and potential market moves. This indicator helps in spotting periods of high activity and potential trading opportunities.", value: "STD;Relative%1Volume%1at%1Time" },
  { name: "Rob Booker - Intraday Pivot Points", description: "Rob Booker - Intraday Pivot Points shows pivot points for intraday trading. Traders use these levels to identify potential support and resistance levels and plan intraday trades. This indicator helps in setting entry and exit points for short-term trades.", value: "BookerIntradayPivots@tv-basicstudies" },
  { name: "Rob Booker - Knoxville Divergence", description: "Rob Booker - Knoxville Divergence identifies divergences between price and technical indicators. Traders use this to spot potential reversals and plan trades accordingly. This indicator helps in identifying divergence-based trading opportunities.", value: "BookerKnoxvilleDivergence@tv-basicstudies" },
  { name: "Rob Booker - Missed Pivot Points", description: "Rob Booker - Missed Pivot Points shows pivot points that were missed by the price in previous sessions. Traders use this to identify potential levels of interest for future price movements. This indicator helps in setting target levels based on missed pivots.", value: "BookerMissedPivots@tv-basicstudies" },
  { name: "Rob Booker - Reversal", description: "Rob Booker - Reversal identifies potential reversals in price based on specific patterns and indicators. Traders use this to spot trend changes and plan trades accordingly. This indicator helps in identifying reversal-based trading opportunities.", value: "BookerReversal@tv-basicstudies" },
  { name: "Rob Booker - Ziv Ghost Pivots", description: "Rob Booker - Ziv Ghost Pivots shows ghost pivot points, which are theoretical support and resistance levels. Traders use this to identify potential turning points in the market. This indicator helps in setting target levels based on ghost pivots.", value: "STD;Rob%1Booker%1Ghost%1Pivots%1v2" },
  { name: "RSI Divergence Indicator", description: "The RSI Divergence Indicator shows divergences between the RSI and price. Traders look for divergences to identify potential reversals. This indicator helps in spotting divergence-based trading opportunities and refining entry and exit points.", value: "STD;Divergence%1Indicator" },
  { name: "Seasonality", description: "The Seasonality indicator shows seasonal trends in the market. Traders use this to identify recurring patterns and plan trades based on seasonal tendencies. This indicator helps in understanding long-term market cycles and timing trades.", value: "STD;Seasonality" },
  { name: "SMI Ergodic Indicator", description: "The SMI Ergodic Indicator is a momentum indicator that helps in identifying overbought or oversold conditions. Traders look for crossovers with a signal line to spot potential reversals. This indicator provides momentum-based trading signals and helps in refining entry and exit points.", value: "STD;SMI_Ergodic_Indicator_Oscillator" },
  { name: "SMI Ergodic Oscillator", description: "The SMI Ergodic Oscillator is an oscillator version of the SMI Ergodic Indicator, providing clearer signals. Traders look for crossovers with a signal line to identify trend changes. This indicator helps in spotting momentum shifts and potential reversals.", value: "STD;SMI_Ergodic_Oscillator" },
  { name: "Smoothed Moving Average", description: "The Smoothed Moving Average (SMMA) is a smoothed version of a moving average, reducing noise and highlighting the overall trend. Traders look for crossovers with price to identify trend changes. This indicator helps in providing a clearer view of the market trend.", value: "STD;Smoothed%1Moving%1Average" },
  { name: "Stochastic", description: "The Stochastic Oscillator shows the position of the current price relative to the high and low range over a specified period. Values above 80 indicate overbought conditions, while values below 20 indicate oversold conditions. Traders use this to identify potential reversal points and time trades.", value: "STD;Stochastic" },
  { name: "Stochastic Momentum Index", description: "The Stochastic Momentum Index (SMI) is a refined version of the stochastic oscillator, providing smoother signals. Traders look for crossovers with a signal line to identify overbought or oversold conditions. This indicator helps in refining entry and exit points based on momentum.", value: "STD;SMI" },
  { name: "Stochastic RSI", description: "The Stochastic RSI applies the stochastic oscillator to the Relative Strength Index (RSI), providing a more sensitive indicator. Traders look for values above 80 to indicate overbought conditions and below 20 for oversold conditions. This indicator helps in identifying potential reversal points with greater accuracy.", value: "STD;Stochastic_RSI" },
  { name: "Supertrend", description: "The Supertrend indicator shows the trend direction by plotting a line above or below the price. Traders look for price crossing the Supertrend line to identify potential trend changes. This indicator helps in setting trailing stop-losses and identifying trend direction.", value: "STD;Supertrend" },
  { name: "Technical Ratings", description: "Technical Ratings aggregate multiple technical indicators into a single rating. Traders use this to get a quick overview of the technical outlook of a security. This indicator helps in making informed trading decisions based on a composite view of technical signals.", value: "STD;Technical%1Ratings" },
  { name: "Time Weighted Average Price", description: "The Time Weighted Average Price (TWAP) shows the average price weighted by time over a specific period. Traders use this to assess the average price over time and identify potential price targets. This indicator helps in planning trades and setting entry/exit points based on time-weighted averages.", value: "STD;Time%1Weighted%1Average%1Price" },
  { name: "Trading Sessions", description: "The Trading Sessions indicator shows different trading sessions on a chart. Traders use this to identify market behavior during specific sessions and plan trades accordingly. This indicator helps in understanding market activity and volatility patterns during different trading sessions.", value: "STD;Trading%1Sessions" },
  { name: "Trend Strength Index", description: "The Trend Strength Index (TSI) measures the strength of a trend by comparing price changes. Traders look for crossovers with a signal line to identify trend changes. This indicator helps in confirming trend direction and spotting potential reversals.", value: "STD;Trend%1Strength%1Index" },
  { name: "Triple EMA", description: "The Triple Exponential Moving Average (TEMA) applies an EMA to an EMA to an EMA, making it very responsive to price changes. Traders look for crossovers with price to identify trend changes. This indicator helps in making more timely trading decisions by reducing lag significantly.", value: "STD;TEMA" },
  { name: "TRIX", description: "The TRIX indicator is a triple exponential moving average oscillator that shows the rate of change of a triple-smoothed moving average. Traders look for crossovers with a signal line to identify bullish or bearish momentum. This indicator helps in spotting momentum shifts and potential trend reversals.", value: "STD;TRIX" },
  { name: "True Strength Index", description: "The True Strength Index (TSI) is a momentum oscillator that shows the rate of price change. Traders look for crossovers with a signal line to identify bullish or bearish momentum. This indicator helps in confirming trend direction and spotting potential reversals.", value: "STD;True%1Strength%1Indicator" },
  { name: "Ultimate Oscillator", description: "The Ultimate Oscillator combines short, intermediate, and long-term price action into one oscillator. Traders look for values above 70 to indicate overbought conditions and below 30 for oversold conditions. This indicator helps in identifying potential reversal points based on multiple timeframes.", value: "STD;Ultimate_Oscillator" },
  { name: "Up/Down Volume", description: "The Up/Down Volume indicator shows the volume of trades on up days versus down days. Positive values indicate buying pressure, while negative values indicate selling pressure. Traders use this to gauge market sentiment and the balance of power between buyers and sellers.", value: "STD;UP_DOWN_Volume" },
  { name: "Visible Average Price", description: "The Visible Average Price shows the average price of visible data on a chart. Traders use this to identify central tendency and potential support/resistance levels. This indicator provides a straightforward way to assess price behavior within a visible range.", value: "STD;Visible%1Average%1Price" },
  { name: "Volatility Stop", description: "The Volatility Stop indicator sets stop levels based on market volatility. Traders use this to set trailing stop-losses that adjust with market volatility, protecting profits and limiting losses. This indicator helps in managing trades based on changing market conditions.", value: "STD;Volatility_Stop" },
  { name: "Volume", description: "The Volume indicator shows the volume of trades over a specific period. Traders look for volume spikes to gauge market interest and potential price movements. This indicator helps in confirming price trends and identifying potential reversals based on volume activity.", value: "Volume@tv-basicstudies" },
  { name: "Volume Delta", description: "Volume Delta shows the difference between buying and selling volume. Positive values indicate more buying, while negative values indicate more selling. Traders use this to understand the volume dynamics and market sentiment, aiding in more informed trading decisions.", value: "STD;Volume%1Delta" },
  { name: "Volume Oscillator", description: "The Volume Oscillator shows the difference between two volume moving averages. Traders look for crossovers with the zero line to identify increasing or decreasing volume trends. This indicator helps in assessing the strength of volume trends and potential reversals.", value: "STD;Volume%1Oscillator" },
  { name: "Volume Weighted Average Price", description: "The Volume Weighted Average Price (VWAP) shows the average price weighted by volume over a specific period. Traders use this to assess the average price over time and identify potential price targets. This indicator helps in planning trades and setting entry/exit points based on volume-weighted averages.", value: "STD;VWAP" },
  { name: "Volume Weighted Moving Average", description: "The Volume Weighted Moving Average (VWMA) is a moving average weighted by volume, giving more weight to higher volume periods. Traders look for crossovers with price to identify trend changes. This indicator helps in making more responsive trading decisions by incorporating volume into the moving average calculation.", value: "STD;VWMA" },
  { name: "Vortex Indicator", description: "The Vortex Indicator identifies the start of a new trend by plotting two oscillating lines. Traders look for crossovers between the lines to identify trend changes. This indicator helps in spotting new trends and confirming trend direction.", value: "STD;Vortex%1Indicator" },
  { name: "Williams Alligator", description: "The Williams Alligator is a trend-following indicator that uses three smoothed moving averages. Traders look for the position of price relative to the moving averages to identify trend direction. This indicator helps in identifying trend direction and potential reversals.", value: "STD;Williams_Alligator" },
  { name: "Williams Fractals", description: "Williams Fractals identify fractal patterns, which are potential turning points in the market. Traders use this to spot potential reversals and plan trades accordingly. This indicator helps in identifying fractal-based trading opportunities.", value: "STD;Whilliams_Fractals" },
  { name: "Williams Percent Range", description: "The Williams Percent Range (%R) shows overbought and oversold levels by comparing the current price to the high-low range over a specified period. Values above -20 indicate overbought conditions, while values below -80 indicate oversold conditions. Traders use this to identify potential reversal points based on momentum.", value: "STD;Willams_R" },
  { name: "Woodies CCI", description: "Woodies CCI is a variation of the Commodity Channel Index (CCI) that includes additional features for identifying trends and reversals. Traders look for patterns and divergences to spot potential trading opportunities. This indicator helps in making more informed trading decisions based on CCI patterns.", value: "STD;Woodies%1CCI" },
  { name: "Zig Zag", description: "The Zig Zag indicator identifies trends and reversals by plotting lines that connect significant price points. Traders use this to filter out noise and identify the overall trend direction. This indicator helps in simplifying price action and spotting major trends and reversals.", value: "STD;Zig_Zag" },
];

const Tab = (props) => (
  <ToolTip title={props.description}>
    <span>
      <BoxField className='small' checked={props.checked} label={props.name} onChange={() => props.onChange(props.value)} />
    </span>
  </ToolTip>
)

function TradingViewWidget({ date=new Date(), symbol, text, close }) {
  const container = useRef();
  const today = new Date();
  const parsedDate = new Date(date);
  const formattedDate = moment(parsedDate).format('YYYY-MM-DD');
  const yearDiff = today.getFullYear() - parsedDate.getFullYear();
  let monthDiff = today.getMonth() - parsedDate.getMonth() + (yearDiff * 12);
  monthDiff = monthDiff < 1 ? 1 : monthDiff;
  const [selectedStudies, setSelectedStudies] = useState([]);
  const isLoggedIn = useContext(AppContext).isLoggedIn;

  const handleStudyToggle = (study) => {
    setSelectedStudies((prev) => {
      const updatedStudies = prev.includes(study)
        ? prev.filter((item) => item !== study)
        : [...prev, study];
  
      // Make Axios POST call after updating selectedStudies
   if (!isLoggedIn) {
    return updatedStudies 
  }
      axios
        .post('/create_chart_indicators', { selectedStudies: updatedStudies })
        .then((response) => {
          console.log('Data posted successfully:', response.data);
        })
        .catch((error) => {
          console.error('Error posting data:', error);
        });
  
      return updatedStudies; // Return the updated state
    });
  };
  useEffect(() => {
    if (!isLoggedIn) {
      return 
    }
    axios
      .get('/fetch_chart_indicators')
      .then((response) => {
        console.log('Fetched indicators:', response.data.data);
        setSelectedStudies(response.data.data); // Store the fetched indicators
      })
      .catch((error) => {
        console.error('Error fetching chart indicators:', error);
      });
  }, []);

  useEffect(() => {
    if (container.current) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
      script.type = 'text/javascript';
      script.async = true;
  

      console.log(`${monthDiff}M`)
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "${symbol}",
          "timezone": "America/New_York",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "withdateranges": true,
          "range": "${monthDiff}M",
          "hide_side_toolbar": true,
          "allow_symbol_change": true,
          "hide_top_toolbar": true,
          "calendar": false,
          "studies": ${JSON.stringify(selectedStudies)},
          "marks": [
            {
              "time": "${formattedDate}",
              "text": "${text}",
              "label": "NY",
              "labelFontColor": "#FFFFFF",
              "minSize": 14
            }
          ],
          "support_host": "https://www.tradingview.com",
          "hideideas": true
        }`;
        
      container.current.appendChild(script);
      return () => {
        container.current.innerHTML = '';
      }
    }
  }, [date, symbol, text, formattedDate, selectedStudies]);

  return (
    <Model closeHandler={close} isLarge={true} title={text} show={true}>
      <div className='overflow-auto text-accent-4 mb-2 pb-2'>
      <div className='d-flex gap-1 mb-1'>
          {studiesInfo.slice(0, 37).map((study) => (
            <Tab
              {...study}
              key={study.value}
              checked={selectedStudies.includes(study.value)} // Check if the study is selected
              onChange={handleStudyToggle}
            />
          ))}
        </div>
        <div className='d-flex gap-1 mb-1'>
          {studiesInfo.slice(37, 74).map((study) => (
            <Tab
              {...study}
              key={study.value}
              checked={selectedStudies.includes(study.value)} // Check if the study is selected
              onChange={handleStudyToggle}
            />
          ))}
        </div>
        <div className='d-flex gap-1'>
          {studiesInfo.slice(74).map((study) => (
            <Tab
              {...study}
              key={study.value}
              checked={selectedStudies.includes(study.value)} // Check if the study is selected
              onChange={handleStudyToggle}
            />
          ))}
        </div>
      </div>
      <strong>*Click Reconnect if graph doesn't load*</strong>
      <div className='position-relative' style={{ paddingTop: '50%', minHeight: '25rem' }}>
        <div className="tradingview-widget-container position-absolute left-0 top-0" ref={container}>
          <div className="tradingview-widget-container__widget" style={{ height: '100%', width: '100%' }}></div>
          <div className="tradingview-widget-copyright">
            <a className='mt-1 btn-text' href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
              <span className='btn-text'>Track all markets on TradingView</span>
            </a>
          </div>
        </div>
      </div>
    </Model>
  );
}

export default memo(TradingViewWidget);


