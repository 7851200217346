import React from 'react';
import axios from 'axios';
import URL from 'url-parse';
import LineChart from '../../ui-v2/LineChart/LineChart';
import { AppContext } from '../../App'
import moment from "moment";
import CoorelationFilter from './CoorelationFilter';
import StockTable from './StockTable';
import Tool from '../../hoc-v2/withTool/Tool';
import './styles.scss';
import TitleBar, { Label } from '../../components-v2/ToolComponents/TitleBar';
import getStockSearch from '../../utility/getStockSearch';

class Correlation_Container extends React.Component {

  static contextType = AppContext;
  render() {
    return (
      <Correlation
        {...this.props}
        {...this.context} />
    )
  }
}

class Correlation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      stocks: ['AAPL', 'TSLA'],
      stock_search: getStockSearch(this.props) || 'AAPL, GOOGL',
      comparable_stock_data: [],
      stock_data: [],
      start_date: "2020-08-11",
      end_date: "2020-08-11",
      correlation: 0,
      isLoading: false,
      error_msg: "",
      layout: 'table',

    }
  }
  findMostSimilarStocks = () => {
    let symbols = this.state.stock_search.split(',');
    axios.post('/find_most_correlated_stocks', {
      symbols: symbols
    }).then((response) => {
      let compare_stock_data_obj = response.data.data;
      Object.keys(compare_stock_data_obj).forEach((key) => {
        compare_stock_data_obj[key] = compare_stock_data_obj[key].map((stock) => {
          let stocks = stock.stocks;
          // Find the stock that does not match the target symbol
          let similarStock = stocks.find(obj => obj.ticker.toLowerCase() !== key.toLowerCase().trim());

          if (!similarStock) {
            // Handle the case where no similar stock is found
            return {
              ...stock,
              correlation: (stock.correlation * 100).toFixed(2),
              ticker: null,
              company_name: null,
              marketcap: null
            };
          }
          else{
            return {
              ...stock,
              correlation: (stock.correlation * 100).toFixed(2),
              ticker: similarStock.ticker,
              company_name: similarStock.company_name,
              marketcap: similarStock.marketcap
            };
          }
          
        });
      });



      // console.log(compare_stock_data_obj)

      this.setState({
        comparable_stock_data: compare_stock_data_obj
      });
    }).catch((error) => {
      console.error("Error fetching most correlated stocks:", error);
      // Optionally, you could set an error state here
      // this.setState({ error: 'Failed to fetch correlated stocks' });
    });
  };
  componentDidMount() {
    if (this.props.state) return this.setState(this.props.state);
    var start_date = new Date();
    start_date.setMonth(start_date.getMonth() - 1);
    var compare_stock_data = [];

    this.findMostSimilarStocks()

    this.setState({
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(Date.now()).format('YYYY-MM-DD'),
      comparable_stock_data: compare_stock_data

    })
  }
  handleChange = (e, values = null) => {
    // console.log(e.target.value, e.target.name)
    if (e.target.name === 'stock_search') {
      this.setState({
        [e.target.name]: values?.join(',') || e.target.value,
        page: 0,
      },
        () => {
          this.findMostSimilarStocks()
        }
      )
    } else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.setState(this.state.comparable_stock_data)
    }
    if (prevProps.symbol_list !== this.props.symbol_list) {
      this.setState({
        stock_search: this.props.symbol_list,
        page: 0,
      }, () => {
        this.findMostSimilarStocks()
      })

    }
  }
  handleStartChange = (date) => {
    this.setState({
      start_date: moment(date).format('YYYY-MM-DD')
    })
  }
  handleEndChange = (date) => {
    this.setState({
      end_date: moment(date).format('YYYY-MM-DD')
    })
  }

  addStock = () => {
    this.setState(prevState => ({
      stocks: [...prevState.stocks, ''] // you may change '' to a default stock symbol if you prefer
    }));
  }

  handleStockChange = (index, value) => {
    this.setState(prevState => {
      const stocks = [...prevState.stocks];
      stocks[index] = value;
      return { stocks };
    });
  }

  submitStocks = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.setState({ stocks: this.state.stocks.map(stock => stock.toUpperCase()) });


    axios.post('/get_correlation', {
      stocks: this.state.stocks.map(stock => stock.toUpperCase()),
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    }).then((response) => {
      if (response.data.status === "success" || response.data.status === "partial") {
        this.setState({
          stock_data: response.data.data,
          correlation: response.data.correlation.toFixed(2),
          isLoading: false,
          error_msg: response.data.status === "partial" ? `Could not fetch data for: ${response.data.failedSymbols.join(', ')}` : ""
        });
      } else {
        this.setState({
          error_msg: "We could not find data on one or more of the stock symbols you searched.",
          isLoading: false
        });
      }
    }).catch(error => {
      this.setState({
        error_msg: "An error occurred while fetching the data.",
        isLoading: false
      });
    });
  }

  onStockRemove = (index) => {
    this.setState(prevState => {
      const stocks = [...prevState.stocks];
      stocks.splice(index, 1);
      return { stocks };
    });
  }


  render() {
    this.props.getState && this.props.getState(this.state);
    const responsive = this.state.stock_data.length > 0;
    // this.props.setResponsive(this.state.stock_data.length > 0);

    return (
      <Tool
        id="correlation"
        algo={this.props.algo}
        responsive={responsive}
      >
        <div className='d-flex flex-column'>
          <TitleBar id="correlation" label="Correlate More" responsive={responsive} />
          <CoorelationFilter
            handleChange={this.handleChange}
            handleStartChange={this.handleStartChange}
            handleEndChange={this.handleEndChange}
            submitStocks={this.submitStocks}
            addStock={this.addStock}
            handleStockChange={this.handleStockChange}
            {...this.state}
            algo={this.props.algo}
            onStockRemove={this.onStockRemove}
          />
        </div>
        <div className='ToolPage-Main d-flex flex-column gap-3 pb-5'>
          {this.state.layout !== 'table' ?
            <>
              <div className='flex-between'>
                <small className='text-accent-4'>correlation: {this.state.correlation}</small>
                {this.props.isPage && <Label id="correlation" label="Correlate More" />}
              </div>
              {this.state.error_msg && <div className='error'>{this.state.error_msg}</div>}
              {this.state.stock_data.length > 0 &&
                <LineChart
                  data={this.state.stock_data}
                  labels={["Date", ...this.state.stocks.map(stock => `Price Change (%) ${stock}`)]}
                  dataKeys={this.state.stocks.map(stock => ({
                    dataKey: stock,
                    stroke: '#' + Math.floor(Math.random() * 16777215).toString(16), // generates a random color
                    activeDot: { r: 8 }
                  }))}
                >
                </LineChart>
              }
            </>
            : <StockTable {...this.state} />
          }
        </div>

      </Tool>
    )
  }
}

export default Correlation_Container;