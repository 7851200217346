import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useApp } from '../../App';
import FaceBook from '../../icons-v2/FaceBookV2';
import Instagram from '../../icons-v2/Instagram';
import LinkedIn from '../../icons-v2/LinkedIn';
import Mail from '../../icons-v2/Mail';
import TikTok from '../../icons-v2/TikTok';
import Twitter from '../../icons-v2/Twitter';
import Youtube from '../../icons-v2/Youtube';
import moment from 'moment';
import './Footer.scss';


const SocialLink = ({ href, Icon }) => (
    <li>
        <a href={href} className="btn smaller btn-icon text-accent-4" target="_blank">
            <Icon />
        </a>
    </li>
)

const Footer = () => {
    const pathname = useLocation().pathname;
    return /algo_page|admin/.test(pathname) ? "" : (
        <>
            <div className='Footer-Padding' />
            <footer className={`__App-Footer z-index-4 mt-auto text-accent-4 smaller d-flex flex-lg-row flex-column gap-2 px-md-4 border-top px-3 py-2 bg-surface-1 align-items-center bottom-0 w-100 start-0 `}>
                <ul className='list-unstyled d-flex gap-1 align-items-center gap-1 flex-1'>
                    <li className='ms-1'><p className='m-0'>Follow us on</p></li>
                    <SocialLink href="https://www.instagram.com/stockalgos/" Icon={Instagram} />
                    <SocialLink href="https://twitter.com/stockalgos/" Icon={Twitter} />
                    <SocialLink href="https://facebook.com/stockalgos/" Icon={FaceBook} />
                    <SocialLink href="https://linkedin.com/company/stockalgos/" Icon={LinkedIn} />
                    <SocialLink href="https://www.tiktok.com/@stockalgos" Icon={TikTok} />
                    <SocialLink href="https://www.youtube.com/@stockalgos" Icon={Youtube} />
                </ul>
                <a className='btn-text smaller btn d-sm-none text-accent-4' href="mailto:admin@stockalgos.com"><Mail />admin@stockalgos.com</a>
                <ul className='list-unstyled gap-2 py-1 d-flex Footer-Links align-items-center'>
                    <li className='d-sm-flex d-none'><a className='btn-text btn smaller text-accent-4' href="mailto:admin@stockalgos.com"><Mail />admin@stockalgos.com</a></li>
                    <li>
                        <Link to='/experts'>Experts</Link>
                    </li>
                    <li>
                        <Link to='/help-and-support' className='btn-text smaller btn text-accent-4'>Help & Support</Link>
                    </li>
                    <li>
                        <Link to='/privacy' className='btn-text smaller btn text-accent-4'>Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to='/terms-and-conditions' className='btn-text smaller btn text-accent-4'>Terms & Conditions</Link>
                    </li>
                    {/* <li className='border-left pe-2'>
                        <a target="_blank" href='https://stockalgos.myshopify.com/collections/all' className='btn btn-text smaller text-accent-4'>Merch Store</a>
                    </li> */}
                    {/* <li className='border-left pe-2'>
                        <a target="_blank" href='/blog' className='btn btn-text smaller text-accent-4'>Blog</a>
                    </li> */}
                </ul>
                <p className='m-0 flex-1 text-start'>© {moment().format('YYYY')} StockAlgos | Proudly Canadian</p>
            </footer>
        </>
    )
}

export default Footer;
