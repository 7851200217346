import React from "react";
import InputField from "../ui-v2/InputField/InputField";
import Form from "../utility/Form";
import axios from "axios";
import toastr from 'toastr'
import { useApp } from "../App";

export default function PasswordReset() {

  const auth = useApp(), token = new URLSearchParams(window.location.search).get('token')
  if(!token || token.length < 10) return ""
  const onSubmit = e => {
    const { password, confirmPassword } = e.values;
    if (password !== confirmPassword) e.onFailure("Your password don't match!")
    else {
      axios.post("/password_reset", {
        password,
        token,
      }).then(({ data }) => {
        if (data.success) {
          auth.persistUser(data.user)
          toastr.success("Password has been changed successfully!")
        }
        else e.onFailure(<>Token has been expired, please get a <a href="#forgot-password" className="btn-text">new one!</a></>)
      }).catch(err => e.onFailure(<>Token has been expired, please get a <a href="#forgot-password" className="btn-text">new one!</a></>))
    }
  };



  return (
    <>
      <h6 className="model-title">Create new Password</h6>
      <Form onSubmit={onSubmit}
        className="d-flex flex-column gap-3"
        footer={isLoading => (
          <div className='model-footer mt-md-3'>
            <button className='btn btn-primary' disabled={isLoading}>Sign in {isLoading && <span className='loader'></span>}</button>
          </div>
        )}
      >
        <InputField
          label='Password'
          type='password'
          minLength={8}
          required={true}
          name='password'
          errorText="Password must be minimum 8 characters long!"
        />
        <InputField
          label='Confirm Password'
          type='password'
          minLength={8}
          required={true}
          name='confirmPassword'
          errorText="Confirm Password must be minimum 8 characters long!"
          className='mb-1'
        />

      </Form>
    </>
  );
}