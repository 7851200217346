import React, { useContext, useMemo } from "react"
import { AppContext } from '../../../App';
import './styles.scss';
import AddIcon from "../../../icons-v2/AddIcon";
import Info from "../../../icons-v2/InfoIconV2";
import PlayIcon from "../../../icons-v2/PlayIcon";
import WriteReviewV2 from "../../WriteAReviewModal/V2";


export default function LeftSection(props) {
    const isDashboard = window.location.pathname !== '/algo_page'
    const { user, isLoggedIn } = useContext(AppContext)
    return (
        <>
            <div className={`ToolPage-LeftSection h3 max-vh-page-header`}>
                <div className={`small overflow-auto flex-1 text-accent-4 fw-normal d-flex flex-column gap-3 ${props.className}`}>
                    {useMemo(() => isDashboard && (
                        <div className="d-flex gap-3">
                            {props.algo && props.algo.video_url && (
                                <label className="btn btn-text small" htmlFor={`${props.id}-video`}>
                                    <PlayIcon /> Explainer Video
                                </label>
                            )}
                            <label className="btn btn-text small" htmlFor={`${props.id}-info`}>
                                <Info /> Info
                            </label>
                            {(isLoggedIn && user.email === 'admin@stockalgos.com') && (
                                <>
                                    <a href={`/admin/edit-an-algo/${props.algo._id}`} className="btn btn-text small">
                                        <label className="btn btn-text small" >
                                            <Info /> Edit
                                        </label>
                                    </a>
                                </>
                            )}
                            {isLoggedIn && <WriteReviewV2 algo={props.algo} />}
                        </div>
                    ), [props.algo])}
                    {props.children}
                </div>
                {props.footer}
            </div>
            <label className="btn-icon btn d-none ToolPage-CloseButton" htmlFor={props.id}>
                <AddIcon className="rotate-45" />
            </label>
        </>
    )
}