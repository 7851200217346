import React from 'react';
import moment from "moment";
import PickersUtilsProvider from '../../ui-v2/PickersUtilsProvider/PickersUtilsProvider';
import DatePicker from '../../ui-v2/DatePicker/DatePicker';
import InputField from '../../ui-v2/InputField/InputField';
import BoxField from '../../ui-v2/BoxField/BoxField';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';
import SearchField from '../../components-v2/AlgoHoldingCommonTools/SearchField';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import Select from '../../ui-v2/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '../../ui-v2/SelectField/SelectField';

const NewsFilter = ({
  handleChange,
  isLoading,
  start_date,
  end_date,
  error_msg,
  handleStartChange,
  handleEndChange,
  stock_search = undefined,
  algo,
  topics = undefined,
  sort,
  limit,
  viewMode,
  isMobile,
  fetchPageContent = false
}) => {
  const topicOptions = [
    'blockchain',
    'earnings',
    'ipo',
    'mergers_and_acquisitions',
    'financial_markets',
    'economy_fiscal',
    'economy_monetary',
    'economy_macro',
    'energy_transportation',
    'finance',
    'life_sciences',
    'manufacturing',
    'real_estate',
    'retail_wholesale',
    'technology'
  ];

  const sortOptions = ['NEWEST', 'OLDEST', 'RELEVANCE'];

  return (
    <LeftSection id="news" algo={algo}>
      <>
        {!isMobile && (
          <div>
            <p className='mt-0 mb-3'>News Search</p>
            <SearchField
              name="stock_search"
              label="Stock Symbols"
              value={stock_search || ''}
              onChange={handleChange}
              placeholder='Enter Stock Symbols (comma separated)'
              endAdornment={<WatchListImport forDashboard={true} handleStockChange={handleChange} />}
            />
          </div>
        )}
        <div className="d-flex gap-2 flex-wrap mb-1">
          <p className='m-0 w-100 '>Display Mode</p>
          <BoxField
            defaultChecked={!viewMode || viewMode === 'individual'}
            type='radio'
            label="Individual"
            value='individual'
            name="viewMode"
            onChange={handleChange}
          />
          <BoxField
            defaultChecked={viewMode === 'grouped'}
            type='radio'
            label="Grouped"
            value='grouped'
            name="viewMode"
            onChange={handleChange}
          />
        </div>

        <SelectField
          title="Filter news by specific topics"
          fullWidth
          value={topics || 'ALL'}
          name="topics"
          onChange={handleChange}
          label="News Topics"
        >
          <MenuItem key="ALL" value="ALL">ALL</MenuItem>
          {topicOptions.map((topic) => (
            <MenuItem key={topic} value={topic}>
              {topic.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </MenuItem>
          ))}
        </SelectField>



        <SelectField
          title="Sort news by relevance or date"
          fullWidth
          name="sort"
          label="Sort Order"
          value={sort}
          onChange={handleChange}
        >
          {sortOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </SelectField>

      
          <div>
            <p className='mt-0 mb-2'>Advanced Options</p>
            <BoxField
              className='small'
              checked={fetchPageContent}
              type='checkbox'
              label="Fetch Page Content"
              name="fetchPageContent"
              onChange={handleChange}
            />
            <small className="d-block mt-2">Load full article content (slower)</small>
          </div>
    

        <div className='d-flex flex-column gap-3'>
          <p className='mb-0 mt-1'>Time Range</p>
          <PickersUtilsProvider>
            <DatePicker
              inputVariant='outlined'
              label="From Date"
              value={moment(start_date)}
              name='start_date'
              onChange={handleStartChange}
            />
            <DatePicker
              className="__custom-input-field"
              label="To Date"
              value={moment(end_date)}
              name='end_date'
              onChange={handleEndChange}
            />
          </PickersUtilsProvider>
        </div>
      </>
    </LeftSection>
  )
}

export default NewsFilter; 