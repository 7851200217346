import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';

export default function TableData({ number_of_patents, symbol, patents = [], filters }) {
    const [state, setState] = useState({ pt: [], page: 0, loading: false });
    const { pt, page, loading } = state
    useEffect(() => {
        setState({ pt: [...patents], page: 0, loading: false })
    }, [patents]);
    const onNext = () => {
        const newPage = page + 1;
        const nextItems = newPage * 100;
        if (pt.length <= nextItems) {
            setState({ loading: true, pt, page: newPage });
            axios.post('/find_stock_patents', {
                ...filters,
                symbols: [symbol],
                after_id: pt[pt.length - 1].patent_id,
                after_date: pt[pt.length - 1].patent_date
            })
                .then(res => {
                    console.log(res.data.data[symbol])
                    setState({
                        pt: [
                            ...pt,
                            ...res.data.data[symbol].patents
                        ],
                        loading: false,
                        page: newPage
                    })
                })
        }
        else setState({ ...state, page: newPage })
    }
    useEffect(() => {

    }, [])
    return (
        <>
            {pt.slice(page * 100, (page + 1) * 100).map((stock, index) => (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                        <div className='description'>
                            <span>{stock.patent_title}</span>
                        </div>
                    </td>
                    <td>
                        <div className='description lines-10'>
                            <span>{stock.patent_abstract}</span>
                        </div>
                    </td>

                    <td>{moment(stock.patent_date).format('YYYY-MM-DD')}</td>
                </tr>
            ))}
            <tr>
                <td colSpan={20}>
                    {loading && <div className="bar-loader"></div>}
                    <div className='flex-middle py-2 gap-3'>
                        <button
                            disabled={loading || page === 0}
                            className='btn-text btn'
                            onClick={() => page <= number_of_patents && setState({ ...state, page: page - 1 })}
                        >Previous</button>
                        <button
                            disabled={loading}
                            onClick={onNext}
                            className='btn-text btn'
                        >Next</button>
                    </div>
                </td>
            </tr>
        </>
    )
}