import React from "react";
import './styles.scss';
import HeaderLinkMain from "./Main";

const HeaderLink = ({ className = '', ...props }) => {
    return (
        <li className={className}>
            <HeaderLinkMain {...props} />
        </li>
    )
}

export default HeaderLink;