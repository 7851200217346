import React, { PureComponent, useContext } from 'react'
import { identifyUser } from './tiktokTracking';
import axios from 'axios'
import URL from 'url-parse';
import IdleTimer from 'react-idle-timer'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import history from './history';
import io from 'socket.io-client';
import { checkAlgoAccess, checkAlgoPurchased, getAlgoByName } from './apis/Algos';
const socket = io.connect();

const user = {
  _id: null,
  loading: true,
  user_viewed_onboarding: true
}

export const AppContext = React.createContext({
  user,
  isLoggedIn: null,
  viral_components: [],
  handleLogOut: () => {
  }
})

class Analytics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      number_of_interactions: 0,
      initialized: false,
      url: new URL(window.location.href, true),
      active_total: 0,
      idle_total: 0
    }
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
  }
  handleOnAction(event) {
    this.setState({
      url: new URL(window.location.href, true),
      number_of_interactions: this.state.number_of_interactions + 1
    })
  }

  timed_func = () => {
    setTimeout(
      () => {
        if (this.props.isLoggedIn) {
          try {
            const { active_total, idle_total } = this.state
            const active_now = this.idleTimer.getTotalActiveTime()
            const idle_now = this.idleTimer.getTotalIdleTime()
            axios.post('/update_analytics', {
              active_time: active_now - active_total,
              idle_time: idle_now - idle_total,
              number_of_interactions: this.state.number_of_interactions,
              user_id: this.props.user_id,
              current_url: this.state.url.pathname !== "/algo_page" ? this.state.url.pathname : `/${this.state.url.query.algo}`,
              algo_id: this.state.url.pathname === "/algo_page" ? this.state.url.query.algo_id : ''
            }).then((response) => {
              this.setState({
                number_of_interactions: 0,
                active_total: active_now,
                idle_total: idle_now
              })
              this.timed_func()
            })
          } catch { }
        }
      },
      1000 * 15
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.isLoggedIn && this.props.user_id.length > 0 && !this.state.initialized) {
      this.setState({
        initialized: true
      })
      this.timed_func()
    }
  }

  render() {


    return (<>

      <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        timeout={1000 * 30}
        onActive={this.handleOnActive}
        onIdle={this.handleOnIdle}
        onAction={this.handleOnAction}
        debounce={250}
      />
    </>)

  }
}

class App extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      user,
      isLoggedIn: null,
      username: '',
      password: '',
      url: new URL(window.location.href, true),
      socket,
      onRefresh: () => {
        const user = { ...this.state.user };
        const isLoggedIn = this.state.isLoggedIn;
        this.setState({ user: { loading: true }, isLoggedIn: null });
        setTimeout(() => {
          this.setState({ user, isLoggedIn })
        }, 300)
      },
      persistRegiserUser: (user) => {

        this.setState({
          user: {
            loading: false,
            _id: user._id,
            name: user.name,
            email: user.email,
            user_name: ""
          },
          isLoggedIn: true,
          password: '',

        })
        localStorage.setItem('userId', user._id)
      },
      persistUser: (user, otherUpdates = {}) => {
        socket.emit("USER_CONNECTED", user._id)
        this.setState({
          user: {
            loading: false,
            ...user
          },
          isLoggedIn: true,
          password: '',
          ...otherUpdates
        })
        localStorage.setItem('userId', user._id)
        identifyUser(user.email, user._id)
      },
      changeUser: (data) => {
        this.setState({
          user: {
            ...this.state.user,
            ...data
          }
        })
      },
      viral_components: [],
      logOutUser: () => {
        socket.emit("USER_DISCONNECTED", this.state.user._id)
        if (window.gapi && window.gapi.auth2) {
          const auth2 = window.gapi.auth2.getAuthInstance()
          if (auth2 != null) {
            auth2.signOut().then(
              auth2.disconnect().then()
            )
          }
        }

        axios.get('/log_out')
          .then((response) => {

            if (response.data.status === "success") {
              this.setState({
                isLoggedIn: false,
                user: { ...user, loading: false },
              }, () => {
                history.replace('/')
              })
            }
          })
      }
    }

    this.routerProcess = this.routerProcess.bind(this)
  }

  routerProcess(success) {

    const { pathname, search } = history.location;
    const common_paths = /marketplace|help-and-support|use-of-cookies|unsubscribe|experts|privacy|terms-and-conditions|leaderboard|go-unlimited|dashboard|competition|password_reset|email_verification|^\/$/
    if (common_paths.test(pathname)) return;
    if (success && pathname.includes('/auth')) return history.replace('/');
    if (!success && !pathname.includes('admin')) history.replace(`/auth/login?redirect=${pathname + search}`)
  }

  async componentDidMount() {
    const free_for_all = true
    const data = document.querySelector("meta[name='page-data']").content;
    let startup;
    if (data && data !== "__LOAD-DATA__") startup = JSON.parse(atob(data));
    const { user, viral_components } = startup || (await axios.get('/fetch-startup')).data;
    const unlimitedAlgo = (await getAlgoByName('StockAlgos Unlimited')).data.data;
    if (!free_for_all) {
      if (user) {
        const data = { algoId: unlimitedAlgo?._id, userId: user._id }
        const hasUnlimitedAccess = (await checkAlgoAccess(data)).data.access_granted;
        const unlimitedPurchased = (await checkAlgoPurchased(data)).data;
        this.state.persistUser(user, { viral_components, unlimitedAlgo, unlimitedPurchased, hasUnlimitedAccess })
      }
      else this.setState({ viral_components, user: { loading: false }, unlimitedAlgo, unlimitedPurchased: {} })
      this.routerProcess(user)
    } else {
      if (user) {
        const data = { algoId: unlimitedAlgo?._id, userId: user._id }
        const hasUnlimitedAccess = (await checkAlgoAccess(data)).data.access_granted;
        const unlimitedPurchased = (await checkAlgoPurchased(data)).data;
        this.state.persistUser(user, { viral_components, unlimitedAlgo, unlimitedPurchased, hasUnlimitedAccess })
        this.routerProcess(user)
      }
      else this.setState({ viral_components, user: { loading: false }, unlimitedAlgo, unlimitedPurchased: {},hasUnlimitedAccess:true })
    }
   
  }

  render() {
    return (
      <React.Fragment>
        {process.env.NODE_ENV === 'production' && (
          <Analytics
            isLoggedIn={this.state.isLoggedIn}
            user_id={this.state.isLoggedIn ? this.state.user._id : ""}
          />
        )}
        <AppContext.Provider value={this.state}>
          {this.props.children}
        </AppContext.Provider>

      </React.Fragment>
    )

  }
}


export const useApp = () => useContext(AppContext)

export default App;
