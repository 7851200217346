import React from 'react';
import './styles.scss';

const withField = Component => ({ label, className, end, inputClass, errorText, onBlur, ...props }) => (
    <div className={className}>
        <fieldset className="with-field">
            <Component placeholder=' ' className={`${inputClass} Field`} {...props} onBlur={e => {
                e.target.classList.add("Input-Blur");
                onBlur && onBlur(e);
            }} />
            {label && (
                <>
                    <label>{label}</label>
                    <legend>{label}</legend>
                </>
            )}
            {end}
        </fieldset>
        {errorText && <small className="with-field-error">{errorText}</small>}
    </div>
)


export default withField;