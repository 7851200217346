import React from 'react';
import { NavLink } from "react-router-dom";
import NavDropDown from "../../Header/NavDropDown/NavDropDown";
import UserAvatar from "../../UserAvatar/UserAvatar";
import './styles.scss';
import HeaderLinkMain from '../HeaderLink/Main';

const UserDropDown = ({ user, ...props }) => {
    return (
        <NavDropDown className="Header-User-DropDown"
            component={(status, ref) => (
                <li ref={ref} className={status ? "active" : ""}>
                    <HeaderLinkMain
                        title={<><UserAvatar {...user} />{user.name}</>}
                    />
                </li>
            )}>
            <li>
                <NavLink to='/my-account'>My Account</NavLink>
            </li>
            <li>
                <NavLink to='/help-and-support'>Help & Support</NavLink>
            </li>
            <li>
                <NavLink to='/privacy'>Privacy policy</NavLink>
            </li>
            <li>
                <label htmlFor="Logout-Modal" className='btn'>Logout</label>
            </li>
        </NavDropDown>
    )
}

export default UserDropDown;
