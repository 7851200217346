import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '../../ui-v2/SelectField/SelectField';
import InputField from '../../ui-v2/InputField/InputField';
import WatchListImport from '../../components-v2/AlgoHoldingCommonTools/watchlist_import';
import LeftSection from '../../components-v2/ToolComponents/LeftSection';
import SearchField from '../../components-v2/AlgoHoldingCommonTools/SearchField';


const IPOSFIlters = ({
    handleFilterChange,
    sort_type,
    period,
    period_type,
    selected_type,
    handleSortChange,
    handleStockChange,
    stock_search,
    algo
}) => {
    return (
        <LeftSection id="ipos" algo={algo}>
            <div>
                <div className='d-flex mb-2 justify-content-between'>
                    <small>Data Filters</small>
                    <WatchListImport handleStockChange={handleStockChange} />
                </div>
                <SearchField
                    name='stock_search'
                    label="Search stock symbol"
                    onChange={handleStockChange}
                    value={stock_search}
                />
            </div>
            <div className='d-flex flex-column gap-3'>
                <SelectField
                    fullWidth
                    value={period}
                    name="period"
                    onChange={handleFilterChange}
                    label="Filter By Date"
                >

                    {period_type.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
                <SelectField
                    fullWidth
                    value={selected_type}
                    name="selected_type"
                    onChange={handleSortChange}
                    label="Sort By"
                >
                    {sort_type.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </SelectField>
            </div>

        </LeftSection>
    )
}

export default IPOSFIlters;
