import Model from '../../ui-v2/Modal/Model';
import React from 'react';

const AlgoInfoPopUp = ({ algo, ...props }) => {
    const display_url_arr_rough = (algo !== undefined && algo.help_link !== undefined) ? algo.help_link.split('.') : ""
    const remove_www = display_url_arr_rough.length > 0 && display_url_arr_rough.filter((x) => x.includes('www') === false)[0]
    const rough = (remove_www.length > 0 && remove_www.includes('//')) ? algo.help_link.split('//')[1] : remove_www
    const clean_display_help_url = rough
    return (
        <Model isNormal={true} {...props} title='Info' actions={hidePopUp => <button className='btn btn-primary' onClick={hidePopUp}>Done</button>}>
            <div>
                <small className='fw-semibold d-block'>Description</small>
                <p className='mb-0' dangerouslySetInnerHTML={{__html:algo.description}}></p>
            </div>
            <div>
                <small className='fw-semibold d-block'>How To Use</small>
                <p className='mb-0' dangerouslySetInnerHTML={{__html:algo.howToUse}}></p>
            </div>
            {algo.help_link !== undefined &&
                <div>
                    <small className='mt-0 mb-3 fw-semibold d-block'>Helper Link</small>
                    <div className='d-grid'>
                        <a  className='btn small px-3 justify-content-start btn-border' href={algo.help_link.includes("http") ? algo.help_link : "//" + algo.help_link} target="blank">
                            <span className='ellipsis'>{clean_display_help_url.toUpperCase()}</span>
                        </a>
                    </div>
                </div>
            }
        </Model>
    )
}

export default AlgoInfoPopUp;
