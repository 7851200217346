import { useEffect, useRef } from "react"

const useSetViewPort = (active = true) => {
    const ref = useRef();
    useEffect(() => {
        const elm = ref.current;
        if (!active || !elm) return;
        const doc = document.documentElement, query = window.matchMedia("(orientation: portrait)");
        const cb = () => {
            const rect = elm.getBoundingClientRect();
            doc.style.setProperty('--vpe-bottom', rect.bottom + 'px');
            doc.style.setProperty('--vpe-height', (window.innerHeight - rect.bottom) + 'px');
        }
        query.addEventListener('change', cb)
        window.addEventListener('resize', cb)
        cb();
        return () => {
            query.removeEventListener('change', cb);
            window.removeEventListener('resize', cb);
            doc.style.setProperty('--vpe-height', window.innerHeight + 'px');
        }
    }, [ref, active])
    return ref
}

export default useSetViewPort;