import React from 'react';
import axios from 'axios'
import URL from 'url-parse';
import moment from "moment";
import Pagination from '../../ui-v2/Pagination/Pagination';
import Filter from '../../components-v2/AlgoHoldingCommonTools/SideFilter';
import StockTable from './StockTable';
import { Link } from 'react-router-dom';
import TipCard from '../../ui-v2/TipCard/TipCard';
import Tool from '../../hoc-v2/withTool/Tool';
import SignalButtons from '../SignalsHandler/SignalButtons';
import SignalsHandler from '../SignalsHandler/SignalsHandler';
import withQuery from '../../hoc-v2/withQuery';
import TitleBar, { Label } from '../../components-v2/ToolComponents/TitleBar';
import getStockSearch from '../../utility/getStockSearch';

class Congress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: new URL(window.location.href, true),
            stock_data: [],
            sort_type: ["Date", "Number of transactions", "Number of participants", "Most Value"],
            period: "All transactions",
            period_type: ["All transactions", "Purchase transactions", "Sale transactions"],
            selected_type: "Date",
            orig_stock_data: [],
            created: false,
            page: 0,
            totalLength: 10,
            num_buys: 0,
            num_sells: 0,
            date: 'All',
            dates: ['All'],
            selectionRange: {
                startDate: new Date(moment().subtract(120, 'days')),
                endDate: new Date(moment()),
                key: 'selection',
            },
            rangeView: false,
            process: false,
            stock_search: getStockSearch(this.props),
            loadingTable: false,
            no_result: false,
            unique_date: "",
            graph_title: "",
            graph_subtitle: "",
            graph: {},
            number_of_days_add: 2,
            number_of_days_sub: 2,
            tableView: 'individual',
            people_search: '',
        }
        this.handlePageClick = this.handlePageClick.bind(this)
        this.getData = this.getData.bind(this)
        this.handleRangeSelect = this.handleRangeSelect.bind(this)
        this.changeTableView = this.changeTableView.bind(this)
    }

    changeTableView(e) {
        this.setState({
            tableView: e.target.value
        }, () => {
            this.getData()
        })
    }

    handleRangeSelect(e) {
        this.setState({
            selectionRange: e.selection,
            rangeView: false
        }, () => {
            this.getData()
        })
    }


    componentDidMount() {
        if (this.props.state) return this.setState(this.props.state);
        this.getData()
    }

    initializeDateRange = () => {
        this.setState({
            rangeView: true
        }, () => {
            document.addEventListener('mousedown', this.handleClick, false)
        })


    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false)
    }
    handleClick = (e) => {
        if (this.node !== null && this.node.contains(e.target)) {
            return
        } else {
            this.handleClickOutside();
        }
    }
    handleClickOutside = () => {
        this.setState({
            rangeView: false
        })
    }

    getData() {
        const { page, selected_type, period, selectionRange, stock_search, tableView } = this.state
        const json = {
            filter: period,
            page: page,
            sort: selected_type,
            range: selectionRange,
            stock_search: stock_search.split(','),
            tableView: tableView,
            people_search: this.state.people_search
        }
        this.setState({
            stock_data: [],
            process: true,
            loadingTable: true,
            no_result: false
        })
        axios.post('/get_congress_data', {
            ...json
        }).then((response) => {

            const res = response.data
            if (res.success) {
                this.setState({
                    stock_data: res.rlt,
                    dates: res.dates,
                    totalLength: res.total,
                    num_buys: res.num_buys,
                    num_sells: res.num_sells,
                })
                if (res.rlt.length === 0) {
                    this.setState({
                        no_result: true
                    })
                }
            }
        }).finally(() => {
            this.setState({
                process: false,
                loadingTable: false,
            })
        })
    }

    handlePageClick(data) {
        this.setState({
            page: data.selected
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSortChange = (e) => {
        let val = e.target.value
        this.setState({
            selected_type: val,
            page: 0,
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }

    handleFilterChange = (e) => {
        let { name, value } = e.target
        this.setState({
            [name]: value,
            page: 0,
        })
        setTimeout(() => {
            this.getData()
        }, 100)
    }

    handleStockChange = (e, values) => {

        this.setState({
            [e.target.name]: values?.join(',') || e.target.value,
            page: 0,
        }, () => {
            setTimeout(() => {
                this.getData()
            }, 100) 
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.setState(this.state.stock_data)
        }
        if (prevProps.symbol_list !== this.props.symbol_list) {
            this.setState({
                stock_search: this.props.symbol_list,
                page: 0,
            }, () => {
                this.getData()
            })

        }
    }


    render() {
        const { loadingTable, totalLength, scraping, page, num_buys, num_sells } = this.state
        const tipProps = this.props.tipProps;
        const stepNo = tipProps?.stepNo;
        this.props.getState && this.props.getState(this.state);
        const isMobile = this.props.isMobile, isPage = this.props.isPage;
        const signals = <SignalsHandler
            name="congress"
            algo_name="Congress"
            component={options => (
                <SignalButtons
                    name="congress"
                    {...options}
                    stepNo={stepNo}
                />
            )}
        />
        return (
            <>
                {this.props.isPage && (
                    <>
                        <TipCard id='tip-1'
                            totalSteps={3}
                            activeStep={1}
                            {...tipProps}
                            className='mt-3 me-0'
                        >Hover over an item for an explanation of its function.</TipCard>
                        <TipCard id='tip-2'
                            {...tipProps}
                            totalSteps={3}
                            activeStep={2}
                        >Filter the data based on your desired criteria (ie. by date, by stock, by transaction type, etc.). View the data as grouped by company, or by individual transactions.</TipCard>
                        <TipCard id='tip-3'
                            {...tipProps}
                            totalSteps={3}
                            activeStep={3}
                        >Opt in for email signals so you can stay informed and never miss a trade. <Link to='/' className='btn btn-text text-surface-1'>Learn how to use</Link></TipCard>
                    </>
                )}
                <Tool id="congress" algo={this.props.algo}>
                    <div className='d-flex flex-column'>
                        <TitleBar id="congress">
                            {(!isPage && isMobile) && signals}
                        </TitleBar>
                        <Filter
                            {...this.state}
                            {...this.props}
                            id='congress'
                            changeTableView={this.changeTableView}
                            handleFilterChange={this.handleFilterChange}
                            handleSortChange={this.handleSortChange}
                            handleStockChange={this.handleStockChange}
                            search_with_people={true}
                            handleRangeSelect={this.handleRangeSelect}
                            initializeDateRange={this.initializeDateRange}
                            stepNo={stepNo}
                            name="congress"
                            algo_name="Congress"
                        />
                    </div>
                    <div className='ToolPage-Main d-flex flex-column'>
                        <div className='flex-center text-accent-4 mb-4 gap-2'>
                            <small className='ms-auto'>Transactions: {num_buys} Buys, {num_sells} Sells </small>
                            {(isPage || !isMobile) && signals}
                            {isPage && isMobile && <Label id="congress" />}
                        </div>
                        {scraping && <span>Now it's scraping and update Database. It will takes 2 - 3 minutes.</span>}
                        {(scraping || loadingTable) && <div className='bar-loader mb-3' />}

                        <StockTable
                            graphStock={this.graphStock}
                            {...this.state}
                            stepNo={stepNo}
                        />
                        <Pagination
                            pageCount={Math.ceil(totalLength / 10)}
                            initialPage={page}
                            forcePage={page}
                            onPageChange={this.handlePageClick}
                            className='m-auto mb-0'
                        />
                    </div>
                </Tool>
            </>
        )
    }
}


export default withQuery(Congress);