import React from 'react';
import axios from 'axios'
import Form from '../hoc-v2/Form';
import Email from '../ui-v2/email';


const ForgotPassword = () => {
    return (
        <>
            <h6 className="model-title">Forgot your password?</h6>
            <small className='text-accent-4'>Enter the email you used when creating your account and we'll send you instructions to reset your password</small>
            <Form
                className="d-grid gap-inherit"
                onSubmit={e => {
                    axios.post("/forgot_password_email", e.values)
                        .then(res => {
                            if (res.data.success) e.onSuccess("Reset password email sent!")
                            else e.onFailure(res.data.message)
                        })
                        .catch(e.onFailure)
                }}
                footer={props => (
                    <div className='model-footer mt-md-2 gap-4'>
                        <a className='btn-text btn' href='#login'>Cancel</a>
                        <button disabled={props.disabled} type="submit" className={`btn btn-primary ${props.className}`}>Send</button>
                    </div>
                )}
            >
                <Email name="email" />
            </Form>
        </>
    )
}


export default ForgotPassword;
