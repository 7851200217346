import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios'
import useAuth from "../../hooks/useAuth"
import './SocialLogins.scss'


const SocialLogins = () => {

    const auth = useAuth({ isForUnAuthorized: true }).auth;

    const handleSuccess = async (googleData) => {
        try {
            const res = await axios.post('/google_facebook_sign_in', {
                token: googleData.credential,
            });
            if (res.data) {
                auth.persistUser(res.data.user);
                
                // Push login event to Google Tag Manager
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'google_login_register',
                    userId: res.data.user._id, // Use actual user identifier here if available
                });
                console.log('data layer should have pushed',window.dataLayer)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleFailure = googleData => {
        console.log(googleData, 'register error')

    }



    return (
        <>
            <div className='d-flex align-items-center'>
                <div className='border-bottom flex-1' />
                <p className='mx-2 my-0 line-height-1'>or</p>
                <div className='border-bottom flex-1' />
            </div>
            <div className='text-center gap-3 d-flex flex-wrap justify-content-center text-accent-4'>
                <p className='m-0 w-100'>Sign in with </p>
                <div className='d-grid col-1 gap-3'>
      
                    <GoogleOAuthProvider clientId="892485314218-ihjgqgvlsgr1bckabhs1bmumcgl49fs3.apps.googleusercontent.com">
                        <GoogleLogin
                            onSuccess={handleSuccess}
                            onError={handleFailure}
                        />
                    </GoogleOAuthProvider>
 
                </div>
            </div>
        </>
    )
}

export default SocialLogins;
