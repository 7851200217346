import styles from './news-data.module.scss';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import moment from 'moment';
import { useState } from 'react';
import Graph_module from '../graph_module';


const getSentimentClass = (score) => {
    if (!score) return '';
    return parseFloat(score) >= 0 ? styles.positive : styles.negative;
};

const formatSentimentNumber = (score) => {
    if (!score) return 'N/A';
    return parseFloat(score).toFixed(2);
};

const formatSentimentFull = (score, label) => {
    if (!score || !label) return 'N/A';
    return `${parseFloat(score).toFixed(2)} (${label})`;
};

export default function NewsData({ news_data = { feed: [] }, isLoading, viewMode = 'individual' }) {
    const articles = news_data?.feed || [];
    const [graphSymbol, setGraphSymbol] = useState(null);

    if (isLoading) {
        return <div className="text-center p-4">Loading news data...</div>;
    }

    if (!news_data || !articles || articles.length === 0) {
        return <div className="text-center p-4">No news items available at this time. Try changing the filters.</div>;
    }

    return (
        <div className='overflow-auto'>
            {graphSymbol && <Graph_module close={() => setGraphSymbol(null)} symbol={graphSymbol} text={graphSymbol} />}
            {articles.map((article, index) => (
                <div
                    key={article.url || index}
                    className={styles.item}
                >
                    <div className='d-flex flex-md-row flex-column align-items-center justify-content-between gap-3 mb-2'>
                        <a href={article.url} target="_blank" rel="noopener noreferrer" className="btn-text text-normal flex-1 fs-inherit h6 flex-grow-1">
                            {article.title}
                        </a>
                        {/* Overall Sentiment */}
                        <ToolTip title={formatSentimentFull(article.overall_sentiment_score, article.overall_sentiment_label)}>
                            <span className={`${getSentimentClass(article.overall_sentiment_score)} d-lg-inline-flex d-none px-3 py-1 text-nowrap`}>
                                <b>Overall Sentiment: </b>
                                {formatSentimentNumber(article.overall_sentiment_score)}
                            </span>
                        </ToolTip>
                    </div>
                    <div className='d-flex gap-2 flex-wrap mt-2 mb-3'>
                        {/* Individual Ticker Sentiments */}
                        <ToolTip title={formatSentimentFull(article.overall_sentiment_score, article.overall_sentiment_label)}>
                            <div className={`${getSentimentClass(article.overall_sentiment_score)} ms-3 fw-bolder d-lg-none`}>
                                <b className='fw-bold'>Overall Sentiment: </b>
                                {formatSentimentNumber(article.overall_sentiment_score)}
                            </div>
                        </ToolTip>
                        {article.ticker_sentiment.map((item, i) => (
                            <ToolTip key={i} title={formatSentimentFull(item.ticker_sentiment_score, item.ticker_sentiment_label)}>
                                <div 
                                    className={`${getSentimentClass(item.ticker_sentiment_score)} cursor-pointer`}
                                    onClick={() => setGraphSymbol(item.ticker)}
                                >
                                    <b>{item.ticker}: </b>
                                    {formatSentimentNumber(item.ticker_sentiment_score)}
                                </div>
                            </ToolTip>
                        ))}
                    </div>
                    <p className="text-accent-4 m-0">{article.summary}</p>
                    <p className='mt-3 mb-0'><b>Published:</b> {moment(article.time_published, 'YYYYMMDDTHHmmss').format('MMMM Do YYYY, h:mm a')}</p>
                </div>
            ))}
        </div>
    )
}