import React, { useState } from "react";
import Input from "./input";
import Eye from "../icons-v2/Eye";

const Password = props => {
    const [type, setType] = useState('password');
    return (
        <Input
            {...props}
            type={type}
            minLength={6}
            required={true}
            end={(
                <button
                    className='btn btn-icon mx-1'
                    onClick={() => setType(type === 'password' ? 'text' : 'password')}
                    type='button'
                >
                    <Eye className={type === "password" ? "" : "bg-accent-6"} />
                </button>
            )}
        />
    )
}

export default Password;