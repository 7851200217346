import React from 'react';
import axios from 'axios';
import URL from 'url-parse';
import { AppContext } from '../../App';
import ToolTip from '../../ui-v2/ToolTip/ToolTip';
import InputField from '../../ui-v2/InputField/InputField';
import moment from 'moment';

import Tool from '../../hoc-v2/withTool/Tool';
import './styles.scss';
import TitleBar, { Label } from '../../components-v2/ToolComponents/TitleBar';
import getStockSearch from '../../utility/getStockSearch';
import withQuery from '../../hoc-v2/withQuery';

class Shorts_Container extends React.Component {
  static contextType = AppContext;
  render() {
    return (
      <Shorts
        {...this.props}
        {...this.context}
      />
    );
  }
}

class Shorts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      // Each row is an object with (at least) these fields:
      total_state: new Array(10).fill({
        symbol: '',
        price: '',
        rate: '',
        strike: '',
        yearly_return: '',
        daily_return: '',
        amount_invested: '',
        amount_made_yearly: '',
        amount_made_daily: '',
        dividend_percent: '',
        bid_price: '', // New field added here
        days_to_expiry: '', // New field added here
        expected_profit: '', // New field added here
        expected_profit_percentage: '', // New field added here
        amount_made_from_contract: '', // New field added here
      }),
    };
  }

  // Handle changes for a specific row's input
  handleInputChange = (index, e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const total_state = [...prevState.total_state];
      const rowCopy = { ...total_state[index] };
      rowCopy[name] = value;
      total_state[index] = rowCopy;
      return { total_state };
    });
  };

  render() {
    const { isPage, isMobile } = this.props;
    return (
      <Tool id="shorts" algo={this.props.algo}>
        <div
          className="table-wrapper product_tool_table"
          style={{
            overflowX: 'auto',
            display: 'block',
            width: '100%',
          }}
        >
          <table
            style={{
              minWidth: '1200px',
              width: '100%',
              borderCollapse: 'collapse',
            }}
          >
            <thead>
              <tr>
                <th>No</th>
                <th><ToolTip title="Stock symbol.">Stock Symbol</ToolTip></th>
                <th><ToolTip title="Stock price.">Stock Price</ToolTip></th>
                <th><ToolTip title="Interactive Brokers interest rate (borrow rate).">IBKR Interest Rate</ToolTip></th>
                <th><ToolTip title="Lowest strike price for reference.">Lowest Strike</ToolTip></th>
                <th><ToolTip title="Dividend percent.">Dividend Percent</ToolTip></th>
                <th><ToolTip title="Bid price to sell the call contract.">Bid Price</ToolTip></th>
                <th><ToolTip title="Days to expiry.">Days to Expiry</ToolTip></th>
                <th><ToolTip title="Yearly return as % and amount.">Yearly Return</ToolTip></th>
                <th><ToolTip title="Daily return as % and amount.">Daily Return</ToolTip></th>
                <th><ToolTip title="Contract Profit.">Contract Return</ToolTip></th>
                <th><ToolTip title="Hypothetical amount invested.">Hypothetical Amount Invested</ToolTip></th>
                <th><ToolTip title="Hypothetical amount made yearly.">Hypothetical Amount Made Yearly</ToolTip></th>
                <th><ToolTip title="Hypothetical amount made daily.">Hypothetical Amount Made Daily</ToolTip></th>
                <th><ToolTip title="Expected Profit.">Expected Profit</ToolTip></th>
              </tr>
            </thead>
            <tbody>
              {this.state.total_state.map((rowState, index) => (
                <StockItem
                  key={index}
                  index={index}
                  rowState={rowState}
                  handleInputChange={this.handleInputChange}
                />
              ))}
            </tbody>
          </table>
        </div>
      </Tool>
    );
  }
}

// ROI calculation function:
function calculate_roi(row) {
  const price = parseFloat(row.price) || 0;
  const rate = parseFloat(row.rate) || 0;
  const strike = parseFloat(row.strike) || 0;
  const amount_invested = parseFloat(row.amount_invested) || 0;
  const bid_price = parseFloat(row.bid_price) || 0; 
  const days_to_expiry = parseFloat(row.days_to_expiry) || 1; // Default to 1 to avoid division by zero

  try {
    const interest_charge = rate / 2;
    const loss_due_to_bid = Math.max(bid_price - strike, 0);
    
    const yearly_return_from_stock_before_dividend = price * (interest_charge / 100) - loss_due_to_bid
    const yearly_return_from_stock = yearly_return_from_stock_before_dividend + (price * (row.dividend_percent / 100));
    

    const amount_invested_value = strike;
    const yearly_roi_percent = (yearly_return_from_stock / amount_invested_value) * 100;
    const yearly_return = amount_invested_value * (yearly_roi_percent / 100);
    const daily_return = yearly_return / 365;
    const daily_roi_percent = yearly_roi_percent / 365;
    const days_needed_to_recover = loss_due_to_bid / daily_return;
    const days_profit_remaining = days_to_expiry - days_needed_to_recover;
    const expected_profit_percentage = daily_roi_percent * days_profit_remaining;
    const expected_profit = daily_return * days_profit_remaining;
    const amount_made_yearly = amount_invested * (yearly_roi_percent / 100);
    const amount_made_daily = amount_made_yearly / 365;
    const amount_made_from_contract = amount_made_daily * days_to_expiry;

    return {
      yearly_roi_percent,
      yearly_return,
      daily_roi_percent,
      daily_return,
      amount_made_yearly,
      amount_made_daily,
      expected_profit,
      expected_profit_percentage,
      amount_made_from_contract,
    };
  } catch (error) {
    return {
      yearly_roi_percent: 0,
      yearly_return: 0,
      daily_roi_percent: 0,
      daily_return: 0,
      amount_made_yearly: 0,
      amount_made_daily: 0,
      expected_profit: 0,
      expected_profit_percentage: 0,
      amount_made_from_contract: 0,
    };
  }
}

const StockItem = ({ index, rowState, handleInputChange }) => {
  const {
    yearly_roi_percent,
    yearly_return,
    daily_roi_percent,
    daily_return,
    amount_made_yearly,
    amount_made_daily,
    expected_profit,
    expected_profit_percentage,
    amount_made_from_contract,
  } = calculate_roi(rowState);

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <input
          type="text"
          name="symbol"
          value={rowState.symbol || ''}
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <input
          type="text"
          name="price"
          value={rowState.price || ''}
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <input
          type="text"
          name="rate"
          value={rowState.rate || ''}
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <input
          type="text"
          name="strike"
          value={rowState.strike || ''}
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <input
          type="text"
          name="dividend_percent"
          value={rowState.dividend_percent || ''}
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <input
          type="text"
          name="bid_price"
          value={rowState.bid_price || ''}
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <input
          type="text"
          name="days_to_expiry"
          value={rowState.days_to_expiry || ''}
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <p>
          {yearly_roi_percent.toFixed(2)}% ({yearly_return.toFixed(2)})
        </p>
      </td>
      <td>
        <p>
          {daily_roi_percent.toFixed(2)}% ({daily_return.toFixed(2)})
        </p>
      </td>
      <td>
        <p>
          {expected_profit_percentage.toFixed(2)}% ({expected_profit.toFixed(2)})
        </p>
      </td>

      <td>
        <input
          type="text"
          name="amount_invested"
          value={rowState.amount_invested || ''}
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%' }}
        />
      </td>
      <td>
        <p>
          {amount_made_yearly.toFixed(2)}
        </p>
      </td>
      <td>
        <p>
          {amount_made_daily.toFixed(2)}
        </p>
      </td>
      <td>
        <p>
          {amount_made_from_contract.toFixed(2)}
        </p>
      </td>
    </tr>
  );
};

export default withQuery(Shorts_Container);



