import React from 'react';
import styles from './StockItem.module.scss';
import StockGraph from '../../components-v2/StockGraph';

const Stockprops = props => {
    const getItem = (title, description) => description && (
        <>
            <b className='fw-semibold text-start'>{title}</b>
            <small>{description}</small>
        </>
    )
    const getOutComeClass = () => {
        switch (props.filtered_outcome) {
            case 'approved':
                return 'bg-accent-3'
            case 'extended':
                return 'bg-primary'
            case 'deferred':
                return 'bg-accent-4'
            case 'declined':
                return 'bg-accent-2'
            default:
                return 'bg-accent-1'
        }
    }
    return (
        <>
            <div className={`small ${styles.root}`}>
                <div className='d-sm-flex align-items-center mb-2'>
                    <h6 className='m-0 mb-sm-0 mb-2 fs-normal'>{props.company_name}</h6>
                    <button id='ticker' className='badge mx-sm-3 ms-2' about={props.ticker} aria-details='FDA'>{props.ticker}</button>
                    <small className='text-accent-4 smaller'>{props.event_date}</small>
                </div>
                <div className='d-flex flex-column flex-sm-row ai-center gap-md-3 gap-2 mb-2 pb-1'>
                    <div className='d-flex gap-3'>
                        <b className='fw-semibold'>Drug Name</b>
                        <small>{props.drug_name}</small>
                    </div>
                    <div className='d-md-block d-none'>|</div>
                    <div className='d-flex gap-3'>
                        <b className='fw-semibold'>Event Type</b>
                        <small>{props.filtered_event_type}</small>
                        {props.filtered_outcome && <span className={`text-surface-1 px-2 py-1 text-uppercase border-radius-20 ${getOutComeClass()}`}>{props.filtered_outcome}</span>}
                    </div>
                </div>
                {props.news !== '-' && <p className='mt-0 mb-2'>{props.news}</p>}
                <div className={`d-grid ${styles.main}`}>
                    {getItem('Outcome', props.outcome)}
                    {getItem('Description', props.event_description)}
                    {getItem('Drug Status', props.drug_status)}
                    {getItem('Drug Rival', props.rival_drugs)}
                    {getItem('Market Potential', props.market_potential)}
                    {getItem('Other approvals', props.other_approvals)}
                </div>
            </div>
        </>
    )
}

export default Stockprops;
