import React from 'react';
import usePost from '../../hooks/usePostV2';
import StarIcon from '../../icons-v2/StarIcon';
import Modal from './WriteReview';
import Spinner from '../../ui-v2/Spinner/Spinner';

export default function WriteReviewV2(props) {
    const { res, onPost, isLoading, setStatus } = usePost('algos/review/get', {
        active: false,
    }), data = {
        userId: localStorage.getItem("userId"),
        algoId: props.algo._id
    }
    return (
        <>
            <button
                className='btn btn-text small'
                id={`${props.algo._id}-Review`}
                onClick={() => onPost({data})}
            >
                <StarIcon /> Review
            </button>
            {isLoading && <Spinner toMiddle />}
            {res && (
                <Modal
                    closeHandler={() => setStatus({ })}
                    show={true}
                    {...props}
                    review={res.review}
                    {...data}
                />
            )}
        </>
    )
}