import React from 'react';
import './styles.scss'
import useActive from '../../../hooks/useActive';
import usePosition from '../../../hooks/usePosition';
import Portal from '../../../hoc-v2/Portal';
import useQuery from '../../../hooks/useQuery';

const Main = ({ children, component, className = '', ...props }) => {
    const { status, btnRef } = useActive(), ref = usePosition(btnRef, status);
    return (
        <>
            {component(status, btnRef)}
            {status && (
                <Portal id="options">
                    <ul ref={ref} className={`${className} Nav-DropDown ${status}`} {...props}>
                        {children}
                    </ul>
                </Portal>
            )}
        </>
    )
}

export default function NavDropDown(props) {
    const isMobile = useQuery();
    return isMobile ? (
        <>
            {props.component()}
            <ul className={props.className}>
                {props.children}
            </ul>
        </>
    ) : <Main {...props} />
}