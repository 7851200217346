import { useCallback, useEffect, useMemo } from "react";
import useStockSearch from "./useStockSearch";

export default function useStockSearchV2({ value, onChange, name }) {
    const sProps = useStockSearch({ value, api: "fetch_stock", isInitialRequired: false });
    let timer = useMemo(() => setTimeout(() => { }, 500), []);
    const onValueChange = useCallback(value => sProps.onChange({ target: { value }, resultItem: true, name }))
    useEffect(() => {
        if (sProps.value !== value) {
            timer = setTimeout(() => {
                onChange({ target: { value: sProps.value, name } });
            }, 500);
        }
        return () => clearTimeout(timer);
    }, [sProps.value]);
    useEffect(() => {
        if (value !== sProps.value) onValueChange(value);
    }, [value])
    return { ...sProps, onValueChange };
}