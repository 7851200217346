import React, { useState, useEffect, useMemo,  useContext  } from 'react';
import { AppContext } from '../../App';
import axios from 'axios';
import Spinner from '../../ui-v2/Spinner/Spinner';
import CreateWatchlist from './CreateWatchlist/CreateWatchlist';
import Main from './Main/Main';
import styles from './styles.module.scss';
import ArrowRightIcon from '../../icons-v2/ArrowRightIcon';


const Wrapper = ({ onClose, dashboard_view, ...props }) => {
    const [state, setSt] = useState({}), watchlist = state.watchlist, setState = obj => setSt({ ...state, ...obj }), newProps = { ...props, setState, ...state }
    const isLoggedIn = useContext(AppContext).isLoggedIn;
    useEffect(async () => {
        if (!isLoggedIn) return;
        const watchlist = (await axios.get('/watchlist')).data;
        setState({ watchlist, selectedItem: watchlist[0] });
    }, [isLoggedIn])
    return (
        <> 
        {isLoggedIn ?
        <>
            <div className='d-flex p-3 border-bottom'>
                <button className={`btn btn-icon no-padding small ${dashboard_view ? "" : "d-lg-none"} ms-0`} onClick={onClose}><ArrowRightIcon className='rotate-180' /></button>
                <h6 className='my-0 ms-auto'>Watchlist</h6>
                {watchlist && <CreateWatchlist {...newProps} />}
            </div>
            {watchlist ? watchlist.length > 0 ? < Main {...newProps} /> : (
                <div className='flex-1 d-flex flex-column text-center text-accent-4 align-items-center justify-content-center small'>
                    <img src="/cat.png" alt="" style={{ width: '6.625rem' }} />
                    <p>You don't have any watchlists yet.</p>
                </div>
            ) : (
                <div className='p-3 flex-1 position-relative w-100'>
                    <Spinner className='translate-middle position-absolute text-accent-3' />
                </div>
            )}
            </>
                :
            <div className='flex-1 d-flex flex-column text-center text-accent-4 align-items-center justify-content-center small'>
                <img src="/cat.png" alt="" style={{ width: '6.625rem' }} />
                <p>You need to <a className='btn-text' href="#login">login to</a> have access to this feature. It's free.</p>
            </div>
        }
            

        </>
    )
}


const WatchlistV2 = ({ isMenu, ...props }) => (
    <div
        className={`bg-surface-1 d-lg-flex flex-column vh-page border-left ${isMenu ? `${styles.active} d-flex` : 'd-none'} ${props.dashboard_view ? styles.dashboard_view : ""} ${styles.root}`}
    >
        {useMemo(() => <Wrapper {...props} />, [])}
    </div>
)

export default WatchlistV2;