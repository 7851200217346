import React from 'react';
import LDBanner from '../../components/LDBanner/LDBanner';
import ArrowRightIcon from '../../icons-v2/ArrowRightIcon';
import styles from './LeaderBoardBanner.module.scss';

const base_url = 'https://stockalgos.com/leaderboard/';

const LeaderBoardBanner = () => (

    // <LDBanner Root='a'
    //     href='https://metatradingclub.com/'
    //     title="Meta Trading Club "
    //     className={styles.root}
    //     footer={<>Join the Next Cohort<ArrowRightIcon className='svg-normal' /></>}
    // >
    //     <p className='mb-0'> Transform your trading in 4 weeks. </p>
    // </LDBanner>
    <LDBanner Root='a'
        href={base_url}
        title={<><u>Stock Trading Competition</u> <br/> <strong>$1,000</strong> in Prizes</>}
        className={styles.root}
        footer={<>Enter Now<ArrowRightIcon className='svg-normal' /></>}
    >
        <p className='mb-0'> Compete monthly against other stock market enthusiasts for free.</p>
    </LDBanner>
)

export default LeaderBoardBanner;