import React, { useRef } from "react";
import InputField from "../../ui-v2/InputField/InputField";
import useStockSearchV2 from "../../hooks/useStockSearchV2";
import SearchResults from "../SearchResults";
import usePosition from "../../hooks/usePosition";
import Portal from "../../hoc-v2/Portal";
import styles from './search.module.scss'

export default function SearchField(props) {
    const { results, onAdd, onValueChange, onClear, ...sProps } = useStockSearchV2({
        value: props.value,
        onChange: props.onChange,
        name: props.name
    });
    const btnRef = useRef(), ref = usePosition(btnRef);
    return (
        <>
            <div ref={btnRef} className={`w-100 ${props.className}`}>
                <InputField
                    {...sProps}
                    name={props.name}
                    label={props.label}
                    endAdornment={props.endAdornment}
                    autoComplete="off"
                    disabled={props.disabled}
                />
            </div>
            <Portal id="popup-wrapper">
                <div ref={ref} className={`position-fixed ${styles.root}`}>
                    <SearchResults
                        results={results}
                        onAdd={onAdd}
                        onValueChange={onValueChange}
                        onClear={onClear}
                        isOverLay={false}
                        className="card"
                        value={sProps.value}
                    />
                </div>
            </Portal>
        </>
    )
}