import React from 'react';
import RenderViral_Container from '../../../live/viral_marketing/render_viral_components';
import CreditDropDown from '../CreditDropDown';
import Notifications from '../../Notifications/Notifications';
import styles from './righlinks.module.scss';
import useQuery from '../../../hooks/useQuery';
import UserDropDown from '../UserDropDown/UserDropDown';
import LoginButton from '../../LoginButton/LoginButton';
import LeaderBoardBanner from '../../FiltersSection/Advertisement';

const RightLinks = ({ isLoggedIn, viral_components, ...props }) => {
    const isMobile = useQuery(992);
    const ViralC = location => <RenderViral_Container location={location || "Top Menu"} all_viral={viral_components} />
    return (
        <div className={`flex-xl-1 justify-content-end me-auto d-lg-flex ${styles.root}`}>
            <ul className='list-unstyled d-lg-flex me-auto align-items-center'>
                {!isMobile && isLoggedIn && <CreditDropDown ViralC={ViralC} />}
                <li className='my-auto mx-3 d-grid'>{ViralC()}</li>
                {isLoggedIn ? (
                    <>
                        <li><Notifications /></li>
                        <UserDropDown {...props} />
                    </>
                ) : (
                    <>
                        <li><LoginButton className='Header-Link'>Login</LoginButton></li>
                        <li className='ms-md-1'><LoginButton className='Header-Link' isLogin={false}>Register</LoginButton></li>
                    </>
                )}
                {isMobile && <li className='p-3'><LeaderBoardBanner /></li>}
            </ul>
        </div>
    )
}


export default RightLinks;